import React from "react";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

const EnumTable = ({ data, title }) => {
  if (data.length === 0) {
    return <div>No data available</div>;
  }

  const newCols = Object.keys(data[0]).map(item => {
    if (item === "District" || item === "ED" || item === "Urban Rural" || item === "Enumerator" || item === "Area") {
      return {
        label: item,
        name: item
      };
    } else {
      return {
        label: item,
        name: item,
        options: {
          filter: false
        }
      };
    }
  });


  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          root: {
            backgroundColor: "#FF000",
            overflowX: "hidden"
          },
          paper: {
            boxShadow: "none"
          }
        },
        MUIDataTableBodyCell: {
          root: {
            backgroundColor: "#FFF",
            padding: 12
          }
        }
      }
    });

  const options = {
    selectableRows: "none",
    pagination: true,
    rowsPerPageOptions: [10, 25, 100],
    responsive: "scrollMaxHeight"
  };

  return (
    <div style={{ maxWidth: "100%" }}>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={title}
          columns={newCols}
          data={data}
          options={options}
        />
      </MuiThemeProvider>
    </div>
  );
};

export default EnumTable;
