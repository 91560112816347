import axios from "axios";

const baseUrl =
  process.env.REACT_APP_NODE_ENV === "development"
    ? "http://localhost:3000/api"
    : process.env.REACT_APP_API;
    

export const authenticate = async (username, password) => {
  const response = await axios.post(`${baseUrl}/login`, {
    username,
    password
  });
  return response.data;
};

export const getUserBuildings = async user => {
  const response = await axios.get(`${baseUrl}/user_buildings/${user}`);
  return response.data;
};

export const getBuildings = async user => {
  const response = await axios.get(`${baseUrl}/buildings`);
  return response.data;
};

export const getTeamBuildings = async user => {
  const response = await axios.get(`${baseUrl}/team_buildings/${user}`);
  return response.data;
};

export const getUserUnits = async user => {
  const response = await axios.get(`${baseUrl}/user_units/${user}`);
  return response.data;
};

export const getUnits = async user => {
  const response = await axios.get(`${baseUrl}/units`);
  return response.data;
};

export const getTeamUnits = async user => {
  const response = await axios.get(`${baseUrl}/team_units/${user}`);
  return response.data;
};

export const getUserHouseholds = async user => {
  const response = await axios.get(`${baseUrl}/user_hh/${user}`);
  return response.data;
};

export const addBuilding = async (
  ed,
  building_no,
  building_type,
  urban_rural,
  block,
  username,
  role,
  comment
) => {
  const response = await axios.post(`${baseUrl}/add_building`, {
    ed,
    building_no,
    building_type,
    urban_rural,
    block,
    username,
    role,
    comment
  });
  return response.data;
};

export const addUnit = async (
  building_id,
  unit_type,
  name,
  males,
  females,
  persons_interviewed,
  result,
  callback_date,
  callback_time,
  username,
  role,
  comment
) => {
  const response = await axios.post(`${baseUrl}/add_unit`, {
    building_id,
    unit_type,
    name,
    males,
    females,
    persons_interviewed,
    result,
    callback_date,
    callback_time,
    username,
    role,
    comment
  });
  return response.data;
};

export const addHh = async (
  building_id,
  unit_id,
  unit_type,
  household_id,
  name,
  males,
  females,
  persons_interviewed,
  result,
  callback_date,
  callback_time
) => {
  const response = await axios.post(`${baseUrl}/add_hh`, {
    building_id,
    unit_id,
    unit_type,
    household_id,
    name,
    males,
    females,
    persons_interviewed,
    result,
    callback_date,
    callback_time
  });
  return response.data;
};

export const editBuilding = async (
  building_id,
  building_type,
  username,
  role,
  comment
) => {
  const response = await axios.post(`${baseUrl}/edit_building`, {
    building_id,
    building_type,
    username,
    role,
    comment
  });
  return response.data;
};

export const editUnit = async (
  building_id,
  unit_id,
  unit_type,
  name,
  males,
  females,
  persons_interviewed,
  result,
  callback_date,
  callback_time,
  interview_type,
  username,
  role,
  comment
) => {
  const response = await axios.post(`${baseUrl}/edit_unit`, {
    building_id,
    unit_id,
    unit_type,
    name,
    males,
    females,
    persons_interviewed,
    result,
    callback_date,
    callback_time,
    interview_type,
    username,
    role,
    comment
  });
  return response.data;
};

export const addBuildingComments = async (
  building_id,
  username,
  role,
  comment
) => {
  const response = await axios.post(`${baseUrl}/insert_buildingComments`, {
    building_id,
    username,
    role,
    comment
  });
  return response.data;
};

export const addUnitComments = async (
  building_id,
  unit_id,
  username,
  role,
  comment
) => {
  const response = await axios.post(`${baseUrl}/insert_unitComments`, {
    building_id,
    unit_id,
    username,
    role,
    comment
  });
  return response.data;
};

export const getBuildingComments = async building_id => {
  const response = await axios.post(`${baseUrl}/get_buildingComments`, {
    building_id
  });
  return response.data;
};

export const getUnitComments = async (building_id, unit_id) => {
  const response = await axios.post(`${baseUrl}/get_unitComments`, {
    building_id,
    unit_id
  });
  return response.data;
};

export const getTeamMembers = async (username) => {
  const response = await axios.post(`${baseUrl}/getTeamMembers`, {
    username
  });
  return response.data;
};