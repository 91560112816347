import React, { useState, useEffect } from "react";
import { Layout, Menu, Drawer, Icon, Empty, Spin, Input, Select } from "antd";
import CensusLogo from "../assets/images/Census2020_logo.svg";

import Enumeration from "../components/Enumeration";
import ResponseRates from "../components/ResponseRates";
import AgeGroups from "../components/AgeGroups";
import Questionnaire from "../components/Questionnaire";
import { EditorMenu } from "../components/PortalMenu";
import { useAuth } from "../context/auth";
import ControlFormBuildings from "../components/ControlFormBuildings";
import ControlFormUnits from "../components/ControlFormUnits";
import { getBuildings, getUnits, getUserHouseholds } from "../services/User";
import ControlModal from "../components/ControlModal";
import CodeSearch from "../components/CodeSearch";

const { Option } = Select;

const { Header, Content, Footer, Sider } = Layout;

const EditorView = () => {
  const [mainView, setMainView] = useState("codesearch");
  const [subView, setSubView] = useState("codesearch");
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [mobileView, setMobileView] = useState();
  const { authToken, setAuthToken } = useAuth();
  const [buildingData, setBuildingData] = useState([]);
  const [unitData, setUnitData] = useState([]);
  const [hhData, setHhData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [rowData, setRowData] = useState([]);

  const logout = () => {
    setAuthToken();
  };

  const handleAddNew = (type, data) => {
    setVisible(true);
    setRowData([type, data]);
    //setDwelling(false);
  };

  const handleModalClose = () => {
    setVisible(false);
    //setDwelling(false);
    // Reload user data after
    getBuildings(authToken.username).then(response => {
      setBuildingData(response);
    });
    getUnits(authToken.username).then(response => {
      setUnitData(response);
    });
    getUserHouseholds(authToken.username).then(response => {
      setHhData(response);
    });
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  const toggleMenu = () => {
    if (window.innerWidth < 992) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      window.addEventListener("resize", toggleMenu);
      toggleMenu();
    }

    getBuildings(authToken.username).then(response => {
      if (mounted) setBuildingData(response);
    });
    getUnits(authToken.username).then(response => {
      if (mounted) setUnitData(response);
    });
    getUserHouseholds(authToken.username).then(response => {
      if (mounted) setHhData(response);
    });

    return () => {
      mounted = false;
      window.removeEventListener("resize", toggleMenu);
    };
  }, [authToken]);

  const selectAfter = (
    <Select defaultValue="isic" style={{ width: 80 }}>
      <Option value="isic">ISIC</Option>
      <Option value="isco">ISCO</Option>
      <Option value="isced">ISCED</Option>
    </Select>
  );

 

  return (
    <div>
      <Layout theme="light">
        {mobileView ? (
          <Drawer
            placement="left"
            closable={false}
            onClose={onClose}
            visible={drawerVisible}
          >
            <EditorMenu setMainView={setMainView} setSubView={setSubView} />
          </Drawer>
        ) : (
          <Sider breakpoint="md" collapsedWidth="0" theme="light">
            <EditorMenu setMainView={setMainView} setSubView={setSubView} />
          </Sider>
        )}

        <Layout style={{ minHeight: "100vh", overflow: "auto" }}>
          <Header className="header">
            {mobileView ? (
              <span className="hamburger-menu">
                <Icon type="menu" onClick={showDrawer} />
              </span>
            ) : (
              console.log("hello")
            )}
          </Header>
          <Content className="table-margin">
            <ControlModal
              type={rowData[0]}
              data={rowData[1]}
              role={authToken.role}
              visible={visible}
              closeModal={handleModalClose}
            />
            {mainView === "controlForms" && subView === "buildings" && (
              <ControlFormBuildings
                buildingData={buildingData}
                unitData={unitData}
                hhData={hhData}
                onClick={handleAddNew}
                role={authToken.role}
              />
            )}
            {mainView === "controlForms" && subView === "units" && (
              <ControlFormUnits
                unitData={unitData}
                hhData={hhData}
                onClick={handleAddNew}
                role={authToken.role}
              />
            )}
            {mainView === "responseRates" && (
              <ResponseRates subView={subView} />
            )}
            {mainView === "compare" && <Questionnaire subView={subView} />}
            {mainView === "codesearch" && <CodeSearch /> }
          </Content>
          <Footer style={{ textAlign: "center" }}>Powered by Coffee</Footer>
        </Layout>
      </Layout>
    </div>
  );
};

export default EditorView;
