import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import ApartmentIcon from "@material-ui/icons/Apartment";
import { withStyles } from "@material-ui/core/styles";

const defaultToolbarStyles = {
  iconButton: {}
};

const CustomToolbar = props => {
  const { classes, handleClick } = props;

  return (
    <React.Fragment>
      <Tooltip title={"Add Building"}>
        <IconButton className={classes.iconButton} onClick={handleClick}>
          <AddIcon className={classes.deleteIcon} style={{ fontSize: 16 }} />
          <ApartmentIcon className={classes.deleteIcon} />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  );
};

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(
  CustomToolbar
);
