import React from "react";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

const AgeGroups = ({ data }) => {
  if (data.length === 0) {
    return <div>No data available</div>;
  }

  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          root: {
            backgroundColor: "#FF000",
            overflowX: "hidden"
          },
          paper: {
            boxShadow: "none"
          }
        },
        MUIDataTableBodyCell: {
          root: {
            backgroundColor: "#FFF",
            padding: 12
          }
        }
      }
    });

  data.forEach(row => {
    if (row.Area === "1") {
      row.Area = "Urban";
    } else if (row.Area === "2") {
      row.Area = "Rural";
    }
  });

  data.forEach(row => {
    switch (row.District) {
      case 1:
        row.District = "Corozal";
        break;
      case 2:
        row.District = "Orange Walk";
        break;
      case 3:
        row.District = "Belize";
        break;
      case 4:
        row.District = "Cayo";
        break;
      case 5:
        row.District = "Stann Creek";
        break;
      case 6:
        row.District = "Toledo";
        break;
      case null:
        row.District = "National";
        break;
      default:
        break;
    }
  });

  const newCols = Object.keys(data[0]).map(item => {
    if (item === "District" || item === "ED" || item === "Area") {
      return {
        label: item,
        name: item
      };
    } else {
      return {
        label: item,
        name: item,
        options: {
          filter: false
        }
      };
    }
  });

  const options = {
    selectableRows: "none",
    rowsPerPageOptions: [10, 25, 100],
    responsive: "scrollMaxHeight"
  };

  console.log(data);

  return (
    <div style={{ maxWidth: "100%" }}>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title="Age Groups"
          columns={newCols}
          data={data}
          options={options}
        />
      </MuiThemeProvider>
    </div>
  );
};

export default AgeGroups;
