import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Modal,
  Divider,
  Form,
  DatePicker,
  Input,
  Select,
  Icon,
  InputNumber,
  Typography,
  List
} from "antd";
import { ADD, EDIT, SU_USER, EN_USER, DWELLING } from "../constants";
import {
  addBuilding,
  addUnit,
  editBuilding,
  editUnit,
  getBuildingComments,
  getUnitComments,
  getTeamMembers
} from "../services/User";

import { useAuth } from "../context/auth";

const { Option } = Select;
const { TextArea } = Input;

const ControlModal = ({ type, data, role, visible, closeModal, form }) => {
  const {
    validateFieldsAndScroll,
    getFieldDecorator,
    setFields,
    resetFields
  } = form;
  const [callback, setCallback] = useState([]);
  const [commentData, setCommentData] = useState([]);
  const [length, setLength] = useState(600);
  const [teamMembers, setTeamMembers] = useState([]);
  const { authToken } = useAuth();

  useEffect(() => {
    let mounted = true;

    if (type === EDIT.BUILDING) {
      getBuildingComments(data.building_id).then(response => {
        if (mounted) setCommentData(response);
      });

      getTeamMembers(authToken.username).then(response => {
        if (mounted) setTeamMembers(response);
      });
    } else if (type === EDIT.UNIT) {
      getUnitComments(data.building_id, data.unit_id).then(response => {
        if (mounted) setCommentData(response);
      });
    }

    return () => {
      mounted = false;
    };
  }, [authToken, type, data]);

  const handleSubmit = e => {
    e.preventDefault();
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (type === ADD.BUILDING) {
          addBuilding(
            values.ed,
            values.building_id,
            values.type,
            values.urban_rural,
            values.block,
            values.enumerator,
            authToken.role,
            values.comment
          )
            .then(res => {
              if (res === "failed") {
                // display error msg
                setFields({
                  ed: {
                    value: values.ed,
                    errors: [new Error("Please provide a valid ED")]
                  },
                  building_id: {
                    value: values.building_id,
                    errors: [new Error("Please provide a valid building ID")]
                  }
                });
              } else {
                closeModal();
                //setDwelling(false);
              }
            })
            .catch(e => {
              console.log(e);
            });
        } else if (type === ADD.UNIT) {
          values.callback_date = callback[0];
          values.callback_time = callback[1];
          addUnit(
            data.building_id,
            values.type,
            values.name,
            values.males,
            values.females,
            values.persons_interviewed,
            values.result,
            values.callback_date,
            values.callback_time,
            authToken.username,
            authToken.role,
            values.comment
          )
            .then(res => {
              closeModal();
              //setDwelling(false);
            })
            .catch(e => {
              console.log(e);
            });
        } else if (type === EDIT.BUILDING) {
          editBuilding(
            data.building_id,
            values.type,
            values.enumerator,
            authToken.role,
            values.comment
          )
            .then(res => {
              console.log(res);
              closeModal();
            })
            .catch(e => console.log(e));
        } else if (type === EDIT.UNIT) {
          editUnit(
            data.building_id,
            data.unit_id,
            values.type,
            values.name,
            values.males,
            values.females,
            values.persons_interviewed,
            values.result,
            // Change to new date and time if need be
            callback.length === 0 ? data.callback_date : callback[0],
            callback.length === 0 ? data.callback_time : callback[1],
            // Only a supervisor can change interview type
            authToken.role === SU_USER
              ? values.interview_type
              : data.interview_type,
            authToken.username,
            authToken.role,
            values.comment
          )
            .then(res => {
              closeModal();
              //setDwelling(false);
            })
            .catch(e => console.log(e));
        }
      }
    });
  };

  const handleDateChange = (value, time) => {
    setCallback(time.split(" "));
  };

  const handleSelectChange = value => {
    if (value === DWELLING) {
      //setDwelling(true);
    } else {
      //setDwelling(false);
    }
  };

  const entry = {};

  if (type === ADD.BUILDING) {
    entry.title = "Building";
    entry.type = "Add New Building";
    entry.icon = "plus";
    entry.isBuilding = true;
    entry.isHousehold = false;
  } else if (type === ADD.UNIT) {
    entry.title = "Building " + data.building_id;
    entry.type = "Add New Unit";
    entry.icon = "plus";
    entry.isBuilding = false;
    entry.isHousehold = false;
    data.type = data.unit_type;
  } else if (type === ADD.HH) {
    entry.title = "Building " + data.building_id + " Unit " + data.unit_id;
    entry.type = "Add New Household";
    entry.icon = "plus";
    entry.isBuilding = false;
    entry.isHousehold = true;
  } else if (type === EDIT.BUILDING) {
    entry.title = "Building " + data.building_id;
    entry.type = "Edit Building " + data.building_id;
    entry.icon = "edit";
    entry.isBuilding = true;
    entry.isHousehold = false;
    data.type = data.building_type;
  } else if (type === EDIT.UNIT) {
    entry.title = "Building " + data.building_id;
    entry.type = "Edit Unit " + data.unit_id;
    entry.icon = "edit";
    entry.isBuilding = false;
    entry.isHousehold = false;
    data.type = data.unit_type;
    data.callback =
      data.callback_date !== ""
        ? moment(
            `${data.callback_date} ${data.callback_time}`,
            "YYYY-MM-DD hh:mm"
          )
        : null;
  } else if (type === EDIT.HH) {
    entry.title = "Building " + data.building_id + " Unit " + data.unit_id;
    entry.type = "Edit Household " + data.household_id;
    entry.icon = "edit";
    entry.isBuilding = false;
    entry.isHousehold = true;
    data.callback =
      data.callback_date !== ""
        ? moment(
            `${data.callback_date} ${data.callback_time}`,
            "YYYY-MM-DD hh:mm"
          )
        : null;
  }

  const getFieldDecoratorOptions = id => {
    if (!visible) return;
    const inputProps = {};

    if (id === "type") {
      inputProps.rules = [{ required: true, message: "Please select type" }];
      if (type >= EDIT.BUILDING) {
        inputProps.initialValue = data.type;
      }
      return inputProps;
    } else if (id === "name") {
      inputProps.rules = [
        { required: true, message: "Please enter name of head" }
      ];
      if (type >= EDIT.BUILDING) {
        inputProps.initialValue = data.name;
      }
      return inputProps;
    } else if (id === "males") {
      inputProps.rules = [
        { required: true, message: "Please enter number of males" }
      ];
      if (type >= EDIT.BUILDING) {
        inputProps.initialValue = data.males;
      }
      return inputProps;
    } else if (id === "females") {
      inputProps.rules = [
        { required: true, message: "Please enter number of females" }
      ];
      if (type >= EDIT.BUILDING) {
        inputProps.initialValue = data.females;
      }
      return inputProps;
    } else if (id === "persons_interviewed") {
      inputProps.rules = [
        {
          required: true,
          message: "Please enter number of persons interviewed"
        }
      ];
      if (type >= EDIT.BUILDING) {
        inputProps.initialValue = data.persons_interviewed;
      }
      return inputProps;
    } else if (id === "result") {
      inputProps.rules = [
        { required: true, message: "Please enter result code" }
      ];
      if (type >= EDIT.BUILDING) {
        inputProps.initialValue = data.result;
      }
      return inputProps;
    } else if (id === "callback") {
      inputProps.rules = [
        { required: false, message: "Please enter callback date and time" }
      ];
      if (type >= EDIT.BUILDING) {
        inputProps.initialValue = data.callback;
      }
      return inputProps;
    } else if (id === "ed") {
      inputProps.rules = [{ required: true, message: "Please enter ED" }];
      if (type >= EDIT.BUILDING) {
        inputProps.initialValue = data.ed;
      }
      return inputProps;
    } else if (id === "building_id") {
      inputProps.rules = [{ required: true, message: "Please enter ID" }];
      if (type >= EDIT.BUILDING) {
        inputProps.initialValue = data.building_id;
      }
      return inputProps;
    } else if (id === "urban_rural") {
      inputProps.rules = [
        { required: true, message: "Please select urban or rural" }
      ];
      if (type >= EDIT.BUILDING) {
        inputProps.initialValue = data.urban_rural;
      }
      return inputProps;
    } else if (id === "block") {
      inputProps.rules = [{ required: true, message: "Please enter block" }];
      if (type >= EDIT.BUILDING) {
        inputProps.initialValue = data.block;
      }
      return inputProps;
    } else if (id === "enumerator") {
      inputProps.rules = [
        {
          required: true,
          message: "Please select the enumerator responsible for this building"
        }
      ];
      if (type >= EDIT.BUILDING) {
        inputProps.initialValue = data.username;
      }
      return inputProps;
    } else if (id === "interview_type") {
      inputProps.rules = [
        { required: false, message: "Please select interview type" }
      ];
      if (type >= EDIT.BUILDING) {
        inputProps.initialValue = data.interview_type;
      }
      return inputProps;
    } else if (id === "comment") {
      inputProps.rules = [
        { required: false, message: "Please enter comments" }
      ];
      return inputProps;
    }
  };

  const getComments = () => {
    return commentData.map(item => {
      return [item.comment, item.username].join(" - ");
    });
  };

  const handleLength = e => {
    const left = 600 - e.target.value.length;
    setLength(left === 0 ? 0 : left);
  };

  const formLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
    }
  };

  return (
    <Modal
      title={entry.title}
      visible={visible}
      onOk={handleSubmit}
      onCancel={closeModal}
      style={{ width: "100vh" }}
      maskClosable={false}
      afterClose={() => {
        resetFields();
        setLength(600);
        setCommentData([]);
      }}
      destroyOnClose
    >
      <Divider>
        <Icon type={entry.icon} /> {entry.type}
      </Divider>

      <Form layout="horizontal" style={{ alignItems: "center" }}>
        {// Building-specific
        !entry.isHousehold && entry.isBuilding && (
          <Form.Item label="Type" {...formLayout}>
            {getFieldDecorator(
              "type",
              getFieldDecoratorOptions("type")
            )(
              <Select
                placeholder="Select type"
                disabled={role !== EN_USER}
                onChange={handleSelectChange}
              >
                <Option value={1}>Residential</Option>
                <Option value={2}>Institutional</Option>
                <Option value={3}>Educational</Option>
                <Option value={4}>Health Care</Option>
                <Option value={5}>Commercial</Option>
                <Option value={6}>Diplomatic</Option>
                <Option value={7}>Governmental</Option>
                <Option value={8}>Religious</Option>
                <Option value={9}>Transportation</Option>
                <Option value={10}>Recreational</Option>
                <Option value={11}>Mixed</Option>
                <Option value={12}>Storage</Option>
                <Option value={13}>Assembly</Option>
              </Select>
            )}
            <br />
          </Form.Item>
        )}
        {// Unit-specific
        !entry.isHousehold && !entry.isBuilding && (
          <Form.Item label="Type" {...formLayout}>
            {getFieldDecorator(
              "type",
              getFieldDecoratorOptions("type")
            )(
              <Select
                placeholder="Select type"
                disabled={role !== EN_USER}
                onChange={handleSelectChange}
              >
                <Option value={1}>Dwelling</Option>
                <Option value={2}>Institution</Option>
                <Option value={3}>Education</Option>
                <Option value={4}>Health Care</Option>
                <Option value={5}>Business/Mercantile</Option>
                <Option value={6}>Diplomatic</Option>
                <Option value={7}>Governmental</Option>
                <Option value={8}>Religious</Option>
                <Option value={9}>Transportation</Option>
                <Option value={10}>Recreation</Option>
                <Option value={12}>Storage</Option>
                <Option value={13}>Vacant Unit</Option>
                <Option value={14}>Other</Option>
              </Select>
            )}
            <br />
          </Form.Item>
        )}
        {// All unit- and household-specific fields
        !entry.isBuilding && (
          <div>
            <Form.Item label="Name" {...formLayout}>
              {getFieldDecorator(
                "name",
                getFieldDecoratorOptions("name")
              )(
                <Input placeholder="Name of head" disabled={role !== EN_USER} />
              )}
            </Form.Item>
            <Form.Item label="Males" {...formLayout}>
              {getFieldDecorator(
                "males",
                getFieldDecoratorOptions("males")
              )(
                <InputNumber
                  min={0}
                  placeholder="0"
                  disabled={role !== EN_USER}
                />
              )}
            </Form.Item>
            <Form.Item label="Females" {...formLayout}>
              {getFieldDecorator(
                "females",
                getFieldDecoratorOptions("females")
              )(
                <InputNumber
                  min={0}
                  placeholder="0"
                  disabled={role !== EN_USER}
                />
              )}
            </Form.Item>
            <Form.Item label="Interviewed" {...formLayout}>
              {getFieldDecorator(
                "persons_interviewed",
                getFieldDecoratorOptions("persons_interviewed")
              )(
                <InputNumber
                  min={0}
                  placeholder="0"
                  disabled={role !== EN_USER}
                />
              )}
            </Form.Item>
            <Form.Item label="Result Code" {...formLayout}>
              {getFieldDecorator(
                "result",
                getFieldDecoratorOptions("result")
              )(
                <Select
                  placeholder="Select result"
                  disabled={role !== EN_USER}
                  onChange={handleSelectChange}
                >
                  <Option value={1}>Complete</Option>
                  <Option value={2}>Partially Complete</Option>
                  <Option value={3}>No suitable respondent</Option>
                  <Option value={4}>Refusal</Option>
                  <Option value={5}>No contact</Option>
                  <Option value={0}>Other</Option>
                </Select>
              )}
              <br />
            </Form.Item>
            <Form.Item label="Callback" {...formLayout}>
              {getFieldDecorator(
                "callback",
                getFieldDecoratorOptions("callback")
              )(
                <DatePicker
                  showTime
                  format="YYYY-MM-DD HH:mm"
                  onChange={handleDateChange}
                  placeholder="Date and time"
                  disabled={role !== EN_USER}
                />
              )}
            </Form.Item>
            {role === SU_USER && (
              <Form.Item label="Interview type" {...formLayout}>
                {getFieldDecorator(
                  "interview_type",
                  getFieldDecoratorOptions("interview_type")
                )(
                  <Select placeholder="Select interview type">
                    <Option value={0}>Normal</Option>
                    <Option value={1}>Live interviewed</Option>
                    <Option value={2}>Re-interviewed</Option>
                  </Select>
                )}
              </Form.Item>
            )}
          </div>
        )}
        {// All building-specific fields
        entry.isBuilding && (
          <div>
            <Form.Item label="ED" {...formLayout}>
              {getFieldDecorator(
                "ed",
                getFieldDecoratorOptions("ed")
              )(<Input placeholder="0" disabled={role !== EN_USER} />)}
            </Form.Item>
            <Form.Item label="Building ID" {...formLayout}>
              {getFieldDecorator(
                "building_id",
                getFieldDecoratorOptions("building_id")
              )(<Input placeholder="0" disabled={role !== EN_USER} />)}
            </Form.Item>
            <Form.Item label="Urban/Rural" {...formLayout}>
              {getFieldDecorator(
                "urban_rural",
                getFieldDecoratorOptions("urban_rural")
              )(
                <Select
                  disabled={role !== EN_USER}
                  placeholder="Select urban or rural"
                >
                  <Option value={1}>Urban</Option>
                  <Option value={2}>Rural</Option>
                </Select>
              )}
              <br />
            </Form.Item>
            <Form.Item label="Block" {...formLayout}>
              {getFieldDecorator(
                "block",
                getFieldDecoratorOptions("block")
              )(<Input placeholder="0" disabled={role !== EN_USER} />)}
            </Form.Item>
            <Form.Item label="Enumerator" {...formLayout}>
              {getFieldDecorator(
                "enumerator",
                getFieldDecoratorOptions("enumerator")
              )(
                <Select
                  disabled={role !== EN_USER}
                  placeholder="Select the enumerator responsible"
                >
                  {teamMembers.map(i => {
                    return (
                      <Option key={i} value={i.username}>
                        {i.username}
                      </Option>
                    );
                  })}
                </Select>
              )}
              <br />
            </Form.Item>
          </div>
        )}
        <Form.Item label="Comments" {...formLayout}>
          <List
            size={"small"}
            dataSource={getComments()}
            renderItem={comment => (
              <List.Item>
                <Typography.Text>{comment}</Typography.Text>
              </List.Item>
            )}
          />
        </Form.Item>
        <Form.Item
          label={
            <Typography.Text
              style={length === 0 ? { color: "red" } : { color: "green" }}
            >
              {length}
            </Typography.Text>
          }
          {...formLayout}
        >
          {getFieldDecorator(
            "comment",
            getFieldDecoratorOptions("comment")
          )(
            <TextArea
              rows={4}
              maxLength={600}
              placeholder="Type a comment"
              onKeyUp={handleLength}
            />
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create({ name: "control_form_entry" })(ControlModal);
