import React, { useState } from "react";
import { Modal, Divider, Form, Icon, InputNumber } from "antd";
import { updateBhis } from "../services/census_queryer";
import Text from "antd/lib/typography/Text";

const ControlModal = ({ type, data, role, visible, closeModal, form }) => {
  const {
    validateFieldsAndScroll,
    getFieldDecorator,
    setFields,
    resetFields
  } = form;

  const handleSubmit = e => {
    e.preventDefault();
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        updateBhis(data.interview__key, data.PUI__id, values.bhis)
          .then(res => {
            if (res === "failed") {
              // display error msg
              alert("Something went wrong with this edit");
            } else {
              closeModal();
            }
          })
          .catch(e => {
            console.log(e);
          });
      }
    });
  };

  const getFieldDecoratorOptions = id => {
    if (!visible) return;
    const inputProps = {};

    if (id === "bhis") {
      inputProps.rules = [{ required: true, message: "Please enter number" }];
      return inputProps;
    }
  };

  const formLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
    }
  };

  let symptoms = [];
  if (data != undefined) {
    switch (data.hasSymptoms) {
      case 0:
        symptoms.push("None");
        break;
      case 1:
        if (data.primarySymptoms__1 == 1) {
          symptoms.push("Fever");
        }
        if (data.primarySymptoms__2 == 1) {
          symptoms.push("Cough");
        }
        if (data.primarySymptoms__3 == 1) {
          symptoms.push("Sore Throat");
        }
        if (data.primarySymptoms__4 == 1) {
          symptoms.push("Shortness of breath");
        }
        break;
    }

    switch (data.memberSex) {
      case 1:
        data.sex1 = "Male";
        break;
      case 2:
        data.sex1 = "Female";
        break;
      case 9:
        data.sex1 = "DK/NS";
        break;
    }

    switch(data.latestCountryVisited)
    {
      case 4:
        data.latestCountryVisited1="Afghanistan";
        break;
      case 8:
        data.latestCountryVisited1="Albania";
        break;
      case 10:
        data.latestCountryVisited="Antartica";
        break;
      case 12: 
        data.latestCountryVisited1="Algeria";
        break;
      case 16:
        data.latestCountryVisited1="American Samoa";
        break;
      case 20:
        data.latestCountryVisited1="Andorra";
        break;
      case 24:
        data.latestCountryVisited1="Angola";
        break;
      case 28:
        data.latestCountryVisited1="Antigua and Barbuda";
        break;
      case 31:
        data.latestCountryVisited1="Azerbaijan";
        break;
      case 32: 
        data.latestCountryVisited1="Argentina";
        break;
      case 36:
        data.latestCountryVisited1="Australia";
        break;
      case 40:
        data.latestCountryVisited1="Austria";
        break;
      case 44:
        data.latestCountryVisited1="Bahamas";
        break;
      case 48:
        data.latestCountryVisited1="Bahrain";
        break;
      case 50:
        data.latestCountryVisited1="Bangladesh";
        break;
      case 51:
        data.latestCountryVisited1="Armenia";
        break;
      case 52:
        data.latestCountryVisited1="Barbados";
        break;
      case 56:
        data.latestCountryVisited1="Belgium";
        break;
      case 60: 
        data.latestCountryVisited1="Bermuda";
        break;
      case 64: 
        data.latestCountryVisited1="Bhutan";
        break;
      case 68:
        data.latestCountryVisited1="Bolivia";
        break;
      case 70:
        data.latestCountryVisited1="Bosnia and Herzegovina";
        break;
      case 72:
        data.latestCountryVisited1="Botswana";
        break;
      case 74:
        data.latestCountryVisited1="Bouvet Island";
        break;
      case 76:
        data.latestCountryVisited1="Brazil";
        break;
      case 84:
        data.latestCountryVisited1="Belize";
        break;
      case 86: 
        data.latestCountryVisited1="British Indian Ocean Territory";
        break;
      case 90:
        data.latestCountryVisited1="Solomon Islands";
        break;
      case 92:
        data.latestCountryVisited1="Virgin Islands, British";
        break;
      case 96:
        data.latestCountryVisited1="Brunei";
        break;
      case 100:
        data.latestCountryVisited1="Bulgaria";
        break;
      case 104:
        data.latestCountryVisited1="Myanmar";
        break;
      case 108:
        data.latestCountryVisited1="Burundi";
        break;
      case 112:
        data.latestCountryVisited1="Belarus";
        break;
      case 116:
        data.latestCountryVisited1="Cambodia";
        break;
      case 120:
        data.latestCountryVisited1="Cameroon";
        break;
      case 124:
        data.latestCountryVisited1="Canada";
        break;
      case 132:
        data.latestCountryVisited1="Cape Verde";
        break;
      case 136:
        data.latestCountryVisited1="Cayman Islands";
        break;
      case 140:
        data.latestCountryVisited1="Central African Republic";
        break;
      case 144:
        data.latestCountryVisited1="Sri Lanka";
        break;
      case 148: 
        data.latestCountryVisited1="Chad";
        break;
      case 152: 
        data.latestCountryVisited1="Chile";
        break;
      case 156:
        data.latestCountryVisited1="China";
        break;
      case 158:
        data.latestCountryVisited1="Taiwan";
        break;
      case 162:
        data.latestCountryVisited1="Christmas Island";
        break;
      case 166:
        data.latestCountryVisited1="Cocos (Keeling) Islands";
        break;
      case 170:
        data.latestCountryVisited1="Colombia";
        break;
      case 174: 
        data.latestCountryVisited1="Comoros";
        break;
      case 175:
        data.latestCountryVisited1="Mayotte";
        break;
      case 178:
        data.latestCountryVisited1="Congo";
        break;
      case 180:
        data.latestCountryVisited1="Democratic Repubic of Congo";
        break;
      case 184:
        data.latestCountryVisited1="Cook Islands";
        break;
      case 188:
        data.latestCountryVisited1="Costa Rica";
        break;
      case 191:
        data.latestCountryVisited1="Croatia";
        break;
      case 192:
        data.latestCountryVisited="Cuba";
        break;
      case 196:
        data.latestCountryVisited1="Cyprus";
        break;
      case 203:
        data.latestCountryVisited1="Czech Reoublic";
        break;
      case 204:
        data.latestCountryVisited1="Benin";
        break;
      case 208:
        data.latestCountryVisited1="Denmark";
        break;
      case 212:
        data.latestCountryVisited1="Dominica";
        break;
      case 214:
        data.latestCountryVisited1="Dominican Republic";
        break;
      case 218:
        data.latestCountryVisited1="Ecuador";
        break;
      case 222:
        data.latestCountryVisited1="El Salvador";
        break;
      case 226:
        data.latestCountryVisited1="Equatorial Guinea";
        break;
      case 231:
        data.latestCountryVisited1="Ethiopia";
        break;
      case 232:
        data.latestCountryVisited1="Eritrea";
        break;
      case 233:
        data.latestCountryVisited1="Estonia";
        break;
      case 234:
        data.latestCountryVisited1="Faroe Islands";
        break;
      case 238:
        data.latestCountryVisited1="Falkland Islands (Malvinas)";
        break;
      case 239:
        data.latestCountryVisited1="South Georgia and South Sandwich Islands";
        break;
      case 242:
        data.latestCountryVisited1="Fiji";
        break;
      case 246:
        data.latestCountryVisited1="Finland";
        break;
      case 250:
        data.latestCountryVisited1="France";
        break;
      case 254:
        data.latestCountryVisited1="French Guiana";
        break;
      case 258:
        data.latestCountryVisited1="French Polynesia";
        break;
      case 260:
        data.latestCountryVisited1="French Southern Territories";
        break;
      case 262:
        data.latestCountryVisited1="Djibouti";
        break;
      case 266:
        data.latestCountryVisited1="Gabon";
        break;
      case 268:
        data.latestCountryVisited1="Georgia";
        break;
      case 270:
        data.latestCountryVisited1="Gambia";
        break;
      case 275:
        data.latestCountryVisited1="Palestian Territory";
        break;
      case 276:
        data.latestCountryVisited1="Germany";
        break;
      case 288:
        data.latestCountryVisited1="Ghana";
        break;
      case 292:
        data.latestCountryVisited1="Gibraltar";
        break;
      case 296:
        data.latestCountryVisited1="Kiribati";
        break;
      case 300:
        data.latestCountryVisited1="Greece";
        break;
      case 304:
        data.latestCountryVisited1="Greenland";
        break;
      case 308:
        data.latestCountryVisited1="Grenada";
        break;
      case 312:
        data.latestCountryVisited1="Guadeloupe";
        break;
      case 316:
        data.latestCountryVisited1="Guam";
        break;
      case 320:
        data.latestCountryVisited1="Guatemala";
        break;
      case 324:
        data.latestCountryVisited1="Guinea";
        break;
      case 328:
        data.latestCountryVisited1="Guyana";
        break;
      case 332:
        data.latestCountryVisited1="Haiti";
        break;
      case 334:
        data.latestCountryVisited1="Heard Island and McDonald Islands";
        break;
      case 336:
        data.latestCountryVisited1="Holy See (Vatican City State)";
        break;
      case 340:
        data.latestCountryVisited1="Honduras";
        break;
      case 344:
        data.latestCountryVisited1="Hong Kong";
        break;
      case 348:
        data.latestCountryVisited1="Hungary";
        break;
      case 352:
        data.latestCountryVisited1="Iceland";
        break;
      case 356:
        data.latestCountryVisited1="India";
        break;
      case 360:
        data.latestCountryVisited1="Indonesia";
        break;
      case 364:
        data.latestCountryVisited1="Islamic Replublic of Iran";
        break;
      case 368:
        data.latestCountryVisited1="Iraq";
        break;
      case 372:
        data.latestCountryVisited1="Ireland";
        break;
      case 376:
        data.latestCountryVisited1="Israel";
        break;
      case 380:
        data.latestCountryVisited1="Italy";
        break;
      case 384:
        data.latestCountryVisited1="Ivory Coast";
        break;
      case 388:
        data.latestCountryVisited1="Jamaica";
        break;
      case 392:
        data.latestCountryVisited1="Japan";
        break;
      case 398:
        data.latestCountryVisited1="Kazakhstan";
        break;
      case 400:
        data.latestCountryVisited1="Jordan";
        break;
      case 404:
        data.latestCountryVisited1="Kenya";
        break;
      case 408:
        data.latestCountryVisited1="Democratic People's Republic of Korea";
        break;
      case 410:
        data.latestCountryVisited1="South Korea";
        break;
      case 414:
        data.latestCountryVisited1="Kuwait";
        break;
      case 417:
        data.latestCountryVisited1="Kyrgyzstan";
        break;
      case 418:
        data.latestCountryVisited1="Lao People's Democratic Republic";
        break;
      case 422:
        data.latestCountryVisited1="Lebanon";
        break;
      case 426:
        data.latestCountryVisited1="Lesotho";
        break;
      case 428:
        data.latestCountryVisited1="Latvia";
        break;
      case 430:
        data.latestCountryVisited1="Liberia";
        break;
      case 434:
        data.latestCountryVisited1="Libya";
        break;
      case 438:
        data.latestCountryVisited1="Liechtenstein";
        break;
      case 440:
        data.latestCountryVisited1="Lithuania";
        break;
      case 442:
        data.latestCountryVisited1="Luxembourg";
        break;
      case 446:
        data.latestCountryVisited1="Macao";
        break;
      case 450:
        data.latestCountryVisited1="Madagascar";
        break;
      case 454:
        data.latestCountryVisited1="Malawi";
        break;
      case 458:
        data.latestCountryVisited1="Malaysia";
        break;
      case 462:
        data.latestCountryVisited1="Maldives";
        break;
      case 466:
        data.latestCountryVisited1="Mali";
        break;
      case 470:
        data.latestCountryVisited1="Malta";
        break;
      case 474:
        data.latestCountryVisited1="Martinique";
        break;
      case 478:
        data.latestCountryVisited1="Mauritania";
        break;
      case 480:
        data.latestCountryVisited1="Mauritius";
        break;
      case 484:
        data.latestCountryVisited1="Mexico";
        break;
      case 492:
        data.latestCountryVisited1="Monaco";
        break;
      case 496:
        data.latestCountryVisited1="Mongolia";
        break;
      case 498:
        data.latestCountryVisited1="Republic of Moldova";
        break;
      case 499:
        data.latestCountryVisited1="Montenegro";
        break;
      case 500:
        data.latestCountryVisited1="Montserrat";
        break;
      case 504:
        data.latestCountryVisited1="Morocco";
        break;
      case 508:
        data.latestCountryVisited1="Mozambique";
        break;
      case 512:
        data.latestCountryVisited1="Oman";
        break;
      case 516:
        data.latestCountryVisited1="Namibia";
        break;
      case 520:
        data.latestCountryVisited1="Nauru";
        break;
      case 524:
        data.latestCountryVisited1="Nepal";
        break;
      case 528:
        data.latestCountryVisited1="Netherlands";
        break;
      case 530:
        data.latestCountryVisited1="Netherlands Antilles";
        break;
      case 533:
        data.latestCountryVisited1="Aruba";
        break;
      case 540:
        data.latestCountryVisited1="New Caledonia";
        break;
      case 548:
        data.latestCountryVisited1="Vanuatu";
        break;
      case 554:
        data.latestCountryVisited1="New Zealand";
        break;
      case 558:
        data.latestCountryVisited1="Nicaragua";
        break;
      case 562:
        data.latestCountryVisited1="Niger";
        break;
      case 566:
        data.latestCountryVisited1="Nigeria";
        break;
      case 570:
        data.latestCountryVisited1="Niue";
        break;
      case 574:
        data.latestCountryVisited1="Norfolk Island";
        break;
      case 578:
        data.latestCountryVisited1="Norway";
        break;
      case 580:
        data.latestCountryVisited1="Northern Mariana Islands";
        break;
      case 581:
        data.latestCountryVisited1="United states Minor Outlying Islands";
        break;
      case 583:
        data.latestCountryVisited1="Federated States of Micronesia";
        break;
      case 584:
        data.latestCountryVisited1="Marshall Islands";
        break;
      case 585:
        data.latestCountryVisited1="Palau";
        break;
      case 586:
        data.latestCountryVisited1="Pakistan";
        break;
      case 591:
        data.latestCountryVisited1="Panama";
        break;
      case 598:
        data.latestCountryVisited1="Papua New Guinea";
        break;
      case 600:
        data.latestCountryVisited1="Paraguay";
        break;
      case 604:
        data.latestCountryVisited1="Peru";
        break;
      case 608:
        data.latestCountryVisited1="Philipines";
        break;
      case 612:
        data.latestCountryVisited="Pitcairn";
        break;
      case 616:
        data.latestCountryVisited1="Poland";
        break;
      case 620:
        data.latestCountryVisited1="Portugal";
        break;
      case 624:
        data.latestCountryVisited1="Guinea-Bissau";
        break;
      case 626:
        data.latestCountryVisited1="Timor-Leste";
        break;
      case 630:
        data.latestCountryVisited1="Puerto Rico";
        break;
      case 634:
        data.latestCountryVisited1="Qatar";
        break;
      case 638:
        data.latestCountryVisited1="Réunion";
        break;
      case 642:
        data.latestCountryVisited1="Romania";
        break;
      case 643:
        data.latestCountryVisited1="Russia";
        break;
      case 646:
        data.latestCountryVisited1="Rwanda";
        break;
      case 654:
        data.latestCountryVisited1="Saint Helena, Ascension and Tristan da Cunha";
        break;
      case 659: 
        data.latestCountryVisited1="Saint Kitts and Nevis";
        break;
      case 660:
        data.latestCountryVisited1="Anguilla";
        break;
      case 662:
        data.latestCountryVisited1="Saint Lucia";
        break;
      case 666:
        data.latestCountryVisited1="Saint Pierre and Miquelon";
        break;
      case 670:
        data.latestCountryVisited1="St. Vincent and the Grenadines";
        break;
      case 674:
        data.latestCountryVisited1="San Marino";
        break;
      case 678:
        data.latestCountryVisited1="Sao Tome and Principe";
        break;
      case 682:
        data.latestCountryVisited1="Saudi Arabia";
        break;
      case 686:
        data.latestCountryVisited1="Senegal";
        break;
      case 688:
        data.latestCountryVisited1="Serbia";
        break;
      case 690:
        data.latestCountryVisited1="Seychelles";
        break;
      case 694:
        data.latestCountryVisited1="Sierra Leone";
        break;
      case 702: 
        data.latestCountryVisited1="Singapore";
        break;
      case 703:
        data.latestCountryVisited1="Slovakia";
        break;
      case 704:
        data.latestCountryVisited1="Vietnam";
        break;
      case 705:
        data.latestCountryVisited1="Slovenia";
        break;
      case 706:
        data.latestCountryVisited1="Somalia";
        break;
      case 710:
        data.latestCountryVisited1="South Africa";
        break;
      case 716:
        data.latestCountryVisited1="Zimbabwe";
        break;
      case 724:
        data.latestCountryVisited1="Spain";
        break;
      case 732:
        data.latestCountryVisited1="Western Sahara";
        break;
      case 736:
        data.latestCountryVisited1="Sudan";
        break;
      case 740:
        data.latestCountryVisited1="Suriname";
        break;
      case 744:
        data.latestCountryVisited1="Svalbard and Jan Mayen";
        break;
      case 748:
        data.latestCountryVisited1="Swaziland";
        break;
      case 752:
        data.latestCountryVisited1="Sweden";
        break;
      case 756:
        data.latestCountryVisited1="Switzerland";
        break;
      case 760:
        data.latestCountryVisited1="Syrian Arab Republic";
        break;
      case 762:
        data.latestCountryVisited1="Tajikistan";
        break;
      case 764:
        data.latestCountryVisited1="Thailand";
        break;
      case 768:
        data.latestCountryVisited1="Togo";
        break;
      case 772:
        data.latestCountryVisited1="Tokelau";
        break;
      case 776:
        data.latestCountryVisited1="Tonga";
        break;
      case 780:
        data.latestCountryVisited1="Trinidad and Tobago";
        break;
      case 784:
        data.latestCountryVisited1="United Arab Emirates";
        break;
      case 788:
        data.latestCountryVisited1="Tunisia";
        break;
      case 792:
        data.latestCountryVisited1="Turkey";
        break;
      case 795:
        data.latestCountryVisited1="Turkemistan";
        break;
      case 796:
        data.latestCountryVisited1="Turks and Caicos Islands";
        break;
      case 798:
        data.latestCountryVisited1="Tuvalu";
        break;
      case 800:
        data.latestCountryVisited1="Uganda";
        break;
      case 804:
        data.latestCountryVisited1="Ukraine";
        break;
      case 807:
        data.latestCountryVisited1="Former Yugoslav Republic of Macedonia";
        break;
      case 818:
        data.latestCountryVisited1="Egypt";
        break;
      case 826: 
        data.latestCountryVisited1="United Kingdom";
        break;
      case 831:
        data.latestCountryVisited1="Guernsey";
        break;
      case 832:
        data.latestCountryVisited1="Jersey";
        break;
      case 833:
        data.latestCountryVisited1="Isle of Man";
        break;
      case 834:
        data.latestCountryVisited1="United Republic of Tarzania";
        break;
      case 840:
        data.latestCountryVisited1="United States";
        break;
      case 850:
        data.latestCountryVisited1="Virgin Islands, US";
        break;
      case 854:
        data.latestCountryVisited1="Burkina Faso";
        break;
      case 858:
        data.latestCountryVisited1="Uruguay";
        break;
      case 860:
        data.latestCountryVisited1="Uzbekistan";
        break;
      case 862:
        data.latestCountryVisited1="Venezuela";
        break;
      case 876:
        data.latestCountryVisited1="Wallis and Futuna";
        break;
      case 882:
        data.latestCountryVisited1="Samoa";
        break;
      case 887:
        data.latestCountryVisited1="Yemen";
        break;
      case 894:
        data.latestCountryVisited1="Zambia";
        break;
    }
  }

  return (
    <Modal
      title={data && "PUI - " + data.memberNames}
      visible={visible}
      onOk={handleSubmit}
      onCancel={closeModal}
      style={{ width: "100vh" }}
      maskClosable={false}
      afterClose={() => {
        resetFields();
      }}
      destroyOnClose
    >
      <Divider>
        <Icon type={"edit"} /> {"Edit BHIS No."}
      </Divider>

      <Form layout="horizontal" style={{ alignItems: "center" }}>
        <Form.Item label="BHIS No." {...formLayout}>
          {getFieldDecorator(
            "bhis",
            getFieldDecoratorOptions("bhis")
          )(<InputNumber min={0} placeholder={"000000"} />)}
        </Form.Item>
        <Form.Item label="DOB (dd-mm-yyyy)" {...formLayout}>
          {data != undefined && <Text>{data.dobDay}-{data.dobMonth}-{data.dobYear}</Text>}
        </Form.Item>
        <Form.Item label="Name" {...formLayout}>
          {data != undefined && <Text>{data.memberNames}</Text>}
        </Form.Item>
        <Form.Item label="Main Household PUI" {...formLayout}>
          {data != undefined && <Text>{data.referenceName}</Text>}
        </Form.Item>
        <Form.Item label="Symptoms" {...formLayout}>
          {data != undefined &&
            symptoms.map((i, k) => {
              return (
                <Form.Item key={k} style={{marginBottom: 0}}>
                  <Text key={k}>{i}</Text>
                </Form.Item>
              );
            })}
        </Form.Item>
        <Form.Item label="Has Travelled" {...formLayout}>
          {data != undefined && <Text>{data.travelled}</Text>}
        </Form.Item>
        <Form.Item label="Sample Collected" {...formLayout}>
          {data != undefined && <Text>{data.sampled}</Text>}
        </Form.Item>
        <Form.Item label="Age" {...formLayout}>
          {data != undefined && <Text>{data.memberAge}</Text>}
        </Form.Item>
        <Form.Item label="Sex" {...formLayout}>
          {data != undefined && <Text>{data.sex1}</Text>}
        </Form.Item>
        <Form.Item label="District" {...formLayout}>
          {data != undefined && <Text>{data.district1}</Text>}
        </Form.Item>
        <Form.Item label="Works at" {...formLayout}>
          {data != undefined && <Text>{data.memberWork}</Text>}
        </Form.Item>
        <Form.Item label="Date" {...formLayout}>
          {data != undefined && <Text>{data.pdate}</Text>}
        </Form.Item>
        <Form.Item label="Latest Country Visited" {...formLayout}>
          {data != undefined && <Text>{data.latestCountryVisited1}</Text>}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create({ name: "control_form_entry" })(ControlModal);
