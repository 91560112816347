import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import AddBuilding from "../components/AddBuilding";
import Edit from "../components/EditRow";
import SubTable from "./SubTable";
import { ADD, EDIT, HQ_USER } from "../constants";

const ControlFormBuildings = ({
  buildingData,
  unitData,
  hhData,
  onClick,
  role
}) => {
  //const [expandedBuildingRow, setExpandedBuildingRow] = useState([]);
  //const [expandedUnitRow, setExpandedUnitRow] = useState([]);

  const buildingObj = {
    units: [],
    households: []
  };

  const getMuiTheme = color =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          root: {
            backgroundColor: "#FF000"
          },
          paper: {
            boxShadow: "none"
          }
        },
        MUIDataTableBodyCell: {
          root: {
            backgroundColor: color,
            padding: "4px"
          }
        },
        MUIDataTableFilterListChip: {
          root: {
            backgroundColor: "teal"
          }
        },
        MuiTableCell: {
          root: {
            padding: 0
          },
          paddingCheckbox: {
            padding: 0
          },
          head: {
            padding: "4px"
          }
        },
        MUIDataTableSelectCell: {
          fixedHeaderCommon: {
            backgroundColor: color
          }
        }
      }
    });

  buildingData.forEach(row => {
    // Add new property with text form
    switch (row.building_type) {
      case 0:
        row.building_type2 = "Not Assigned";
        break;
      case 1:
        row.building_type2 = "Residential";
        break;
      case 2:
        row.building_type2 = "Institutional";
        break;
      case 3:
        row.building_type2 = "Educational";
        break;
      case 4:
        row.building_type2 = "Health Care";
        break;
      case 11:
        row.building_type2 = "Mixed";
        break;
      case 12:
        row.building_type2 = "Storage";
        break;
      case 13:
        row.building_type2 = "Assembly";
        break;
      default:
        break;
    }
  });

  buildingData.forEach(row => {
    switch (row.district) {
      case 1:
        row.district = "Corozal";
        break;
      case 2:
        row.district = "Orange Walk";
        break;
      case 3:
        row.district = "Belize";
        break;
      case 4:
        row.district = "Cayo";
        break;
      case 5:
        row.district = "Stann Creek";
        break;
      case 6:
        row.district = "Toledo";
        break;
    }
  });

  const columns_buildings = [
    {
      label: "Type",
      name: "building_type2"
    },
    {
      label: "Urban/Rural",
      name: "urban_rural"
    },
    {
      label: "ED",
      name: "ed"
    },
    {
      label: "Block",
      name: "block"
    },
    {
      label: "BuildingNo",
      name: "building_no"
    },
    ...(role === HQ_USER
      ? [
          {
            label: "District",
            name: "district"
          },
          {
            label: "CTV",
            name: "ctv"
          }
        ]
      : []),
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Edit
              handleClick={() => {
                onClick(EDIT.BUILDING, buildingData[tableMeta.rowIndex]);
              }}
            />
          );
        },
        setCellProps: () => {
          return {
            style: { width: 48, padding: 0 }
          };
        }
      }
    }
  ];

  const columns_units = [
    {
      label: "Type",
      name: "unit_type2"
    },
    {
      label: "Name",
      name: "name"
    },
    {
      label: "Result",
      name: "result"
    },
    {
      label: "Callback Date",
      name: "callback_date"
    },
    {
      label: "Callback Time",
      name: "callback_time"
    },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Edit
              handleClick={() => {
                onClick(EDIT.UNIT, buildingObj.units[tableMeta.rowIndex]);
              }}
            />
          );
        },
        setCellProps: () => {
          return {
            style: { width: 48, padding: 0 }
          };
        }
      }
    }
  ];

  const columns_households = [
    {
      label: "Household",
      name: "household_id"
    },
    {
      label: "Name",
      name: "name"
    },
    {
      label: "Result",
      name: "result"
    },
    {
      label: "Callback Date",
      name: "callback_date"
    },
    {
      label: "Callback Time",
      name: "callback_time"
    },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Edit
              handleClick={() => {
                onClick(EDIT.HH, buildingObj.households[tableMeta.rowIndex]);
              }}
            />
          );
        },
        setCellProps: () => {
          return {
            style: { width: 48, padding: 0 }
          };
        }
      }
    }
  ];

  unitData.forEach(row => {
    switch (row.unit_type) {
      case 1:
        row.unit_type2 = "Dwelling";
        break;
      case 2:
        row.unit_type2 = "Institutional";
        break;
      case 3:
        row.unit_type2 = "Educational";
        break;
      case 4:
        row.unit_type2 = "Health Care";
        break;
      case 5:
        row.unit_type2 = "Business/Mercantile";
        break;
      case 6:
        row.unit_type2 = "Diplomatic";
        break;
      case 7:
        row.unit_type2 = "Governmental";
        break;
      case 8:
        row.unit_type2 = "Religious";
        break;
      case 9:
        row.unit_type2 = "Transportation";
        break;
      case 10:
        row.unit_type2 = "Recreational";
        break;
      case 11:
        row.unit_type2 = "Storage";
        break;
      case 12:
        row.unit_type2 = "Vacant Unit";
        break;
      case 13:
        row.unit_type2 = "Other";
        break;
      default:
        break;
    }
  });

  const options_buildings = {
    selectableRows: "none",
    pagination: true,
    download: false,
    print: false,
    rowsPerPageOptions: [10, 25, 100],
    responsive: "scrollFullHeight",
    customToolbar: () => {
      const addBuilding = () => {
        onClick(ADD.BUILDING, {});
      };
      return <AddBuilding handleClick={addBuilding} />;
    },
    expandableRows: true,
    isRowExpandable: (dataIndex, expandedRows) => {
      // Expand selected row and collapse any expanded row on click
      const row =
        expandedRows.data.filter(d => d.dataIndex === dataIndex).length === 0
          ? [dataIndex]
          : [];
      //setExpandedBuildingRow(row)
      //setExpandedUnitRow([]);
      return true;
    },
    //rowsExpanded: expandedBuildingRow,
    renderExpandableRow: (rowData, rowMeta) => {
      const buildingId = parseInt(buildingData[rowMeta.dataIndex].building_id);
      const addUnit = () => {
        onClick(ADD.UNIT, buildingData[rowMeta.dataIndex]);
      };
      const colSpan = rowData.length + 1;
      // Set all units for building
      buildingObj.units = unitData.filter(
        data => data.building_id === buildingId
      );
      return (
        <SubTable
          colSpan={colSpan}
          theme={getMuiTheme("#20B2AA88") /* lightseagreen ~50% */}
          columns={columns_units}
          data={buildingObj.units}
          options={options_units}
          addOnClick={addUnit}
          typeToAdd="Unit"
        />
      );
    }
  };

  const options_units = {
    selectableRows: "none",
    pagination: false,
    responsive: "scrollFullHeight",
    sort: false,
    filter: false,
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    expandableRows: true,
    isRowExpandable: (dataIndex, expandedRows) => {
      // TEMP - MAKE NO UNIT EXPANDABLE
      return false;
      // Only dwellings are expandable
      /*if (buildingObj.units[dataIndex].unit_type !== 1) return false;
      // Expand selected dwellings and collapse any expanded dwelling on click
      const row = (expandedRows.data.filter(d => d.dataIndex === dataIndex).length === 0) ? [dataIndex] : [];
      setExpandedUnitRow(row)
      return true;*/
    },
    //rowsExpanded: expandedUnitRow,
    renderExpandableRow: (rowData, rowMeta) => {
      const buildingId = parseInt(buildingData[rowMeta.dataIndex].building_id);
      const unitId = parseInt(unitData[rowMeta.dataIndex].unit_id);
      const colSpan = rowData.length + 1;
      const addHh = () => {
        onClick(ADD.HH, buildingObj.units[rowMeta.dataIndex]);
      };
      // Set all households for dwelling
      buildingObj.households = hhData.filter(
        d => d.building_id === buildingId && d.unit_id === unitId
      );
      return (
        <SubTable
          colSpan={colSpan}
          theme={getMuiTheme("#F0808088") /* lightcoral ~50% */}
          columns={columns_households}
          data={buildingObj.households}
          options={options_households}
          addOnClick={addHh}
          typeToAdd="Household"
        />
      );
    }
  };

  //Temp - Until Household is added
  // unitData.forEach(row=>{
  //   switch(row.unit_type)
  //   {
  //     case "Dwelling":
  //     row.unit_type = 1
  //       break
  //     case "Institutional":
  //     row.unit_type = 2
  //       break
  //     case "Educational":
  //     row.unit_typee = 3
  //       break
  //     case "Health Care":
  //     row.unit_type = 4
  //       break
  //     case "Business/Mercantile":
  //     row.unit_type = 5
  //       break
  //     case "Diplomatic":
  //     row.unit_type = 6
  //       break
  //     case "Governmental":
  //     row.unit_type = 7
  //       break
  //     case "Religious":
  //     row.unit_type = 8
  //       break
  //     case "Transportation":
  //     row.unit_type = 9
  //       break
  //     case "Recreational":
  //     row.unit_type = 10
  //       break
  //     case "Storage":
  //     row.unit_type = 11
  //       break
  //     case "Vacant Unit":
  //     row.unit_type = 12
  //       break
  //     case "Other":
  //     row.unit_type = 13
  //       break
  //   }
  // })

  const options_households = {
    selectableRows: "none",
    pagination: false,
    responsive: "scrollFullHeight",
    sort: false,
    filter: false,
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    expandableRows: true,
    isRowExpandable: (dataIndex, expandedRows) => {
      return false;
    }
  };
  return (
    <div>
      <MuiThemeProvider theme={getMuiTheme("#32CD3288") /* limegreen ~50% */}>
        <MUIDataTable
          title="All Buildings (filter down your results)"
          columns={columns_buildings}
          data={buildingData}
          options={options_buildings}
        />
      </MuiThemeProvider>
    </div>
  );
};

export default ControlFormBuildings;
