export const USER_TOKEN = "@pp::Auth";
export const HQ_USER = "hq";
export const EN_USER = "interviewer";
export const SU_USER = "supervisor";
export const ED_USER = "editor";
export const MOH_USER = "moh";
export const DWELLING = 1;
export const ADD = {
  BUILDING: 0,
  UNIT: 1,
  HH: 2
};
export const EDIT = {
  BUILDING: 3,
  UNIT: 4,
  HH: 5
};
