import React, { useState, useEffect } from "react";
import { Layout, Drawer, Icon, Row, Col, Statistic, Card } from "antd";
import Enumeration from "../components/Enumeration";
import Payment from "../components/Payment";
import ResponseRates from "../components/ResponseRates";
import AgeGroups from "../components/AgeGroups";
import Questionnaire from "../components/Questionnaire";
import { MohMenu } from "../components/PortalMenu";
import { useAuth } from "../context/auth";
import ContactListing from "../components/ContactListing";
import { getBuildings, getUnits, getUserHouseholds } from "../services/User";
import ControlModal from "../components/ContactModal";
import Iframe from "react-iframe";
import {
  getCardStatistics,
  getInterviewMOH,
  getIndividuals,
  getDistrict,
} from "../services/census_queryer";
import PuiListing from "../components/PuiList";
import MapContainer from "../components/Map";
import PuiModal from "../components/PuiModal";
import CreateAssignmentModal from "../components/CreateAssignmentModal";

const { Content, Sider } = Layout;

const HqView = () => {
  const [mainView, setMainView] = useState("pui");
  const [subView, setSubView] = useState("units");
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [mobileView, setMobileView] = useState();
  const { authToken } = useAuth();
  const [buildingData, setBuildingData] = useState([]);
  const [unitData, setUnitData] = useState([]);
  const [hhData, setHhData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [pVisible, setPVisible] = useState(false);
  const [aVisible, setAVisible] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [cardStatistics, setCardStatistics] = useState([]);
  const [filter, setFilter] = useState([]);
  const [district, setDistrict] = useState(null);
  let districtBuildingData;
  let districtUnitData;

  const openPuiModal = (type, data) => {
    setPVisible(true);
    setRowData([type, data]);
  };

  const handleAddNew = (type, data) => {
    setVisible(true);
    setRowData([type, data]);
  };

  const openAssignModal = (type, data) => {
    setAVisible(true);
    setRowData([type, data]);
  };

  const handleDynamicFilter = (key) => {
    setMainView("controlForms");
    setFilter([true, key]);
  };

  const handleModalClose = () => {
    setVisible(false);
    setPVisible(false);
    setAVisible(false);
    getInterviewMOH().then((data) => setUnitData(data));
    getIndividuals().then((data) => setBuildingData(data));
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  const toggleMenu = () => {
    if (window.innerWidth < 992) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      window.addEventListener("resize", toggleMenu);
      toggleMenu();
    }

    // getBuildings(authToken.username).then(response => {
    //   if (mounted) setBuildingData(response);
    // });
    // getUnits(authToken.username).then(response => {
    //   if (mounted) setUnitData(response);
    // });

    getDistrict(authToken.username).then((data) => {
      if (mounted) setDistrict(data);
    });

    getInterviewMOH().then((data) => {
      if (mounted) setUnitData(data);
    });

    getIndividuals().then((data) => {
      if (mounted) setBuildingData(data);
    });

    getUserHouseholds(authToken.username).then((response) => {
      if (mounted) setHhData(response);
    });

    getCardStatistics().then((response) => {
      if (mounted) setCardStatistics(response);
    });

    return () => {
      mounted = false;
      window.removeEventListener("resize", toggleMenu);
    };
  }, [authToken]);

  const embedablePlots = [
    { url: "//plot.ly/~gianbz/9.embed?", width: 12, height: 400 },
    { url: "//plot.ly/~gianbz/13.embed?", width: 12, height: 400 },
    { url: "//plot.ly/~gianbz/50.embed?", width: 12, height: 400 },
    { url: "//plot.ly/~gianbz/48.embed?", width: 12, height: 400 },
  ];

  if (district != null && buildingData.length > 1 && unitData.length > 1) {
    switch (district[0].district) {
      case 0:
        districtBuildingData = buildingData;
        districtUnitData = unitData;
        break;
      case 1:
        districtBuildingData = buildingData.filter(
          (i) => i.district == district[0].district
        );
        districtUnitData = unitData.filter(
          (i) => i.contactDistrict == district[0].district
        );
        break;
      case 2:
        districtBuildingData = buildingData.filter(
          (i) => i.district == district[0].district
        );
        districtUnitData = unitData.filter(
          (i) => i.contactDistrict == district[0].district
        );
      case 3:
        districtBuildingData = buildingData.filter(
          (i) => i.district == district[0].district
        );
        districtUnitData = unitData.filter(
          (i) => i.contactDistrict == district[0].district
        );
      case 4:
        districtBuildingData = buildingData.filter(
          (i) => i.district == district[0].district
        );
        districtUnitData = unitData.filter(
          (i) => i.contactDistrict == district[0].district
        );
      case 5:
        districtBuildingData = buildingData.filter(
          (i) => i.district == district[0].district
        );
        districtUnitData = unitData.filter(
          (i) => i.contactDistrict == district[0].district
        );
      case 6:
        districtBuildingData = buildingData.filter(
          (i) => i.district == district[0].district
        );
        districtUnitData = unitData.filter(
          (i) => i.contactDistrict == district[0].district
        );
    }
  }

  return (
    <div>
      <Layout theme="light">
        {mobileView ? (
          <Drawer
            placement="left"
            closable={false}
            onClose={onClose}
            visible={drawerVisible}
          >
            <MohMenu setMainView={setMainView} setSubView={setSubView} />
          </Drawer>
        ) : (
          <Sider breakpoint="md" collapsedWidth="0" theme="light">
            <MohMenu setMainView={setMainView} setSubView={setSubView} />
          </Sider>
        )}

        <Layout style={{ height: "100vh", overflowY: "auto" }}>
          {mobileView && (
            <span className="hamburger-menu">
              <Icon type="menu" onClick={showDrawer} />
            </span>
          )}

          <Content className="table-margin">
            <ControlModal
              type={rowData[0]}
              data={rowData[1]}
              role={authToken.role}
              visible={visible}
              closeModal={handleModalClose}
            />
            <PuiModal
              data={rowData[1]}
              role={authToken.role}
              visible={pVisible}
              closeModal={handleModalClose}
            />
            <CreateAssignmentModal
              data={rowData[1]}
              role={authToken.role}
              visible={aVisible}
              closeModal={handleModalClose}
            />
            {mainView === "dashboard" && (
              <>
                <Row>
                  {cardStatistics.map((x) => (
                    <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                      <Card style={{ textAlign: "center", margin: "5px" }}>
                        <Statistic
                          title={x.name}
                          value={x.value}
                          valueStyle={{ color: `${x.color}` }}
                          prefix={<Icon type={x.icon} />}
                        />
                      </Card>
                    </Col>
                  ))}
                </Row>
                <Row gutter={[8, 8]}>
                  {embedablePlots.map((plot) => (
                    <Col
                      xs={{ span: 24, offset: 0 }}
                      sm={{ span: 20, offset: 2 }}
                      md={{ span: 18, offset: 3 }}
                      lg={{ span: 20, offset: 2 }}
                      xl={{ span: plot.width, offset: 0 }}
                    >
                      <Iframe
                        frameBorder={0}
                        width="100%"
                        height={plot.height}
                        url={plot.url + "showlink=false&modebar=false"}
                      />
                    </Col>
                  ))}
                </Row>
              </>
            )}
            {mainView === "pui" && (
              <PuiListing
                unitData={districtBuildingData}
                hhData={hhData}
                onClick={openPuiModal}
                role={authToken.role}
              />
            )}
            {mainView === "controlForms" && (
              <ContactListing
                unitData={districtUnitData}
                hhData={hhData}
                extra={districtBuildingData}
                onClick={handleAddNew}
                onAssign={openAssignModal}
                filter={filter}
              />
            )}
            {mainView === "map" && <MapContainer />}
            {mainView === "enumeration" && <Enumeration subView={subView} />}
            {mainView === "responseRates" && (
              <ResponseRates subView={subView} />
            )}
            {mainView === "ageGroups" && <AgeGroups subView={subView} />}
            {mainView === "compare" && <Questionnaire subView={subView} />}
            {mainView === "pay" && <Payment subView={subView} />}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default HqView;
