import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import React from "react";
import moment from 'moment';
import "react-big-calendar/lib/css/react-big-calendar.css";

const CallbacksCalendar = ({ unitData }) => {

    let eventList = [];
    const localizer = momentLocalizer(moment);

    let allViews = Object.keys(Views).map(k => Views[k]);

    unitData.map((i) => {
        var j = 0;
        console.log(i.callback_date + 'T' + i.callback_time)
        eventList.push({
            id: j,
            title: i.name,
            start: new Date(i.callback_date + 'T' + i.callback_time),
            end: new Date(i.callback_date + 'T' + i.callback_time),
            desc: i.name
        })
        j++;
    });

    console.log(eventList)

    return (
        <div>
            <Calendar
                events={eventList}
                views={allViews}
                step={60}
                showMultiDayTimes
                localizer={localizer}
                style={{height: 500}}
                defaultDate={new Date()}
                min = {new Date('2019, 1, 1, 00:00')}
            />
        </div>
    );
};

export default CallbacksCalendar;