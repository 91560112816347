import React, { useState, useEffect } from "react";
import {
  getAgeGroupsNational,
  getAgeGroupsDistrict,
  getAgeGroupsEd
} from "../services/census_queryer";
import AgeGroupsTable from "./AgeGroupsTable";
import Loading from "./Loading";

const AgeGroups = ({ subView }) => {
  const [ageGroupsData, setAgeGroupsData] = useState(null);

  useEffect(() => {
    let mounted = true;

    if (mounted && subView === "ageGroupsNational") {
      getAgeGroupsNational().then(data => setAgeGroupsData(data));
    } else if (mounted && subView === "ageGroupsDistrict") {
      getAgeGroupsDistrict().then(data => setAgeGroupsData(data));
    } else if (mounted && subView === "ageGroupsED") {
      getAgeGroupsEd().then(data => setAgeGroupsData(data));
    } else if (mounted) {
      setAgeGroupsData([]);
    }

    return () => {
      // toggle on clean up
      mounted = false;
    };
  }, [subView]);

  return (
    <div>
      {ageGroupsData ? <AgeGroupsTable data={ageGroupsData} /> : <Loading />}
    </div>
  );
};

export default AgeGroups;
