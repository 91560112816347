import React, { useState, useEffect } from "react";
import { Modal, Form, Select, message } from "antd";
import { ADD, EDIT, MOH_USER, DWELLING } from "../constants";
import {
  getUsers,
  createSSAssignment,
  updateAssigned
} from "../services/census_queryer";

import { useAuth } from "../context/auth";

const { Option } = Select;

const CreateAssignmentModal = ({
  type,
  data,
  role,
  visible,
  closeModal,
  form
}) => {
  const {
    validateFieldsAndScroll,
    getFieldDecorator,
    setFields,
    resetFields
  } = form;
  const { authToken } = useAuth();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getUsers()
        .then(users => setUsers(users))
        .catch(error => setUsers([]));
    }

    return () => {
      mounted = false;
    };
  }, [authToken, type, data]);

  const createAssignment = async contact => {
    const response = await createSSAssignment(contact);

    if (response !== "failed") {
      // Warning: update may fail despite assignment creation success
      await updateAssigned(contact.interview__id, contact.contactListNames);
    }

    return response;
  };

  const handleSubmit = e => {
    e.preventDefault();
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        data.interviewer = values.interviewer;
        createAssignment(data)
          .then(res => {
            if (res === "failed") {
              // display error msg
              message.error("Assignment not created");
            } else {
              message.success("Assignment created");
            }
          })
          .catch(e => {
            message.error("Assignment not created");
          });

        closeModal();
      }
    });
  };

  const getFieldDecoratorOptions = id => {
    if (!visible) return;
    const inputProps = {};

    if (id === "interviewer") {
      inputProps.rules = [
        { required: true, message: "Please select interviewer" }
      ];
      return inputProps;
    }
  };

  const formLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
    }
  };

  return (
    <Modal
      title={"Assign Interview"}
      visible={visible}
      onOk={handleSubmit}
      onCancel={closeModal}
      style={{ width: "100vh" }}
      maskClosable={false}
      afterClose={() => {
        resetFields();
      }}
      destroyOnClose
    >
      <Form layout="horizontal" style={{ alignItems: "center" }}>
        <Form.Item label="Interviewer" {...formLayout}>
          {getFieldDecorator(
            "interviewer",
            getFieldDecoratorOptions("interviewer")
          )(
            <Select placeholder="Select interviewer">
              {users.map((v, k) => {
                return (
                  <Option key={k} value={v.UserId}>
                    {v.UserName}
                  </Option>
                );
              })}
            </Select>
          )}
          <br />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Form.create({ name: "create_assignment" })(
  CreateAssignmentModal
);
