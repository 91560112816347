import React from "react";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

const CodeSearchTable = ( {codeData, tableRef = null} ) => {


    const cols = Object.keys(codeData[0]).map(item => {
    if (item === "District" || item === "ED" || item === "Urban Rural" || item === "Enumerator" || item === "Area") {
        return {
        label: item,
        name: item
        };
    } else {
        return {
        label: item,
        name: item,
        options: {
            filter: false
        }
        };
    }
    })

      const getMuiTheme = () =>
      createMuiTheme({
        overrides: {
          MUIDataTable: {
            root: {
              backgroundColor: "#FF000",
              overflowX: "hidden"
            },
            paper: {
              boxShadow: "none"
            }
          },
          MUIDataTableBodyCell: {
            root: {
              backgroundColor: "#FFF",
              padding: 12
            }
          }
        }
      });


      const options = {
        selectableRows: "none",
        pagination: true,
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10],
        responsive: "scrollMaxHeight",
        search: false,
        download: false,
        viewColumns: true,
        print: false,
        filter: false,
        textLabels: {
            body: {
              noMatch: "No matching codes",
              toolTip: "Sort",
              columnHeaderTooltip: column => `Sort for ${column.label}`
            },
            pagination: {
              next: "Next",
              previous: "Previous",
              rowsPerPage: "Results per page:",
              displayRows: "of",
            }
        }
      }
      

    return (
        <>
            <div style={{ maxWidth: "100%" }}>
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                    innerRef={tableRef}
                    title='Code Search'
                    columns={cols}
                    data={codeData}
                    options={options}
                    />
                </MuiThemeProvider>
            </div>
        </>
    )
}

export default CodeSearchTable