import React, { useState, useEffect } from "react";
import {
  getBuildingSummaries
} from "../services/census_queryer";
import BuildingSummaryTable from "./BuildingSummaryTable";
import { Tabs } from "antd";
import Loading from "./Loading";

const { TabPane } = Tabs;

const Payment = ({ subView }) => {
  const [buildingSummaries, setBuildingSummaries] = useState(null);
  const [paymentSummaries, setPaymentSummaries] = useState(null);
  const [tableTitle, setTableTitle] = useState(null);
  const [finalTab, setFinalTab] = useState(null);


  useEffect(() => {
    let mounted = true;

    if (mounted && subView === "approvals") {
      setTableTitle("Building Approvals")
      getBuildingSummaries().then(response => {
        setBuildingSummaries(response);
      });
    } else if (mounted && subView === "summaries") {
      setTableTitle("Payment Approvals")
      setFinalTab("Urban Rural")
    }

    return () => {
      // toggle on clean up
      mounted = false;
    };
  }, [subView]);

  function callback(key) {}

  return (
    <>
        {buildingSummaries ? <BuildingSummaryTable data={buildingSummaries} title={tableTitle} /> : <Loading />}
    </>
  );
};

export default Payment;
