import React from "react";
import Iframe from "react-iframe";

const MapContainer = () => {
  return (
    <Iframe
      url="https://gis.sib.org.bz/covid_map/"
      width="100%"
      height="750"
      id="map"
      className="map-container"
      display="initial"
      position="relative"
    />
  );
};

export default MapContainer;
