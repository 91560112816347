import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../context/auth";
import { SU_USER } from "../constants";

function SuperRoute({ component: Component, ...rest }) {
  const { authToken } = useAuth();
  // Generic user - delete after development
  if (authToken && authToken.username === "test") authToken.role = SU_USER;

  return (
    <Route
      {...rest}
      render={props =>
        authToken && authToken.role === SU_USER ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

export default SuperRoute;
