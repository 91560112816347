import React, { useState, useEffect } from "react";
import { Input, Select, Tag, Tooltip } from "antd";
import { getIsicData, getIscoData, getIscedData, getSuggestedCode, getFuseConfig, getBceaData } from "../services/census_queryer";
import CodeSearchTable  from './CodeSearchTable'
import Fuse from 'fuse.js'

const { Option } = Select;

// const options = {
//   shouldSort: true,
//   threshold: 0.4,
//   location: 0,
//   distance: 800,
//   tokenize: true,
//   matchAllTokens: false,
//   maxPatternLength: 44,
//   minMatchCharLength: 1,
//   keys: [
//     "ISIC",
//     "Description",
//     "ISCO4",
//     "Definition",
//     "Title",
//     "IncludedOccupations"
//   ]
// };

const CodeSearch = ( ) => {

    const tableRef = React.useRef();

    const[codeData, setCodeData] = useState([]) 
    const[tableData, setTableData] = useState([])
    const[isicData, setIsicData] = useState([])
    const[iscoData, setIscoData] = useState([])
    const[iscedData, setIscedData] = useState([])
    const[bceaData, setBceaData] = useState([])
    const[codeType, setCodeType] = useState('isco')

    const [suggestedCode, setSuggestedCode] = useState('');
    const [token, setToken] = useState('');
    const [fuseConfigs, setFuseConfigs] = useState({});
    const copyRef = React.useRef()
  

    //INITIALIZE DATA 
    useEffect(() => {

        let mounted = true;

        // getIsicData().then(response => {
        //   if (mounted) {setIsicData(response); setCodeData(response)}
        // });
        // getIscedData().then(response => {
        //   if (mounted) setIscedData(response);
        // });

        getIscoData().then(response => {
          if (mounted) { setIscoData(response); setCodeData(response) }
        });

        getBceaData().then(response => {
           if (mounted) setBceaData(response);
        });

        getFuseConfig().then(response => {
          if (mounted) setFuseConfigs(response);
        })

        return () => { mounted = false; }
    
    }, []);

    //HANDLES TOKEN CHANGE
    useEffect(()=>{
      
      let mounted = true;

      if(mounted){setSuggestedCode("")}

      const timer = setTimeout(()=>{

        const fuse = new Fuse(codeData, fuseConfigs)
        let result = fuse.search(token)  
        if(mounted) setTableData(result)
      
        //check if the token is a number to Prevent fetching suggested code
        if( Number.isInteger(+token) ){
          if(mounted) { setSuggestedCode("") }
        }
        
        else{
          //make use of the isic route if bcea
          const _codeType = codeType === 'bcea' ? 'isic' : codeType
          getSuggestedCode(_codeType, token).then( code => {
            if(mounted) { setSuggestedCode(code) }
          });
        }
      
        // if(result.length) tableRef.current.changePage(0);

   
      }, [900])

      return () => { clearTimeout(timer); mounted = false; };

    }, [token, codeType, codeData, fuseConfigs] )


    const selectAfter = (
        <Select defaultValue="isco" style={{ width: 80 }} onChange={(value) => onCodeChange(value)}>
          {/* <Option value="isic">ISIC</Option> */}
          <Option value="isco">ISCO</Option>
          <Option value="bcea">BCEA</Option>
          {/* <Option value="isced">ISCED</Option> */}
        </Select> 
      );

    const onCodeChange = (type) => {     
        if (type === "isco") { setCodeData(iscoData)}
        if (type === "isic") { setCodeData(isicData)}
        if (type === "isced") { setCodeData(iscedData)}
        if (type === "bcea") { setCodeData(bceaData)}
        setCodeType(type)
      }

      const copyClipboard = () => {
        // navigator.clipboard.writeText(copyRef.current.innerText)
        try{
          // Create new element
          const el = document.createElement('textarea');
          // Set value (string to be copied)
          el.value = suggestedCode;
          // Set non-editable to avoid focus and move outside of view
          el.setAttribute('readonly', '');
          el.style = {position: 'absolute', left: '-9999px'};
          document.body.appendChild(el);
          // Select text inside element
          el.select();
          // Copy text to clipboard
          document.execCommand('copy');
          // Remove temporary element
          document.body.removeChild(el);

        } catch(e) {
          console.log("Copy Clipboard Failed: ", e)
        }
       
      }

    
    const suggestedCodeTemplate =  () => {
      return suggestedCode ? <div style={{ marginBottom: 16 }}>
        Suggested Code:  <Tooltip title="Copy"  placement="right">
          <Tag color="#87d068" onClick={copyClipboard} style={{cursor: "pointer"}}> <span id="copyREF" ref={copyRef} >  {suggestedCode} </span> </Tag>
        </Tooltip>
      </div> : null
    }

    const prepareTableData = () => {

      //USED TO APPEND FLASK RECORD ISCO
      if(codeType === "isco" && suggestedCode){
        let alreadyExistInTableData = false;
        let copyTableData = tableData.map( el=>{ 
          if( el.ISCO4 == suggestedCode ) alreadyExistInTableData = true;
          return el;
        });
        if(!alreadyExistInTableData){
          const suggestedRecord = iscoData.find(el=> +el.ISCO4 === +suggestedCode);
          if(!suggestedRecord) return copyTableData;
          copyTableData.unshift(suggestedRecord);
          return copyTableData
        }
        return copyTableData;
      }

      return tableData;
      
    }

    let suggestedTableData = prepareTableData();

    
    return (
        <>
            <div style={{ marginBottom: 16 }}>
                <Input addonAfter={selectAfter} placeholder='Enter a keyword or phrase' onKeyUp={(e)=>setToken(e.target.value)} />
            </div>
           { suggestedCodeTemplate()}
            {suggestedTableData.length > 0 &&
                <CodeSearchTable tableRef={tableRef} codeData={suggestedTableData} />}
        </>
    )
} 

export default CodeSearch