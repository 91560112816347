import React, { useState, useEffect } from "react";
import {
  getResponseRatesNational,
  getResponseRatesDistrict,
  getResponseRatesEd,
  getResponseRatesEnum,
  getResponseCountsNational,
  getResponseCountsDistrict,
  getResponseCountsEd,
  getResponseCountsEnum
} from "../services/census_queryer";
import ResponseRatesTable from "./ResponseRatesTable";
import { Tabs } from "antd";
import Loading from "./Loading";

const { TabPane } = Tabs;

const ResponseRates = ({ subView }) => {
  const [responseNational, setResponseNational] = useState(null);
  const [responseDistrict, setResponseDistrict] = useState(null);
  const [responseED, setResponseED] = useState(null);
  const [responseEnumerator, setResponseEnumerator] = useState(null);

  useEffect(() => {
    let mounted = true;

    if (mounted && subView === "responseRates") {
      getResponseRatesNational().then(data => setResponseNational(data));
      getResponseRatesDistrict().then(data => setResponseDistrict(data));
      getResponseRatesEd().then(data => setResponseED(data));
      getResponseRatesEnum().then(data => setResponseEnumerator(data));
    } else if (mounted && subView === "responseCounts") {
      getResponseCountsNational().then(data => setResponseNational(data));
      getResponseCountsDistrict().then(data => setResponseDistrict(data));
      getResponseCountsEd().then(data => setResponseED(data));
      getResponseCountsEnum().then(data => setResponseEnumerator(data));
    }

    return () => {
      // toggle on clean up
      mounted = false;
    };
  }, [subView]);

  function callback(key) {}

  return (
    <Tabs defaultActiveKey="1" onChange={callback}>
      <TabPane tab="National" key="1">
        {responseNational ? (
          <ResponseRatesTable data={responseNational} />
        ) : (
          <Loading />
        )}
      </TabPane>
      <TabPane tab="District Area" key="2">
        {responseDistrict ? (
          <ResponseRatesTable data={responseDistrict} />
        ) : (
          <Loading />
        )}
      </TabPane>
      <TabPane tab="ED" key="3">
        {responseED ? <ResponseRatesTable data={responseED} /> : <Loading />}
      </TabPane>
      <TabPane tab="Enumerators" key="4">
        {responseEnumerator ? (
          <ResponseRatesTable data={responseEnumerator} />
        ) : (
          <Loading />
        )}
      </TabPane>
    </Tabs>
  );
};

export default ResponseRates;
