import React, { useState, useEffect } from "react";
import {
  getEnumRatesNational,
  getEnumRatesDistrict,
  getEnumRatesEd,
  getEnumRatesEnum,
  getCompositionNational,
  getCompositionDistrict,
  getCompositionEd,
  getCompositionUrbanRural
} from "../services/census_queryer";
import EnumTable from "./EnumTable";
import { Tabs } from "antd";
import Loading from "./Loading";

const { TabPane } = Tabs;

const Enumeration = ({ subView }) => {
  const [enumDataNational, setEnumDataNational] = useState(null);
  const [enumDataDistrict, setEnumDataDistrict] = useState(null);
  const [enumDataED, setEnumDataED] = useState(null);
  const [enumDataEnumerator, setEnumDataEnumerator] = useState(null);
  const [enumDataUrbanRural, setEnumDataUrbanRural] = useState(null);
  const [tableTitle, setTableTitle] = useState(null);
  const [finalTab, setFinalTab] = useState(null);


  useEffect(() => {
    let mounted = true;

    if (mounted && subView === "coverage") {
      setTableTitle("Building Coverage")
      setFinalTab("Enumerator")
      getEnumRatesNational().then(data => setEnumDataNational(data));
      getEnumRatesDistrict().then(data => setEnumDataDistrict(data));
      getEnumRatesEd().then(data => setEnumDataED(data));
      getEnumRatesEnum().then(data => setEnumDataEnumerator(data));
    } else if (mounted && subView === "composition") {
      setTableTitle("Buildings and Household Composition")
      setFinalTab("Urban Rural")
      getCompositionNational().then(data => setEnumDataNational(data));
      getCompositionDistrict().then(data => setEnumDataDistrict(data));
      getCompositionEd().then(data => setEnumDataED(data));
      getCompositionUrbanRural().then(data => setEnumDataEnumerator(data));
    } else if (mounted) {
      setEnumDataNational([]);
    }

    return () => {
      // toggle on clean up
      mounted = false;
    };
  }, [subView]);

  function callback(key) {}

  return (
    <Tabs defaultActiveKey="1" onChange={callback} animated={false}>
      <TabPane tab="National" key="1">
        {enumDataNational ? <EnumTable data={enumDataNational} title={tableTitle} /> : <Loading />}
      </TabPane>
      <TabPane tab="District" key="2">
        {enumDataDistrict ? <EnumTable data={enumDataDistrict} title={tableTitle}  /> : <Loading />}
      </TabPane>
      <TabPane tab="ED" key="3">
        {enumDataED ? <EnumTable data={enumDataED} title={tableTitle}  /> : <Loading />}
      </TabPane>
      <TabPane tab={finalTab} key="4">
        {enumDataED ? <EnumTable data={enumDataEnumerator} title={tableTitle}  /> : <Loading />}
      
      </TabPane>
    </Tabs>
  );
};

export default Enumeration;
