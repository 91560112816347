import React, { useState } from "react";
import { Menu, Icon, Divider, Avatar, Dropdown, Button } from "antd";
import { useAuth } from "../context/auth";
import CensusLogo from "../assets/images/Census2020_logo.svg";
import CensusLogoTeal from "../assets/images/Census2020_Teal.svg";
import CensusLogoWhite from "../assets/images/Census2020logo_white.svg";
import { animateScroll } from "react-scroll";

const { SubMenu } = Menu;

const Logo = () => {
  return (
    <div
      style={{
        overflowX: "hidden",
        overflowY: "auto",
        padding: 5,
        textAlign: "center"
      }}
    >
      <img src={CensusLogo} height={90} width={90} />
    </div>
  );
};

export const EditorMenu = ({ setMainView, setSubView }) => {
  const [openKeyss, setOpenKeys] = useState(["codesearch"]);
  const { authToken, setAuthToken } = useAuth();

  const logout = () => {
    setAuthToken();
  };

  const menuItems = [
    {
      key: "codesearch",
      icon: "file-search",
      text: "Code Search",
      sub: []
    },
    {
      key: "controlForms",
      icon: "form",
      text: "Control Forms",
      sub: [
        {
          key: "buildings",
          text: "Buildings"
        },
        {
          key: "units",
          text: "Units"
        }
      ]
    }
  ];

  const rootSubmenuKeys = ["responseRates", "controlForms"];

  const onOpenChange = openKeys => {
    const latestOpenKey = openKeys.find(key => openKeyss.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(openKeys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <div>
      <div style={{ height: "32vh", overflowX: "hidden", overflowY: "auto" }}>
        <h1
          style={{
            paddingTop: 20,
            fontSize: 22,
            fontWeight: "500",
            textAlign: "center",
            color: "rgba(0,0,0,0.5)"
          }}
        >
          Editor Portal
        </h1>
        <div style={{ textAlign: "center", width: "100%" }}>
          <Avatar size={80} icon="user" className="avatar" />
          <Dropdown
            overlay={() => (
              <Menu>
                <Menu.Item key="logout" onClick={logout}>
                  <Icon type="logout" /> Logout
                </Menu.Item>
              </Menu>
            )}
          >
            <Button
              style={{
                marginBlockStart: "1em",
                borderColor: "rgba(0,0,0,0.07)",
                borderRadius: 50
              }}
            >
              {authToken.fname + " " + authToken.lname} <Icon type="down" />
            </Button>
          </Dropdown>
        </div>
        <Divider style={{ backgroundColor: "light gray", height: 4 }} />
      </div>

      <Menu
        theme="light"
        mode="inline"
        defaultSelectedKeys={["codesearch"]}
        openKeys={openKeyss}
        onOpenChange={onOpenChange}
        onClick={({ item, key, keyPath }) => {
          let mainMenuItem = keyPath[keyPath.length - 1];
          setMainView(mainMenuItem);
          setSubView(key);
        }}
        style={{ height: "53vh", overflowX: "hidden", overflowY: "auto" }}
      >
        {menuItems.map(item => {
          if (item.sub.length > 1)
            return (
              <SubMenu
                key={item.key}
                title={
                  <span>
                    <Icon type={item.icon} /> {item.text}
                  </span>
                }
              >
                {item.sub.map(subitem => (
                  <Menu.Item key={subitem.key}>{subitem.text}</Menu.Item>
                ))}
              </SubMenu>
            );
          else
            return item.key == "divider" ? (
              <Divider
                key={item.key}
                style={{ backgroundColor: "light gray", height: 4 }}
              />
            ) : (
              <Menu.Item key={item.key} onClick={() => setOpenKeys([])}>
                <span>
                  <Icon type={item.icon} /> {item.text}
                </span>
              </Menu.Item>
            );
        })}
      </Menu>

      <Logo />
    </div>
  );
};

export const HqMenu = ({ setMainView, setSubView }) => {
  const [openKeyss, setOpenKeys] = useState("");
  const { authToken, setAuthToken } = useAuth();

  const logout = () => {
    setAuthToken();
  };

  const menuItems = [
    {
      key: "dashboard",
      icon: "compass",
      text: "Overview",
      sub: [
        {
          sub2: []
        }
      ]
    },
    {
      key: "enumeration",
      icon: "carry-out",
      text: "Enumeration",
      sub: [
        {
          key: "coverage",
          text: "Coverage"
        },
        {
          key: "composition",
          text: "Composition"
        },
        {
          key: "counts",
          text: "Counts"
        }
      ]
    },
    {
      key: "responseRates",
      icon: "home",
      text: "HH Response",
      sub: [
        {
          key: "responseRates",
          text: "Rates"
        },
        {
          key: "responseCounts",
          text: "Counts"
        }
      ]
    },
    {
      key: "ageGroups",
      icon: "bar-chart",
      text: "Indicators",
      sub: [
        {
          key: "ageGroupsNational",
          text: "Sex Ratio"
        },
        {
          key: "ageGroupsDistrict",
          text: "Children Under 5"
        },
        {
          key: "ageGroupsED",
          text: "Age Groups"
        },
        {
          key: "ageGroupsTeam",
          text: "Labour Force Rates"
        }
      ]
    },
    {
      key: "divider",
      sub: []
    },
    {
      key: "controlForms",
      icon: "form",
      text: "Control Forms",
      sub: [
        {
          key: "buildings",
          text: "Buildings"
        },
        {
          key: "units",
          text: "Units"
        }
      ]
    },
    {
      key: "compare",
      icon: "team",
      text: "Reinterviews",
      sub: [
        {
          sub2: []
        }
      ]
    },
    {
      key: "divider",
      sub: []
    },
    {
      key: "pay",
      icon: "dollar",
      text: "Payment",
      sub: [
        {
          key: "approvals",
          text: "Approve Cases"
        },
        {
          key: "summaries",
          text: "Approve Staff"
        }
      ]
    }
  ];

  const rootSubmenuKeys = [
    "responseRates",
    "enumeration",
    "ageGroups",
    "controlForms",
    "pay"
  ];

  const onOpenChange = openKeys => {
    const latestOpenKey = openKeys.find(key => openKeyss.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(openKeys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }

    if (openKeys[0] === "pay") {
      animateScroll.scrollToBottom({ containerId: "hq-menu", duration: 300 });
    }
  };

  return (
    <div>
      <div style={{ height: "32vh", overflowX: "hidden", overflowY: "auto" }}>
        <h1
          style={{
            paddingTop: 20,
            fontSize: 22,
            fontWeight: "500",
            textAlign: "center",
            color: "rgba(0,0,0,0.5)"
          }}
        >
          HQ Portal
        </h1>
        <div style={{ textAlign: "center", width: "100%" }}>
          <Avatar size={80} icon="user" className="avatar" />
          <Dropdown
            overlay={() => (
              <Menu>
                <Menu.Item key="logout" onClick={logout}>
                  <Icon type="logout" /> Logout
                </Menu.Item>
              </Menu>
            )}
          >
            <Button
              style={{
                marginBlockStart: "1em",
                borderColor: "rgba(0,0,0,0.07)",
                borderRadius: 50
              }}
            >
              {authToken.fname + " " + authToken.lname} <Icon type="down" />
            </Button>
          </Dropdown>
        </div>
        <Divider style={{ backgroundColor: "light gray", height: 4 }} />
      </div>

      <Menu
        id={"hq-menu"}
        theme="light"
        mode="inline"
        defaultSelectedKeys={["dashboard"]}
        openKeys={openKeyss}
        onOpenChange={onOpenChange}
        onClick={({ item, key, keyPath }) => {
          let mainMenuItem = keyPath[keyPath.length - 1];
          setMainView(mainMenuItem);
          setSubView(key);
        }}
        style={{
          height: "68vh",
          overflowX: "hidden",
          overflowY: "auto"
        }}
      >
        {menuItems.map(item => {
          if (item.sub.length > 1)
            return (
              <SubMenu
                key={item.key}
                title={
                  <span>
                    <Icon type={item.icon} /> {item.text}
                  </span>
                }
              >
                {item.sub.map(subitem => (
                  <Menu.Item key={subitem.key}>{subitem.text}</Menu.Item>
                ))}
              </SubMenu>
            );
          else
            return item.key == "divider" ? (
              <Divider
                key={item.key}
                style={{ backgroundColor: "light gray", height: 4 }}
              />
            ) : (
              <Menu.Item key={item.key} onClick={() => setOpenKeys([])}>
                <span>
                  <Icon type={item.icon} /> {item.text}
                </span>
              </Menu.Item>
            );
        })}
        <Logo />
      </Menu>
    </div>
  );
};

// ### MOH ###
export const MohMenu = ({ setMainView, setSubView }) => {
  const [openKeyss, setOpenKeys] = useState("pui");
  const { authToken, setAuthToken } = useAuth();

  const logout = () => {
    setAuthToken();
  };

  const menuItems = [
    // {
    //   key: "dashboard",
    //   icon: "compass",
    //   text: "Overview",
    //   sub: [
    //     {
    //       sub2: []
    //     }
    //   ]
    // },
    // {
    //   key: "enumeration",
    //   icon: "carry-out",
    //   text: "Enumeration",
    //   sub: [
    //     {
    //       key: "coverage",
    //       text: "Coverage"
    //     },
    //     {
    //       key: "composition",
    //       text: "Composition"
    //     },
    //     {
    //       key: "counts",
    //       text: "Counts"
    //     }
    //   ]
    // },
    // {
    //   key: "responseRates",
    //   icon: "home",
    //   text: "HH Response",
    //   sub: [
    //     {
    //       key: "responseRates",
    //       text: "Rates"
    //     },
    //     {
    //       key: "responseCounts",
    //       text: "Counts"
    //     }
    //   ]
    // },
    // {
    //   key: "ageGroups",
    //   icon: "bar-chart",
    //   text: "Indicators",
    //   sub: [
    //     {
    //       key: "ageGroupsNational",
    //       text: "Sex Ratio"
    //     },
    //     {
    //       key: "ageGroupsDistrict",
    //       text: "Children Under 5"
    //     },
    //     {
    //       key: "ageGroupsED",
    //       text: "Age Groups"
    //     },
    //     {
    //       key: "ageGroupsTeam",
    //       text: "Labour Force Rates"
    //     }
    //   ]
    // },
    // {
    //   key: "divider",
    //   sub: []
    // },
    {
      key: "pui",
      icon: "form",
      text: "PUIs",
      sub: []
    },
    {
      key: "controlForms",
      icon: "contacts",
      text: "Contacts",
      sub: []
    },
    {
      key: "map",
      icon: "environment",
      text: "Map",
      sub: []
    }
    // {
    //   key: "compare",
    //   icon: "team",
    //   text: "Reinterviews",
    //   sub: [
    //     {
    //       sub2: []
    //     }
    //   ]
    // },
    // {
    //   key: "divider",
    //   sub: []
    // },
    // {
    //   key: "pay",
    //   icon: "dollar",
    //   text: "Payment",
    //   sub: [
    //     {
    //       key: "approvals",
    //       text: "Approve Cases"
    //     },
    //     {
    //       key: "summaries",
    //       text: "Approve Staff"
    //     }
    //   ]
    // }
  ];

  const rootSubmenuKeys = [
    "responseRates",
    "enumeration",
    "ageGroups",
    "controlForms",
    "pay"
  ];

  const onOpenChange = openKeys => {
    const latestOpenKey = openKeys.find(key => openKeyss.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(openKeys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }

    if (openKeys[0] === "pay") {
      animateScroll.scrollToBottom({ containerId: "hq-menu", duration: 300 });
    }
  };

  return (
    <div>
      <div style={{ overflowX: "hidden", overflowY: "auto" }}>
        <h1
          style={{
            paddingTop: 20,
            fontSize: 22,
            fontWeight: "500",
            textAlign: "center",
            color: "rgba(0,0,0,0.5)"
          }}
        >
          MoH Portal
        </h1>
        <div style={{ textAlign: "center", width: "100%" }}>
          <Avatar size={80} icon="user" className="avatar" />
          <Dropdown
            overlay={() => (
              <Menu>
                <Menu.Item key="logout" onClick={logout}>
                  <Icon type="logout" /> Logout
                </Menu.Item>
              </Menu>
            )}
          >
            <Button
              style={{
                marginBlockStart: "1em",
                borderColor: "rgba(0,0,0,0.07)",
                borderRadius: 50
              }}
            >
              {authToken.fname + " " + authToken.lname} <Icon type="down" />
            </Button>
          </Dropdown>
        </div>
        <Divider style={{ backgroundColor: "light gray", height: 4 }} />
      </div>

      <Menu
        id={"hq-menu"}
        theme="light"
        mode="inline"
        defaultSelectedKeys={["pui"]}
        openKeys={openKeyss}
        onOpenChange={onOpenChange}
        onClick={({ item, key, keyPath }) => {
          let mainMenuItem = keyPath[keyPath.length - 1];
          setMainView(mainMenuItem);
          setSubView(key);
        }}
        style={{
          height: "68vh",
          overflowX: "hidden",
          overflowY: "auto"
        }}
      >
        {menuItems.map(item => {
          if (item.sub.length > 1)
            return (
              <SubMenu
                key={item.key}
                title={
                  <span>
                    <Icon type={item.icon} /> {item.text}
                  </span>
                }
              >
                {item.sub.map(subitem => (
                  <Menu.Item key={subitem.key}>{subitem.text}</Menu.Item>
                ))}
              </SubMenu>
            );
          else
            return item.key == "divider" ? (
              <Divider
                key={item.key}
                style={{ backgroundColor: "light gray", height: 4 }}
              />
            ) : (
              <Menu.Item key={item.key} onClick={() => setOpenKeys([])}>
                <span>
                  <Icon type={item.icon} /> {item.text}
                </span>
              </Menu.Item>
            );
        })}
        {/* <Logo /> */}
      </Menu>
    </div>
  );
};
