import React from "react";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Text from "antd/lib/typography/Text";
import EditRow from "./EditRow";
//import Contacts from "../components/ContactsButton";

const ControlFormUnits = ({ unitData, hhData, onClick, role }) => {
  const getMuiTheme = color =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          root: {
            backgroundColor: "#FF000"
          },
          paper: {
            boxShadow: "none"
          }
        }
      }
    });

  unitData != undefined && unitData.forEach(row => {
    switch (parseInt(row.hasSymptoms)) {
      case 1:
        for (const [key, value] of Object.entries(row)) {
          if (key === "primarySymptoms__1" && value === 1) {
            row.symptoms = "Fever";
          } else if (key === "primarySymptoms__2" && value === 1) {
            row.symptoms = "Cough";
          } else if (key === "primarySymptoms__3" && value === 1) {
            row.symptoms = "Sore throat";
          } else if (key === "primarySymptoms__4" && value === 1) {
            row.symptoms = "Shortness of breath";
          }
        }
        break;
    }
  });

  unitData != undefined && unitData.forEach(row => {
    switch (parseInt(row.internationalTravel)) {
      case 1:
        row.travelled = "Yes";
        break;
      case 2:
        row.travelled = "No";
        break;
    }
  });

  unitData != undefined && unitData.forEach(row => {
    switch (parseInt(row.sampleCollected)) {
      case 1:
        row.sampled = "Yes";
        break;
      case 2:
        row.sampled = "No";
        break;
    }
  });

  unitData != undefined && unitData.forEach(row => {
    switch (parseInt(row.memberSex)) {
      case 1:
        row.sex1 = "Male";
        break;
      case 2:
        row.sex1 = "Female";
        break;
      case 9:
        row.sex1 = "DK/NS";
        break;
    }
  });

  unitData != undefined && unitData.forEach(row => {
    switch (parseInt(row.district)) {
      case 1:
        row.district1 = "Corozal";
        break;
      case 2:
        row.district1 = "Orange Walk";
        break;
      case 3:
        row.district1 = "Belize";
        break;
      case 4:
        row.district1 = "Cayo";
        break;
      case 5:
        row.district1 = "Stann Creek";
        break;
      case 6:
        row.district1 = "Toledo";
        break;
    }
  });

  unitData != undefined && unitData.forEach(row => {
    row.pdate = row.date.split("T")[0];
  });

  const columns_units = [
    {
      label: "Interview Key",
      name: "interview__key"
    },
    {
      label: "PUI ID",
      name: "PUI__id"
    },
    {
      label: "BHIS No.",
      name: "bhis"
    },
    {
      label: "Date",
      name: "pdate"
    },
    {
      label: "Name",
      name: "memberNames"
    },
    {
      label: "Phone Number",
      name: "memberContactNo"
    },
    {
      label: "Has Symptoms",
      name: "symptoms"
    },
    {
      label: "Has Travelled",
      name: "travelled"
    },
    {
      label: "Sample Collected",
      name: "sampled"
    },
    {
      label: "Age",
      name: "memberAge"
    },
    {
      label: "Sex",
      name: "sex1"
    },
    {
      label: "District",
      name: "district1"
    },
    {
      label: "Works at",
      name: "memberWork"
    },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <EditRow
              handleClick={() => {
                onClick(0, unitData[tableMeta.rowIndex]);
              }}
            />
          );
        },
        setCellProps: () => {
          return {
            style: { width: 48, padding: 0 }
          };
        }
      }
    }
    // Jump to contacts for specific PUI
    // {
    //   name: "",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     empty: true,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return (
    //         <Contacts
    //           handleClick={() => {
    //             onClick(tableMeta.rowData[0]);
    //           }}
    //         />
    //       );
    //     },
    //     setCellProps: () => {
    //       return {
    //         style: { width: 48, padding: 0 }
    //       };
    //     }
    //   }
    // }
  ];

  const options_units = {
    selectableRows: "none",
    pagination: true,
    rowsPerPageOptions: [10, 25, 100],
    responsive: "scrollFullHeight"
  };

  return (
    <div>
      <MuiThemeProvider theme={getMuiTheme("#FFF") /* lightseagreen ~50% */}>
        <MUIDataTable
          title="Persons Under Investigation"
          columns={columns_units}
          data={unitData}
          options={options_units}
        />
      </MuiThemeProvider>
      <br />
      <Text>1. BHIS No. as 999998 = BHIS not given, please investigate</Text>
    </div>
  );
};

export default ControlFormUnits;
