import React from "react";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Table, Radio, Divider } from "antd";

const BuildingSummaryTable = ({ data, title }) => {
  if (data.length === 0) {
    return <div>No data available</div>;
  }

  const summary = data.map(building => {
    // Household column
    var completed = building.CompleteHHs == null ? 0 : building.CompleteHHs;
    var partial = building.PartialHHs == null ? 0 : building.PartialHHs;
    var other = building.OtherHHs == null ? 0 : building.OtherHHs;
    var totalHHs = completed * 2 + partial * 1.5 + other * 2;
    building.Households =
      totalHHs === 0 ? (
        <b>None</b>
      ) : (
        <p>
          Complete: {completed} / $2.00
          <br />
          Partial: {partial} / $1.50
          <br />
          Other: {other} / $1.00
          <br />
          <b>Total: ${totalHHs.toFixed(2)}</b>
        </p>
      );

    // Individual column
    completed = building.CompleteInds == null ? 0 : building.CompleteInds;
    partial = building.PartialInds == null ? 0 : building.PartialInds;
    other = building.OtherInds == null ? 0 : building.OtherInds;
    var totalInds = completed * 2 + partial * 1.5 + other * 2;
    building.Individuals =
      totalInds === 0 ? (
        <b>None</b>
      ) : (
        <p>
          Complete: {completed} / $2.00
          <br />
          Partial: {partial} / $1.50
          <br />
          Other: {other} / $1.00
          <br />
          <b>Total: ${totalInds.toFixed(2)}</b>
        </p>
      );

    // Units column
    completed = building.CompleteUnits == null ? 0 : building.CompleteUnits;
    partial = building.PartialUnits == null ? 0 : building.PartialUnits;
    other = building.OtherUnits == null ? 0 : building.OtherUnits;
    var totalUnits = completed * 2 + partial * 1.5 + other * 2;
    building.Units =
      totalUnits === 0 ? (
        <b>None</b>
      ) : (
        <p>
          Complete: {completed} / $2.00
          <br />
          Partial: {partial} / $1.50
          <br />
          Other: {other} / $1.00
          <br />
          <b>Total: ${totalUnits.toFixed(2)}</b>
        </p>
      );

    var total = totalHHs + totalInds + totalUnits;
    building.Total = <b>${total.toFixed(2)}</b>;

    return building;
  });

  const newCols = Object.keys(data[0])
    .filter(
      item =>
        item === "District" ||
        item === "ED" ||
        item === "Urban Rural" ||
        item === "Enumerator" ||
        item === "Area" ||
        item === "CTV" ||
        item === "IsBuilding" ||
        item === "BuildingClass" ||
        item === "Status" ||
        item === "Households" ||
        item === "Individuals" ||
        item === "Units" ||
        item === "Total"
    )
    .map(item => ({ label: item, name: item }));

  const antCols = Object.keys(data[0]).map(item => {
    return {
      title: item,
      dataIndex: item,
      sorter: (a, b) => a.item.length - b.item.length,
      sortDirections: ["ascend", "descend"]
    };
  });

  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          root: {
            backgroundColor: "#FF000",
            overflowX: "hidden"
          },
          paper: {
            boxShadow: "none"
          }
        },
        MUIDataTableBodyCell: {
          root: {
            backgroundColor: "#FFF",
            padding: 12
          }
        }
      }
    });

  const options = {
    pagination: true,
    rowsPerPageOptions: [10, 25, 100],
    responsive: "scrollMaxHeight",
    onRowsSelect: (cur, all) => console.log(all),
    rowsSelected: [0, 1, 2, 4, 5],
    count: 80,
    disableToolbarSelect: true
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: record => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name
    }),
    selectionType: "checkbox"
  };

  return (
    <div style={{ maxWidth: "100%" }}>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={title}
          columns={newCols}
          data={summary}
          options={options}
        />
      </MuiThemeProvider>
    </div>
  );
};

export default BuildingSummaryTable;
