import React, { useState, useEffect } from 'react';
import { Form, Select, Button } from 'antd';
import ReinterviewTable from './ReinterviewTable'
import { getEd, getBuilding, getInterviewID, getInterviewID2, getUnit, getOriginal, getReinterview } from '../services/census_queryer'

const QuestionnaireTable = ({ data, form }) => {

  const { getFieldDecorator, resetFields } = form;
  var district;
  const { Option } = Select;

  //For cascading dropdown list
  const [districtData, setDistrictData] = useState(null);
  const [edData, setEdData] = useState([]);
  const [buildingData, setbuildingData] = useState([]);
  const [unitData, setUnitData] = useState([]);

  //For getting interview ID
  const [holdEd, setHoldEd] = useState(null);
  const [holdBuilding, setHoldBuilding] = useState(null);
  const [holdUnit, setHoldUnit] = useState(null);
  const [holdHousehold, setHoldHousehold] = useState(null);
  const [interviewAns1, setInterviewAnswer1] = useState(null);
  const [interviewAns2, setInterviewAnswer2] = useState(null);

  //Get answer
  const [original, setOriginal] = useState([])
  const [reinterview, setReinterview] = useState([])

  //For toggle and clear buttons
  const [toggleStatus, settoggleStatus] = useState(false);
  const [clearStatus, setClearStatus] = useState(false);

  const districts = ["Corozal", "Orange Walk", "Belize", "Cayo", "Stann Creek", "Toledo"];

  //Get Interview ID and send to reinterview
  const handleSubmit = () => {
    if (original.length<1 && reinterview.length<1) {
      console.log(holdEd);
      console.log(holdBuilding);
      console.log(holdUnit);
      console.log(holdHousehold);
      console.log(interviewAns1, interviewAns2)

      getOriginal(interviewAns1[0].interview__id).then(data=>{setOriginal(data)});
      getReinterview(interviewAns2[0].interview__id).then(data=>{setReinterview(data)});
    }

    setClearStatus(false);

  };

  const handleChange = (e) => {
    setEdData([]);
    setbuildingData([]);
    setUnitData([]);
    if (e) {
      switch (e) {
        case "Corozal":
          district = 1;
          break;
        case "Orange Walk":
          district = 2;
          break;
        case "Belize":
          district = 3;
          break;
        case "Cayo":
          district = 4;
          break;
        case "Stann Creek":
          district = 5;
          break;
        case "Toledo":
          district = 6;
          break;
        default:
          break;
      }
    }
    getEd(district).then(response => { setEdData(response) })
    setDistrictData(district);

    resetFields();
  }

  const handleChangeEd = (f) => {
    setbuildingData([]);
    getBuilding(districtData, f).then(response => { setbuildingData(response) });
    setHoldEd(f);
    resetFields('building');
  }

  const handleChangeBuilding = (g) => {
    setUnitData([]);
    getUnit(districtData, holdEd, g).then(response => { setUnitData(response) });
    setHoldBuilding(g);
    resetFields('unit');
    resetFields('household');
  }

  const handleChangeUnit = (h) => {
    setHoldUnit(h);
    resetFields('household');
  }

  const handleChangeHousehold = (j) => {
    setHoldHousehold(j);
    //Get interview answer
    getInterviewID(districtData, holdEd, holdBuilding, holdUnit, j).then(data => { setInterviewAnswer1(data) });
    getInterviewID2(districtData, holdEd, holdBuilding, holdUnit, j).then(data => { setInterviewAnswer2(data) });
  }

  const edOptions = () => {
    if (edData.length < 1) {

    }
    else {
      return (
        edData.map(i => (
          <Option key={i.ed} value={i.ed}>
            {i.ed}
          </Option>
        ))
      )
    }
  }

  const buildingOptions = () => {
    if (buildingData.length < 1) {

    }
    else {
      return (buildingData.map(i => (
        <Option key={i.buildingID} value={i.buildingID}>
          {i.buildingID}
        </Option>
      )));
    }
  }

  const unitOptions = () => {
    if (unitData.length < 1) {

    }
    else {
      return (unitData.map(i => (
        <Option key={i.unitRoster__id} value={i.unitRoster__id}>
          {i.unitRoster__id}
        </Option>
      )));
    }
  }

  const householdOptions = () => {
    if (unitData.length < 1) {

    }
    else {
      return (unitData.map(i => (
        <Option key={i.householdRoster__id} value={i.householdRoster__id}>
          {i.householdRoster__id}
        </Option>
      )));
    }
  }

  const getFieldDecoratorOptions = (id) => {
    const inputProps = {};
    if (id === 'district') {
      inputProps.rules = [{ required: false, message: 'Please select district' }];
      return inputProps;
    }
    else if (id === 'ed') {
      inputProps.rules = [{ required: false, message: 'Please select ed' }];
      return inputProps;
    }
    else if (id === 'building') {
      inputProps.rules = [{ required: false, message: 'Please select building' }];
      return inputProps;
    }
    else if (id === 'unit') {
      inputProps.rules = [{ required: false, message: 'Please select unit' }];
      return inputProps;
    }
    else if (id === 'household') {
      inputProps.rules = [{ required: false, message: 'Please select household' }];
      return inputProps;
    }
  }

  const formLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 }
  }

  const deleteTable = () => {
    setClearStatus(true);
    setDistrictData(null);
    setEdData([]);
    setbuildingData([]);
    setUnitData([]);
    setHoldEd(null);
    setHoldBuilding(null);
    setHoldUnit(null);
    setInterviewAnswer1(null);
    setInterviewAnswer2(null);
    setOriginal([]);
    setReinterview([]);
    resetFields();
  }

  const toggleDifference = () => {
    if (toggleStatus) {
      settoggleStatus(false);
    }
    else {
      settoggleStatus(true);
    }
  }

  const sendToReinterview = () => {
    if (original.length<1 && reinterview.length<1) {
      // console.log("Cry")
    }
    else {

      return (
        (original.length>=1 && reinterview.length>=1) && <ReinterviewTable q1={original} q2={reinterview} toggle={toggleStatus} clear={clearStatus} />
      );
    }
  }

  return (
    <>
      {(
        <Form>
          <Form.Item label='Districts' htmlFor="district" {...formLayout}>
            {getFieldDecorator('district', getFieldDecoratorOptions('district'))(
              <Select placeholder="Select District" onChange={e => handleChange(e)}
                key="district" id="district" name="district">
                {
                  districts.map(i => (
                    <Option value={i} key={i}>
                      {i}
                    </Option>
                  ))
                }
              </Select>
            )}
          </Form.Item>
          <Form.Item label="ED" htmlFor="ed" {...formLayout}>
            {getFieldDecorator('ed', getFieldDecoratorOptions('ed'))(
              <Select placeholder="Select ED" onChange={(f) => handleChangeEd(f)}
                disabled={edData.length < 1 ? true : false}
                id="ed" name="ed"
              >
                {
                  edOptions()
                }
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Building" htmlFor="building" {...formLayout}>
            {getFieldDecorator('building', getFieldDecoratorOptions('building'))(
              <Select placeholder="Selct Building" onChange={(g) => handleChangeBuilding(g)}
                disabled={buildingData.length < 1 ? true : false}
                id="building" name="building">
                {
                  buildingOptions()
                }
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Unit" htmlFor="unit" {...formLayout}>
            {getFieldDecorator('unit', getFieldDecoratorOptions('unit'))(
              <Select placeholder="Select Unit" onChange={(h) => handleChangeUnit(h)}
                disabled={unitData.length < 1 ? true : false}
                id="building" name="building">
                {
                  unitOptions()
                }
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Household" htmlFor="household" {...formLayout}>
            {getFieldDecorator('household', getFieldDecoratorOptions('household'))(
              <Select placeholder="Select Household" onChange={(j) => handleChangeHousehold(j)}
                disabled={unitData.length < 1 ? true : false}
                id="household" name="household">
                {
                  householdOptions()
                }
              </Select>
            )}
          </Form.Item>
        </Form>)}
      <div style={{ width: "100%", textAlign: "center" }}>
        <Button onClick={handleSubmit} disabled={districtData && holdEd && holdBuilding && holdUnit && holdHousehold ? false : true}>Go</Button>
        <Button onClick={toggleDifference} disabled={original.length>=1 && reinterview.length>=1 ? false : true}>Toggle Differences</Button>
        <Button onClick={deleteTable} disabled={original.length>=1 && reinterview.length>=1 ? false : true}>Clear</Button>

      </div>
      {
        sendToReinterview()
      }
    </>
  );
}
export default Form.create({ name: 'reinterview' })(QuestionnaireTable);


