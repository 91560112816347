import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import HqRoute from "./routes/HqRoute";
import MohRoute from "./routes/MohRoute";
import SuperRoute from "./routes/SuperRoute";
import EnumRoute from "./routes/EnumRoute";
import EditorRoute from "./routes/EditorRoute";
import HqView from "./pages/HqView";
import MohView from "./pages/MohView";
import SuperView from "./pages/SuperView";
import EnumView from "./pages/EnumView";
import EditorView from "./pages/EditorView";
import Login from "./pages/Login";
import { AuthContext } from "./context/auth";
import { USER_TOKEN } from "./constants";

const Redirect = () => {
  useEffect(() => {
    window.location =
      "http://ptrack.sib.org.bz:8080/apex/f?p=143:LOGIN_DESKTOP:10996757858016:::::";
  }, []);

  return <section>Redirecting to new dashboard...</section>;
}; 

const App = (props) => {
  const token = JSON.parse(localStorage.getItem(USER_TOKEN));
  const [authToken, setAuthToken] = useState(token);

  const setToken = (data = null) => {
    localStorage.setItem(USER_TOKEN, JSON.stringify(data));
    setAuthToken(data);
  };

  return (
    <AuthContext.Provider value={{ authToken, setAuthToken: setToken }}>
      <Router>
        <Route path="/login" component={Login} />
        <EnumRoute exact path="/enumerator" component={EnumView} />
        <SuperRoute exact path="/supervisor" component={SuperView} />
        <HqRoute exact path="/" component={HqView} />
        <MohRoute exact path="/moh" component={MohView} />
        <EditorRoute exact path="/editor" component={EditorView} />
        <Route path="/new-dashboard" component={Redirect} />;
      </Router>
    </AuthContext.Provider>
  );
};

export default App;
