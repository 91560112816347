import React from "react";
import { Table, TableBody, TableRow, TableCell } from "@material-ui/core";
import { Form } from "antd";

const ReinterviewTable = ({ q1, q2, toggle, clear }) => {
  let holdq1Values = [];
  let holdq2Values = [];
  let holdq1keys = [];
  let holdq2keys = [];
 
  console.log(toggle, clear)

  Object.values(q1[0]).forEach(row => {
    holdq1Values.push(row);
  });
  Object.values(q2[0]).forEach(row => {
    holdq2Values.push(row);
  });
  Object.keys(q1[0]).forEach(row => {
    holdq1keys.push(row);
  });
  Object.keys(q2[0]).forEach(row => {
    holdq2keys.push(row);
  });

  const highlight = { backgroundColor: "#FED8B1" };

  const createRows = (q1, q2) => {
    // if (q1.Answers.length >= q2.Answers.length) {
    return holdq1Values.map((i, j) =>
      clear === false ? (
        toggle === false ? (
          
            <>
              <TableRow>
                <TableCell colSpan="1" style={{ fontWeight: "bold" }}>
                  {holdq1keys[j]}
                </TableCell>
                <TableCell colSpan="1" style={{ fontWeight: "bold" }}>
                  {holdq2keys[j]}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan="1"
                  style={i !== holdq2Values[j] ? highlight : {}}
                >
                  {i}
                </TableCell>
                <TableCell
                  colSpan="1"
                  style={i !== holdq2Values[j] ? highlight : {}}
                >
                  {holdq2Values[j]}
                </TableCell>
              </TableRow>
            </>
          
        ) :  i !== holdq2Values[j] ? (
          <>
            <TableRow>
              <TableCell colSpan="1" style={{ fontWeight: "bold" }}>
                {holdq1keys[j]}
              </TableCell>
              <TableCell colSpan="1" style={{ fontWeight: "bold" }}>
                {holdq2keys[j]}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                colSpan="1"
                style={i !== holdq2Values[j] ? highlight : {}}
              >
                {i}
              </TableCell>
              <TableCell
                colSpan="1"
                style={i !== holdq2Values[j] ? highlight : {}}
              >
                {holdq2Values[j]}
              </TableCell>
            </TableRow>
          </>
        ) : (
          <></>
        )
      ) : (
        <></>
      )
    );
  };

  const formLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 }
  };

  const countDifferences = (q1, q2) => {
    let count = 0;

    holdq1Values.map((i, j) => {
      if (holdq2Values[j] !== undefined) {
        if (i !== holdq2Values[j]) count++;
      }
    });

    return (
      <Form>
        <Form.Item label="Differences" {...formLayout}>
          {count}
        </Form.Item>
      </Form>
    );
  };

  return (
    <>
      {countDifferences(q1, q2)}
      <Table key={"tbody"}>
        <TableBody key={"tbody"}>{createRows(q1, q2)}</TableBody>
      </Table>
    </>
  );
};

export default ReinterviewTable;
