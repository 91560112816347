import React from "react";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { withStyles } from "@material-ui/core/styles";

const defaultToolbarStyles = {
  iconButton: {}
};

const CustomToolbar = props => {
  const { classes, handleClick } = props;

  return (
    <React.Fragment>
      <IconButton className={classes.iconButton} onClick={handleClick}>
        <EditIcon className={classes.deleteIcon} style={{ fontSize: 18 }} />
      </IconButton>
    </React.Fragment>
  );
};

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(
  CustomToolbar
);
