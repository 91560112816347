import axios from "axios";

// "https://bzcensus.herokuapp.com/api"


const baseUrl =
  process.env.REACT_APP_NODE_ENV === "development"
    ? "http://localhost:3000/api"
    : process.env.REACT_APP_API;

export const getQuestionnaire = async () => {
  const response = await axios.get(`${baseUrl}/questionnaire`);
  return response.data;
};

export const createSSAssignment = async data => {
  const response = await axios.post(`${baseUrl}/create_assignment`, { data });
  return response.data;
};

export const getUsers = async () => {
  const response = await axios.get(`${baseUrl}/get_users`);
  return response.data;
};

/***** Household Reponse *****/
//Rates
export const getResponseRatesNational = async () => {
  const response = await axios.get(`${baseUrl}/response_rates_national`);
  return response.data;
};

export const getResponseRatesDistrict = async () => {
  const response = await axios.get(`${baseUrl}/response_rates_district`);
  return response.data;
};

export const getResponseRatesEd = async () => {
  const response = await axios.get(`${baseUrl}/response_rates_ed`);
  return response.data;
};

export const getResponseRatesEnum = async () => {
  const response = await axios.get(`${baseUrl}/response_rates_enum`);
  return response.data;
};

//Counts
export const getResponseCountsNational = async () => {
  const response = await axios.get(`${baseUrl}/response_counts_national`);
  return response.data;
};

export const getResponseCountsDistrict = async () => {
  const response = await axios.get(`${baseUrl}/response_counts_district`);
  return response.data;
};

export const getResponseCountsEd = async () => {
  const response = await axios.get(`${baseUrl}/response_counts_ed`);
  return response.data;
};

export const getResponseCountsEnum = async () => {
  const response = await axios.get(`${baseUrl}/response_counts_enum`);
  return response.data;
};

/***** Enumeration *****/
export const getCompositionNational = async () => {
  const response = await axios.get(`${baseUrl}/composition_national`);
  return response.data;
};

export const getCompositionDistrict = async () => {
  const response = await axios.get(`${baseUrl}/composition_district`);
  return response.data;
};

export const getCompositionEd = async () => {
  const response = await axios.get(`${baseUrl}/composition_ed`);
  return response.data;
};
export const getCompositionUrbanRural = async () => {
  const response = await axios.get(`${baseUrl}/composition_urban_rural`);
  return response.data;
};

/***** Composition *****/
export const getEnumRatesNational = async () => {
  const response = await axios.get(`${baseUrl}/enum_rates_national`);
  return response.data;
};

export const getEnumRatesDistrict = async () => {
  const response = await axios.get(`${baseUrl}/enum_rates_district`);
  return response.data;
};

export const getEnumRatesEd = async () => {
  const response = await axios.get(`${baseUrl}/enum_rates_ed`);
  return response.data;
};
export const getEnumRatesEnum = async () => {
  const response = await axios.get(`${baseUrl}/enum_rates_enum`);
  return response.data;
};

export const getAgeGroups = async () => {
  const response = await axios.get(`${baseUrl}/age_groups`);
  return response.data;
};

export const getAgeGroupsNational = async () => {
  const response = await axios.get(`${baseUrl}/age_groups_national`);
  return response.data;
};

export const getAgeGroupsDistrict = async () => {
  const response = await axios.get(`${baseUrl}/age_groups_national`);
  return response.data;
};

export const getAgeGroupsEd = async () => {
  const response = await axios.get(`${baseUrl}/age_groups_national`);
  return response.data;
};

//Card stats
export const getCardStatistics = async () => {
  const response = await axios.get(`${baseUrl}/card_statistics`);
  return response.data;
};

//Building summaries
export const getBuildingSummaries = async () => {
  const response = await axios.get(`${baseUrl}/building_summaries`);
  return response.data;
};

//CODE search
export const getCodeData = async type => {
  const response = await axios.get(`${baseUrl}/code_search/${type}`);
  return response.data;
};

export const getIscoData = async type => {
  const response = await axios.get(`${baseUrl}/isco`);
  return response.data;
};

export const getBceaData = async type => {
  const response = await axios.get(`${baseUrl}/bcea`);
  return response.data;
};

export const getFuseConfig = async () => {
  const response = await axios.get(`${baseUrl}/fuse_config`);
  return response.data;
};

export const getIsicData = async type => {
  const response = await axios.get(`${baseUrl}/isic`);
  return response.data;
};
export const getIscedData = async type => {
  const response = await axios.get(`${baseUrl}/isced`);
  return response.data;
};

export const getControlFormEntry = async user => {
  const response = await axios.get(`${baseUrl}/control_form_entry/${user}`);
  return response.data;
};

export const create = async newObject => {
  const response = axios.post(baseUrl, newObject);
  return response;
};

export const update = (id, newObject) => {
  const response = axios.put(`${baseUrl}/${id}`, newObject);
  return response;
};
export const getEd = async district => {
  const response = await axios.post(`${baseUrl}/getEd`, { district });
  return response.data;
};
export const getBuilding = async (district, ed) => {
  const response = await axios.post(`${baseUrl}/getBuildingID`, {
    district,
    ed
  });
  return response.data;
};
export const getUnit = async (district, ed, building) => {
  const response = await axios.post(`${baseUrl}/getUnitRoster`, {
    district,
    ed,
    building
  });
  return response.data;
};
export const getHousehold = async (district, ed, building, unit) => {
  const response = await axios.post(`${baseUrl}/getHouseholdRoster`, {
    district,
    ed,
    building,
    unit
  });
  return response.data;
};
export const getInterviewID = async (
  district,
  ed,
  building,
  unit,
  household
) => {
  const response = await axios.post(`${baseUrl}/getInterviewID`, {
    district,
    ed,
    building,
    unit,
    household
  });
  return response.data;
};
export const getInterviewID2 = async (
  district,
  ed,
  building,
  unit,
  household
) => {
  const response = await axios.post(`${baseUrl}/getInterviewID2`, {
    district,
    ed,
    building,
    unit,
    household
  });
  return response.data;
};
export const getOriginal = async interviewId => {
  const response = await axios.post(`${baseUrl}/getOriginal`, { interviewId });
  return response.data;
};
export const getReinterview = async interviewId => {
  const response = await axios.post(`${baseUrl}/getReinterview`, {
    interviewId
  });
  return response.data;
};

/*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
+                           FLASK API Routes                            +
+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

export const getSuggestedCode = async (type, token) => {

  try{
    const url = `https://app.sib.org.bz:5000/api/${type}/codes?desc=${token}`
    // const url = `https://app.sib.org.bz:5000/api/isco/codes?desc=${token}`
    const response = await axios.get(url);
    return response.data
  } catch(e){
    console.log("ERROR", e.request)
    return '';    
  }
 
};

/*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
+                           MOH Routes                            +
+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const getInterviewMOH = async () => {
  const response = await axios.get(`${baseUrl}/getInterviewMOH`);
  return response.data;
};

export const getIndividuals = async () => {
  const response = await axios.get(`${baseUrl}/getIndividuals`);
  return response.data;
};

export const updateAssigned = async (interviewID, memberNames) => {
  const response = await axios.post(`${baseUrl}/updateAssigned`, {
    interviewID,
    memberNames
  });
  return response.data;
};

export const getDistrict = async (username) => {
  const response = await axios.post(`${baseUrl}/getDistrict`,{username});
  return response.data;
};

export const updateContact = async (
  interview__key,
  ContactListing__id,
  memberNames,
  isHouseholdMember,
  relationshipToHead,
  generalRelationship,
  contactAge,
  contactSex,
  contactPhone,
  contactDistrict,
  contactCTV,
  contactAddress,
  contactWork,
  contactWorkAddress,
  isEmergencyContact,
  riskLevel
) => {
  const response = await axios.post(`${baseUrl}/updateContact`, {
    interview__key,
    ContactListing__id,
    memberNames,
    isHouseholdMember,
    relationshipToHead,
    generalRelationship,
    contactAge,
    contactSex,
    contactPhone,
    contactDistrict,
    contactCTV,
    contactAddress,
    contactWork,
    contactWorkAddress,
    isEmergencyContact,
    riskLevel
  });
  return response.data;
};

export const updateBhis = async (interview__key, PUI__id, bhis) => {
  const response = await axios.post(`${baseUrl}/updateBhis`, {
    interview__key,
    PUI__id,
    bhis
  });
  return response.data;
};
