import React from "react";
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider } from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { Button } from "antd";

const SubTable = ({
  colSpan,
  theme,
  columns,
  data,
  options,
  addOnClick,
  typeToAdd
}) => {
  return (
    <>
      <TableRow>
        <TableCell colSpan={colSpan} style={{ border: 0 }}>
          <MuiThemeProvider theme={theme}>
            <MUIDataTable columns={columns} data={data} options={options} />
          </MuiThemeProvider>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={colSpan}
          style={{ paddingTop: 12, paddingBottom: 12, textAlign: "center" }}
        >
          <Button
            type="primary"
            shape="round"
            icon="plus"
            size="small"
            onClick={addOnClick}
          >
            Add New {typeToAdd}
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

export default SubTable;
