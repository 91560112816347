import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Dropdown, Button, Layout, Menu, Icon, Tabs } from "antd";
import CensusLogo from "../assets/images/Census2020_logo.svg";
import CensusLogoTeal from "../assets/images/Census2020_Teal.svg";
import { getTeamBuildings, getTeamUnits } from "../services/User";
import ControlFormBuildings from "../components/ControlFormBuildings";
import ControlFormUnits from "../components/ControlFormUnits";
import ControlFormHouseholds from "../components/ControlFormHouseholds";
import ControlModal from "../components/ControlModal";
import { useAuth } from "../context/auth";

const { Header, Content, Footer } = Layout;
const { TabPane } = Tabs;

const EnumView = () => {
  const [currentDisplay, setCurrentDisplay] = useState("forms");
  const [rowData, setRowData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [buildingData, setBuildingData] = useState([]);
  const [unitData, setUnitData] = useState([]);
  const [hhData, setHhData] = useState([]);
  const { authToken, setAuthToken } = useAuth();

  const logout = () => {
    setAuthToken();
  };

  const handleAddNew = (type, data) => {
    setVisible(true);
    setRowData([type, data]);
  };

  const handleModalClose = () => {
    setVisible(false);
    //setDwelling(false);
    // Reload user data after
    getTeamBuildings(authToken.username).then(response => {
      setBuildingData(response);
    });
    getTeamUnits(authToken.username).then(response => {
      setUnitData(response);
    });
  };

  useEffect(() => {
    let mounted = true;
    getTeamBuildings(authToken.username).then(response => {
      if (mounted) setBuildingData(response);
    });
    getTeamUnits(authToken.username).then(response => {
      if (mounted) setUnitData(response);
    });

    return () => {
      mounted = false;
    };
  }, [authToken]);

  const menu = (
    <Menu>
      <Menu.Item key="logout" onClick={logout}>
        <Icon type="logout" /> Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout
      className="layout"
      style={{ flex: 1, minHeight: "100vh" }}
      theme="light"
    >
      <Header theme="light" className="header">
        <img className="ant-menu-item logo" src={CensusLogoTeal} alt="logo" />
        <Menu
          theme="light"
          mode="horizontal"
          defaultSelectedKeys={["1"]}
          style={{ lineHeight: "55px", backgroundColor: "gold-1" }}
          onClick={({ item, key, keyPath, domEvent }) =>
            key !== "item_3" && setCurrentDisplay(key)
          }
        >
          <Menu.Item key="forms">
            <Icon type="form" />
            Forms
          </Menu.Item>
          <Menu.Item key="callbacks">
            <Icon type="clock-circle" />
            Callbacks
          </Menu.Item>
          <Menu.Item key="reports">
            <Icon type="table" />
            Reports
          </Menu.Item>
          <Menu.Item style={{ float: "right" }}>
            <Dropdown overlay={menu}>
              <Button>
                {authToken.username} <Icon type="down" />
              </Button>
            </Dropdown>
          </Menu.Item>
        </Menu>
      </Header>
      <Content>
        <ControlModal
          type={rowData[0]}
          data={rowData[1]}
          role={authToken.role}
          visible={visible}
          setVisible={setVisible}
          closeModal={handleModalClose}
        />

        {// Main Control Form View
        currentDisplay === "forms" && (
          <Tabs defaultActiveKey="1" destroyInactiveTabPane={true}>
            <TabPane tab="Buildings" key="1">
              <ControlFormBuildings
                buildingData={buildingData}
                unitData={unitData}
                hhData={hhData}
                onClick={handleAddNew}
              />
            </TabPane>
            <TabPane tab="Units" key="2">
              <ControlFormUnits
                unitData={unitData}
                hhData={hhData}
                onClick={handleAddNew}
              />
            </TabPane>
          </Tabs>
        )}
      </Content>
      <Footer style={{ textAlign: "center" }}>
        Powerd by Gian and the IT Team
      </Footer>
    </Layout>
  );
};

export default EnumView;
