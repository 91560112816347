import React, { useState, useEffect } from "react";
import { Layout, Drawer, Icon, Row, Col, Statistic, Card } from "antd";
import Enumeration from "../components/Enumeration";
import Payment from "../components/Payment"
import ResponseRates from "../components/ResponseRates";
import AgeGroups from "../components/AgeGroups";
import Questionnaire from "../components/Questionnaire";
import { HqMenu } from "../components/PortalMenu";
import { useAuth } from "../context/auth";
import ControlFormBuildings from "../components/ControlFormBuildings";
import ControlFormUnits from "../components/ControlFormUnits";
import { getBuildings, getUnits, getUserHouseholds } from "../services/User";
import ControlModal from "../components/ControlModal";
import Iframe from "react-iframe";
import { getCardStatistics } from "../services/census_queryer";

const { Content, Sider } = Layout;

const HqView = () => {
  const [mainView, setMainView] = useState("dashboard");
  const [subView, setSubView] = useState("welcome");
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [mobileView, setMobileView] = useState();
  const { authToken } = useAuth();
  const [buildingData, setBuildingData] = useState([]);
  const [unitData, setUnitData] = useState([]);
  const [hhData, setHhData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [cardStatistics, setCardStatistics] = useState([]);

  const handleAddNew = (type, data) => {
    setVisible(true);
    setRowData([type, data]);
    //setDwelling(false);
  };

  const handleModalClose = () => {
    setVisible(false);
    //setDwelling(false);
    // Reload user data after
    getBuildings(authToken.username).then(response => {
      setBuildingData(response);
    });
    getUnits(authToken.username).then(response => {
      setUnitData(response);
    });
    getUserHouseholds(authToken.username).then(response => {
      setHhData(response);
    });
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  const toggleMenu = () => {
    if (window.innerWidth < 992) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      window.addEventListener("resize", toggleMenu);
      toggleMenu();
    }

    getBuildings(authToken.username).then(response => {
      if (mounted) setBuildingData(response);
    });
    getUnits(authToken.username).then(response => {
      if (mounted) setUnitData(response);
    });
    getUserHouseholds(authToken.username).then(response => {
      if (mounted) setHhData(response);
    });

    getCardStatistics().then(response => {
      if (mounted) setCardStatistics(response);
    });

    return () => {
      mounted = false;
      window.removeEventListener("resize", toggleMenu);
    };
  }, [authToken]);

  const embedablePlots = [
    { url: "//plot.ly/~gianbz/9.embed?", width: 12, height: 400 },
    { url: "//plot.ly/~gianbz/13.embed?", width: 12, height: 400 },
    { url: "//plot.ly/~gianbz/50.embed?", width: 12, height: 400 },
    { url: "//plot.ly/~gianbz/48.embed?", width: 12, height: 400 }
  ];

  return (
    <div>
      <Layout theme="light">
        {mobileView ? (
          <Drawer
            placement="left"
            closable={false}
            onClose={onClose}
            visible={drawerVisible}
          >
            <HqMenu setMainView={setMainView} setSubView={setSubView} />
          </Drawer>
        ) : (
          <Sider breakpoint="md" collapsedWidth="0" theme="light">
            <HqMenu setMainView={setMainView} setSubView={setSubView} />
          </Sider>
        )}

        <Layout style={{ height: "100vh", overflowY: "auto" }}>
          {mobileView && (
            <span className="hamburger-menu">
              <Icon type="menu" onClick={showDrawer} />
            </span>
          )}

          <Content className="table-margin">
            <ControlModal
              type={rowData[0]}
              data={rowData[1]}
              role={authToken.role}
              visible={visible}
              closeModal={handleModalClose}
            />
            {mainView === "dashboard" && (
              <>
                <Row>
                  {cardStatistics.map(x => ( 
                    <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                      <Card style={{ textAlign: "center", margin: "5px" }}>
                        <Statistic
                          title={x.name}
                          value={x.value}
                          valueStyle={{ color: `${x.color}` }}
                          prefix={<Icon type={x.icon} />}
                        />
                      </Card>
                    </Col>
                  ))}
                </Row>
                <Row gutter={[8, 8]}>
                  {embedablePlots.map(plot => (
                    <Col
                      xs={{ span: 24, offset: 0 }}
                      sm={{ span: 20, offset: 2 }}
                      md={{ span: 18, offset: 3 }}
                      lg={{ span: 20, offset: 2 }}
                      xl={{ span: plot.width, offset: 0 }}
                    >
                      <Iframe
                        frameBorder={0}
                        width="100%"
                        height={plot.height}
                        url={plot.url + "showlink=false&modebar=false"}
                      />
                    </Col>
                  ))}
                </Row>
              </>
            )}
            {mainView === "controlForms" && subView === "buildings" && (
              <ControlFormBuildings
                buildingData={buildingData}
                unitData={unitData}
                hhData={hhData}
                onClick={handleAddNew}
                role={authToken.role}
              />
            )}
            {mainView === "controlForms" && subView === "units" && (
              <ControlFormUnits
                unitData={unitData}
                hhData={hhData}
                onClick={handleAddNew}
                role={authToken.role}
              />
            )}
            {mainView === "enumeration" && <Enumeration subView={subView} />}
            {mainView === "responseRates" && (
              <ResponseRates subView={subView} />
            )}
            {mainView === "ageGroups" && <AgeGroups subView={subView} />}
            {mainView === "compare" && <Questionnaire subView={subView} />}
            {mainView === "pay" && <Payment subView={subView} />}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default HqView;
