import React, { useState, useEffect } from "react";
import { getQuestionnaire } from "../services/census_queryer";
// import {sendBits} from './QuestionnaireTable'
import QuestionnaireTable from "./QuestionnaireTable";
import ReinterviewTable from "./ReinterviewTable";
import Loading from "./Loading";

const Questionnaire = ({ subView }) => {
  // console.log("not here")
  const [compare, setQuestionnaire] = useState(null);
  const [bits, setBits] = useState(null);

  useEffect(() => {
    let mounted = true;

    if (mounted && subView === "compare") {
      // console.log("here")
      getQuestionnaire().then(data => setQuestionnaire(data));
      // sendBits().then(data=>{ if (mounted) setBits(data) })
    } else if (mounted) {
      setQuestionnaire([]);
    }

    return () => {
      // toggle on clean up
      mounted = false;
    };
  }, [subView]);

  // console.log(compare)

  return (
    <div>
      {compare ? <QuestionnaireTable data={compare} /> : <Loading />}
      {/* {bits ? <ReinterviewTable data={compare,bits} /> : <Spin size="large" className='centeredSpin' />} */}
    </div>
  );
};

export default Questionnaire;
