import React, { useState } from "react";
import {
  Modal,
  Divider,
  Form,
  Input,
  Select,
  Icon,
  InputNumber,
  Typography
} from "antd";
import { ADD, MOH_USER, DWELLING } from "../constants";
import { updateContact } from "../services/census_queryer";

const { Option } = Select;

const ControlModal = ({ type, data, role, visible, closeModal, form }) => {
  const {
    validateFieldsAndScroll,
    getFieldDecorator,
    setFields,
    resetFields
  } = form;

  const { Text } = Typography;

  const handleSubmit = e => {
    e.preventDefault();
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        updateContact(
          data.interview__key,
          data.ContactListing__id,
          values.name,
          values.isHouseholdMember,
          values.relationshipToHead === undefined
            ? ""
            : values.relationshipToHead,
          values.generalRelationship === undefined
            ? ""
            : values.generalRelationship,
          values.age,
          values.sex,
          values.phone,
          values.district,
          values.ctv,
          values.address,
          values.work,
          values.workAddress,
          values.emergencyContact,
          values.riskLevel
        )
          .then(res => {
            if (res === "failed") {
              // display error msg
              alert("Something went wrong with this edit");
            } else {
              closeModal();
            }
          })
          .catch(e => {
            console.log(e);
          });
      }
    });
  };

  const handleSelectChange = value => {
    if (value === DWELLING) {
      //setDwelling(true);
    } else {
      //setDwelling(false);
    }
  };

  const getFieldDecoratorOptions = id => {
    if (!visible) return;
    const inputProps = {};

    if (id === "riskLevel") {
      inputProps.rules = [
        { required: false, message: "Please select level of risk" }
      ];
      if (data.riskLevel) {
        inputProps.initialValue = parseInt(data.riskLevel);
      }
      return inputProps;
    } else if (id === "name") {
      inputProps.rules = [
        { required: false, message: "Please enter name of head" }
      ];
      if (data.contactListNames) {
        inputProps.initialValue = data.contactListNames;
      }
      return inputProps;
    } else if (id === "isHouseholdMember") {
      inputProps.rules = [
        { required: false, message: "Please select is household member" }
      ];
      if (data.isHouseholdMember) {
        inputProps.initialValue = parseInt(data.isHouseholdMember);
      }
      return inputProps;
    } else if (id === "relationshipToHead") {
      inputProps.rules = [
        { required: false, message: "Please enter relationship to head" }
      ];
      if (data.relationshipToHead) {
        inputProps.initialValue = parseInt(data.relationshipToHead);
      }
      return inputProps;
    } else if (id === "generalRelationship") {
      inputProps.rules = [
        {
          required: false,
          message: "Please enter general relationship"
        }
      ];
      if (data.generalRelationship) {
        inputProps.initialValue = parseInt(data.generalRelationship);
      }
      return inputProps;
    } else if (id === "age") {
      inputProps.rules = [
        { required: false, message: "Please enter contact age" }
      ];
      if (data.contactAge) {
        inputProps.initialValue = parseInt(data.contactAge);
      }
      return inputProps;
    } else if (id === "sex") {
      inputProps.rules = [
        { required: false, message: "Please enter contact sex" }
      ];
      if (data.contactSex) {
        inputProps.initialValue = parseInt(data.contactSex);
      }
      return inputProps;
    } else if (id === "phone") {
      inputProps.rules = [
        { required: false, message: "Please enter contact phone" }
      ];
      if (data.contactPhone) {
        inputProps.initialValue = data.contactPhone;
      }
      return inputProps;
    } else if (id === "district") {
      inputProps.rules = [
        { required: false, message: "Please enter contact district" }
      ];
      if (data.contactDistrict) {
        inputProps.initialValue = parseInt(data.contactDistrict);
      }
      return inputProps;
    } else if (id === "ctv") {
      inputProps.rules = [
        { required: false, message: "Please select contact ctv" }
      ];
      // if (data.contactCTV) {
      //   inputProps.initialValue = parseInt(data.contactCTV);
      // }
      if (data.ctv) {
        inputProps.initialValue = data.ctv;
      }
      return inputProps;
    } else if (id === "address") {
      inputProps.rules = [
        { required: false, message: "Please enter contact address" }
      ];
      if (data.contactAddress) {
        inputProps.initialValue = data.contactAddress;
      }
      return inputProps;
    } else if (id === "work") {
      inputProps.rules = [
        {
          required: false,
          message: "Please enter contact work"
        }
      ];
      if (data.contactWork) {
        inputProps.initialValue = data.contactWork;
      }
      return inputProps;
    } else if (id === "workAddress") {
      inputProps.rules = [
        { required: false, message: "Please enter work address" }
      ];
      if (data.contactWorkAddress) {
        inputProps.initialValue = data.contactWorkAddress;
      }
      return inputProps;
    } else if (id === "emergencyContact") {
      inputProps.rules = [
        { required: false, message: "Please select is emergency contact" }
      ];
      if (data.isEmergencyContact) {
        inputProps.initialValue = parseInt(data.isEmergencyContact);
      }
      return inputProps;
    }
  };

  const formLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
    }
  };

  let contactSex;
  let contactCTV;
  let contactDistrict;

  if (data != undefined) {
    switch (data.contactSex) {
      case 1:
        contactSex = "Male";
        break;
      case 2:
        contactSex = "Female";
        break;
      case 999999:
        contactSex = "DK/NS";
        break;
    }

    switch (data.contactCTV) {
      case 222:
        contactCTV = "Aguacate";
        break;
      case 184:
        contactCTV = "Alta Vista";
        break;
      case 2:
        contactCTV = "Altamira";
        break;
      case 129:
        contactCTV = "Arenal";
        break;
      case 130:
        contactCTV = "Armenia";
        break;
      case 41:
        contactCTV = "August Pine Ridge";
        break;
      case 131:
        contactCTV = "Augustine";
        break;
      case 42:
        contactCTV = "Bacadia";
        break;
      case 223:
        contactCTV = "Barranco";
        break;
      case 132:
        contactCTV = "Barton Creek";
        break;
      case 133:
        contactCTV = "Beaver Dam";
        break;
      case 88:
        contactCTV = "Belize City";
        break;
      case 185:
        contactCTV = "Bella Vista (Stann Creek)";
        break;
      case 224:
        contactCTV = "Bella Vista (Toledo)";
        break;
      case 125:
        contactCTV = "Belmopan";
        break;
      case 126:
        contactCTV = "Benque Viejo Del Carmen";
        break;
      case 90:
        contactCTV = "Bermudian Landing";
        break;
      case 186:
        contactCTV = "Big Creek";
        break;
      case 225:
        contactCTV = "Big Falls";
        break;
      case 162:
        contactCTV = "Billy White";
        break;
      case 91:
        contactCTV = "Biscayne";
        break;
      case 226:
        contactCTV = "Black Creek";
        break;
      case 134:
        contactCTV = "Blackman Eddy";
        break;
      case 227:
        contactCTV = "Bladden";
        break;
      case 43:
        contactCTV = "Blue Creek (Orange Walk)";
        break;
      case 228:
        contactCTV = "Blue Creek (Toledo)";
        break;
      case 92:
        contactCTV = "Bomba";
        break;
      case 229:
        contactCTV = "Boom Creek";
        break;
      case 93:
        contactCTV = "Boston";
        break;
      case 135:
        contactCTV = "Branch Mouth";
        break;
      case 3:
        contactCTV = "Buena Vista (Corozal)";
        break;
      case 163:
        contactCTV = "Buena Vista (Cayo)";
        break;
      case 136:
        contactCTV = "Bullet Tree Falls";
        break;
      case 94:
        contactCTV = "Burrell Boom";
        break;
      case 230:
        contactCTV = "Calacomos";
        break;
      case 4:
        contactCTV = "Calcutta";
        break;
      case 5:
        contactCTV = "Caledonia";
        break;
      case 137:
        contactCTV = "Calla Creek";
        break;
      case 138:
        contactCTV = "Camalote";
        break;
      case 44:
        contactCTV = "Carmelita (Orange Walk)";
        break;
      case 139:
        contactCTV = "Carmelita (Cayo)";
        break;
      case 6:
        contactCTV = "Carolina";
        break;
      case 231:
        contactCTV = "Cattle Landing";
        break;
      case 95:
        contactCTV = "Caye Caulker";
        break;
      case 140:
        contactCTV = "Central Farm";
        break;
      case 7:
        contactCTV = "Chan Chen";
        break;
      case 45:
        contactCTV = "Chan Chich";
        break;
      case 46:
        contactCTV = "Chan Pine Ridge";
        break;
      case 141:
        contactCTV = "Chial";
        break;
      case 8:
        contactCTV = "Chunox";
        break;
      case 9:
        contactCTV = "Concepcion";
        break;
      case 233:
        contactCTV = "Conejo";
        break;
      case 10:
        contactCTV = "Consejo";
        break;
      case 142:
        contactCTV = "Cool Shade";
        break;
      case 11:
        contactCTV = "Copper Bank";
        break;
      case 234:
        contactCTV = "Corazon";
        break;
      case 1:
        contactCTV = "Corozal Town";
        break;
      case 96:
        contactCTV = "Corozalito";
        break;
      case 143:
        contactCTV = "Cotton Tree";
        break;
      case 187:
        contactCTV = "Cow Creek";
        break;
      case 188:
        contactCTV = "Cow Pen";
        break;
      case 235:
        contactCTV = "Crique Jute";
        break;
      case 236:
        contactCTV = "Crique Largo";
        break;
      case 237:
        contactCTV = "Crique Sarco";
        break;
      case 232:
        contactCTV = "Crique Trosa";
        break;
      case 12:
        contactCTV = "Cristo Rey (Corozal)";
        break;
      case 144:
        contactCTV = "Cristo Rey (Cayo)";
        break;
      case 97:
        contactCTV = "Crooked Tree";
        break;
      case 47:
        contactCTV = "Cross Creek";
        break;
      case 238:
        contactCTV = "Crystal Creek";
        break;
      case 48:
        contactCTV = "Cuatro Leguas";
        break;
      case 183:
        contactCTV = "Dangriga";
        break;
      case 98:
        contactCTV = "Davis/Grace Bank";
        break;
      case 239:
        contactCTV = "Deep River/Medina Bank";
        break;
      case 999999:
        contactCTV = "DK/NS";
        break;
      case 241:
        contactCTV = "Dolores";
        break;
      case 99:
        contactCTV = "Double Head Cabbage";
        break;
      case 49:
        contactCTV = "Douglas";
        break;
      case 145:
        contactCTV = "Duck Run 1";
        break;
      case 146:
        contactCTV = "Duck Run 2";
        break;
      case 147:
        contactCTV = "Duck Run 3";
        break;
      case 240:
        contactCTV = "Dump";
        break;
      case 242:
        contactCTV = "Elridge";
        break;
      case 148:
        contactCTV = "Esperanza (Cayo)";
        break;
      case 243:
        contactCTV = "Esperanza (Toledo)";
        break;
      case 13:
        contactCTV = "Estrella";
        break;
      case 14:
        contactCTV = "Fire Burn (Corozal)";
        break;
      case 50:
        contactCTV = "Fire Burn (Orange Walk)";
        break;
      case 100:
        contactCTV = "Flowers Bank";
        break;
      case 244:
        contactCTV = "Forest Home";
        break;
      case 149:
        contactCTV = "Frank's Eddy";
        break;
      case 101:
        contactCTV = "Freetown Sibun";
        break;
      case 102:
        contactCTV = "Gales Point";
        break;
      case 51:
        contactCTV = "Gallon Jug";
        break;
      case 103:
        contactCTV = "Gardenia";
        break;
      case 245:
        contactCTV = "Genus";
        break;
      case 189:
        contactCTV = "Georgetown";
        break;
      case 150:
        contactCTV = "Georgeville";
        break;
      case 52:
        contactCTV = "Gold Button Ranch";
        break;
      case 246:
        contactCTV = "Golden Beauty";
        break;
      case 247:
        contactCTV = "Golden Stream";
        break;
      case 104:
        contactCTV = "Gracie Rock";
        break;
      case 248:
        contactCTV = "Graham Creek";
        break;
      case 53:
        contactCTV = "Guinea Grass";
        break;
      case 105:
        contactCTV = "Hattieville";
        break;
      case 106:
        contactCTV = "Hendy Bank";
        break;
      case 249:
        contactCTV = "Hicattee";
        break;
      case 15:
        contactCTV = "Hill Bank (Corozal)";
        break;
      case 54:
        contactCTV = "Hill Bank (Orange Walk)";
        break;
      case 55:
        contactCTV = "Honey  Camp";
        break;
      case 190:
        contactCTV = "Hope Creek";
        break;
      case 250:
        contactCTV = "Hopeville";
        break;
      case 191:
        contactCTV = "Hopkins";
        break;
      case 192:
        contactCTV = "Hummingbird Community";
        break;
      case 193:
        contactCTV = "Independence";
        break;
      case 56:
        contactCTV = "Indian Church";
        break;
      case 57:
        contactCTV = "Indian Creek (Orange Walk)";
        break;
      case 251:
        contactCTV = "Indian Creek (Toledo)";
        break;
      case 58:
        contactCTV = "Indian Hill Estate";
        break;
      case 107:
        contactCTV = "Isabella Bank";
        break;
      case 252:
        contactCTV = "Jacinto/Westmoreland";
        break;
      case 253:
        contactCTV = "Jalacte";
        break;
      case 254:
        contactCTV = "Jordan";
        break;
      case 194:
        contactCTV = "Kendall";
        break;
      case 108:
        contactCTV = "La Democracia";
        break;
      case 164:
        contactCTV = "La Gracia";
        break;
      case 109:
        contactCTV = "Ladyville";
        break;
      case 255:
        contactCTV = "Laguna";
        break;
      case 59:
        contactCTV = "Las Milpas";
        break;
      case 110:
        contactCTV = "Lemonal";
        break;
      case 16:
        contactCTV = "Libertad";
        break;
      case 17:
        contactCTV = "Little Belize";
        break;
      case 195:
        contactCTV = "Long Bank";
        break;
      case 111:
        contactCTV = "Lords Bank";
        break;
      case 151:
        contactCTV = "Los Tambos";
        break;
      case 18:
        contactCTV = "Louisville";
        break;
      case 112:
        contactCTV = "Lucky Strike";
        break;
      case 256:
        contactCTV = "Mabilha";
        break;
      case 152:
        contactCTV = "Macaw Bank";
        break;
      case 257:
        contactCTV = "Machakila";
        break;
      case 258:
        contactCTV = "Mafredi";
        break;
      case 60:
        contactCTV = "Mameyal";
        break;
      case 196:
        contactCTV = "Mango Creek";
        break;
      case 259:
        contactCTV = "Mango Walk";
        break;
      case 113:
        contactCTV = "Maskall";
        break;
      case 114:
        contactCTV = "May Pen";
        break;
      case 197:
        contactCTV = "Maya Beach";
        break;
      case 198:
        contactCTV = "Maya Centre";
        break;
      case 199:
        contactCTV = "Maya Mopan";
        break;
      case 260:
        contactCTV = "Medina Bank";
        break;
      case 200:
        contactCTV = "Melinda";
        break;
      case 201:
        contactCTV = "Middlesex";
        break;
      case 261:
        contactCTV = "Midway";
        break;
      case 115:
        contactCTV = "Mile 25 Village";
        break;
      case 262:
        contactCTV = "Moho River";
        break;
      case 263:
        contactCTV = "Monkey River";
        break;
      case 264:
        contactCTV = "Moody Hill";
        break;
      case 153:
        contactCTV = "More Tomorrow";
        break;
      case 202:
        contactCTV = "Mullins River";
        break;
      case 265:
        contactCTV = "Na Lum Kah";
        break;
      case 61:
        contactCTV = "New Hope";
        break;
      case 266:
        contactCTV = "New Road Area";
        break;
      case 154:
        contactCTV = "Ontario";
        break;
      case 40:
        contactCTV = "Orange Walk Town";
        break;
      case 124:
        contactCTV = "Other - Belize";
        break;
      case 182:
        contactCTV = "Other - Cayo";
        break;
      case 39:
        contactCTV = "Other - Corozal";
        break;
      case 87:
        contactCTV = "Other - Orange Walk";
        break;
      case 220:
        contactCTV = "Other - Stann Creek";
        break;
      case 294:
        contactCTV = "Other - Toledo";
        break;
      case 267:
        contactCTV = "Otoxha";
        break;
      case 19:
        contactCTV = "Paraiso";
        break;
      case 155:
        contactCTV = "Paslow Falls/Plant-Sucker/Remanzo";
        break;
      case 20:
        contactCTV = "Patchakan";
        break;
      case 62:
        contactCTV = "Petville";
        break;
      case 156:
        contactCTV = "Pilgrimage Valley";
        break;
      case 268:
        contactCTV = "Pinehill";
        break;
      case 203:
        contactCTV = "Placencia";
        break;
      case 204:
        contactCTV = "Pomona";
        break;
      case 21:
        contactCTV = "Progreso";
        break;
      case 157:
        contactCTV = "Pueblas";
        break;
      case 22:
        contactCTV = "Pueblo Nuevo";
        break;
      case 269:
        contactCTV = "Pueblo Viejo";
        break;
      case 221:
        contactCTV = "Punta Gorda";
        break;
      case 270:
        contactCTV = "Punta Negra";
        break;
      case 158:
        contactCTV = "Ramonal";
        break;
      case 23:
        contactCTV = "Ranchito";
        break;
      case 116:
        contactCTV = "Rancho Dolores";
        break;
      case 205:
        contactCTV = "Red Bank";
        break;
      case 63:
        contactCTV = "Richmond Hill";
        break;
      case 159:
        contactCTV = "Ringtail Village";
        break;
      case 206:
        contactCTV = "Riversdale";
        break;
      case 160:
        contactCTV = "Roaring Creek";
        break;
      case 161:
        contactCTV = "Roaring River";
        break;
      case 117:
        contactCTV = "Rock Stone Pond";
        break;
      case 207:
        contactCTV = "Rum Point";
        break;
      case 24:
        contactCTV = "Saltillo";
        break;
      case 25:
        contactCTV = "San Andres";
        break;
      case 26:
        contactCTV = "San Antonio (Corozal)";
        break;
      case 64:
        contactCTV = "San Antonio (Orannge Walk)";
        break;
      case 165:
        contactCTV = "San Antonio (Cayo)";
        break;
      case 271:
        contactCTV = "San Antonio (Toledo)";
        break;
      case 272:
        contactCTV = "San Benito Poite";
        break;
      case 65:
        contactCTV = "San Carlos";
        break;
      case 66:
        contactCTV = "San Estevan";
        break;
      case 67:
        contactCTV = "San Felipe (Orange Walk)";
        break;
      case 273:
        contactCTV = "San Felipe (Toledo)";
        break;
      case 127:
        contactCTV = "San Ignacio";
        break;
      case 27:
        contactCTV = "San Joaquin";
        break;
      case 166:
        contactCTV = "San Jorge";
        break;
      case 68:
        contactCTV = "San Jose (Orange Walk)";
        break;
      case 167:
        contactCTV = "San Jose (Cayo)";
        break;
      case 274:
        contactCTV = "San Jose (Toledo)";
        break;
      case 69:
        contactCTV = "San Jose Palmar";
        break;
      case 168:
        contactCTV = "San Jose Succotz";
        break;
      case 70:
        contactCTV = "San Juan (Orange Walk)";
        break;
      case 208:
        contactCTV = "San Juan (Stann Creek)";
        break;
      case 71:
        contactCTV = "San Lazaro";
        break;
      case 72:
        contactCTV = "San Lorenzo";
        break;
      case 275:
        contactCTV = "San Lucas";
        break;
      case 73:
        contactCTV = "San Luis";
        break;
      case 169:
        contactCTV = "San Marcos (Cayo)";
        break;
      case 276:
        contactCTV = "San Marcos (Toledo)";
        break;
      case 277:
        contactCTV = "San Miguel";
        break;
      case 28:
        contactCTV = "San Maximo";
        break;
      case 29:
        contactCTV = "San Narciso";
        break;
      case 74:
        contactCTV = "San Pablo (Orange Walk)";
        break;
      case 278:
        contactCTV = "San Pablo (Toledo)";
        break;
      case 30:
        contactCTV = "San Pedro";
        break;
      case 279:
        contactCTV = "San Pedro Columbia";
        break;
      case 89:
        contactCTV = "San Pedro Town";
        break;
      case 31:
        contactCTV = "San Roman (Corozal)";
        break;
      case 75:
        contactCTV = "San Roman (Orange Walk)";
        break;
      case 209:
        contactCTV = "San Roman (Stann Creek)";
        break;
      case 280:
        contactCTV = "San Vicente";
        break;
      case 32:
        contactCTV = "San Victor";
        break;
      case 118:
        contactCTV = "Sand Hill";
        break;
      case 281:
        contactCTV = "Santa Ana";
        break;
      case 33:
        contactCTV = "Santa Clara";
        break;
      case 34:
        contactCTV = "Santa Cruz (Corozal)";
        break;
      case 76:
        contactCTV = "Santa Cruz (Orange Walk)";
        break;
      case 210:
        contactCTV = "Santa Cruz (Stann Creek)";
        break;
      case 282:
        contactCTV = "Santa Cruz (Toledo)";
        break;
      case 128:
        contactCTV = "Santa Elena (Cayo)";
        break;
      case 283:
        contactCTV = "Santa Elena (Toledo)";
        break;
      case 170:
        contactCTV = "Santa Familia";
        break;
      case 77:
        contactCTV = "Santa Marta (Orange Walk)";
        break;
      case 171:
        contactCTV = "Santa Marta (Cayo)";
        break;
      case 172:
        contactCTV = "Santa Rosa (Cayo)";
        break;
      case 211:
        contactCTV = "Santa Rosa (Stann Creek)";
        break;
      case 284:
        contactCTV = "Santa Teresa";
        break;
      case 173:
        contactCTV = "Santa Teresita";
        break;
      case 119:
        contactCTV = "Santana";
        break;
      case 212:
        contactCTV = "Sarawee";
        break;
      case 285:
        contactCTV = "Sarstoon";
        break;
      case 35:
        contactCTV = "Sarteneja";
        break;
      case 120:
        contactCTV = "Scotland Halfmoon";
        break;
      case 213:
        contactCTV = "Seine Bight";
        break;
      case 174:
        contactCTV = "Selena";
        break;
      case 78:
        contactCTV = "Shipyard";
        break;
      case 286:
        contactCTV = "Sholville";
        break;
      case 214:
        contactCTV = "Silk Grass";
        break;
      case 287:
        contactCTV = "Silver Creek";
        break;
      case 215:
        contactCTV = "Sittee River";
        break;
      case 216:
        contactCTV = "South Stann Creek";
        break;
      case 175:
        contactCTV = "Spanish Lookout";
        break;
      case 181:
        contactCTV = "Spring Field";
        break;
      case 217:
        contactCTV = "St. Margaret";
        break;
      case 176:
        contactCTV = "St. Matthews";
        break;
      case 121:
        contactCTV = "St. Paul's Bank";
        break;
      case 218:
        contactCTV = "Steadfast";
        break;
      case 288:
        contactCTV = "Sunday Wood";
        break;
      case 289:
        contactCTV = "Swasey";
        break;
      case 79:
        contactCTV = "Sylvestre Camp";
        break;
      case 36:
        contactCTV = "Tacistal";
        break;
      case 290:
        contactCTV = "Tambran";
        break;
      case 177:
        contactCTV = "Teakettle";
        break;
      case 81:
        contactCTV = "Tower Hill";
        break;
      case 80:
        contactCTV = "Tres Leguas";
        break;
      case 82:
        contactCTV = "Trial Farm";
        break;
      case 83:
        contactCTV = "Trinidad";
        break;
      case 291:
        contactCTV = "Trio";
        break;
      case 178:
        contactCTV = "Unitedville";
        break;
      case 219:
        contactCTV = "Valley Community";
        break;
      case 179:
        contactCTV = "Valley of Peace";
        break;
      case 84:
        contactCTV = "Wahmil";
        break;
      case 123:
        contactCTV = "Western Paradise";
        break;
      case 122:
        contactCTV = "Willows Bank";
        break;
      case 292:
        contactCTV = "Wilson Road";
        break;
      case 37:
        contactCTV = "Xaibe";
        break;
      case 86:
        contactCTV = "Yalbac (Orange Walk)";
        break;
      case 180:
        contactCTV = "Yalbac (Cayo)";
        break;
      case 293:
        contactCTV = "Yemery Grove";
        break;
      case 38:
        contactCTV = "Yo Chen";
        break;
      case 85:
        contactCTV = "Yo Creek";
        break;
    }

    switch(data.contactDistrict)
    {
      case 1:
        contactDistrict = "Corozal";
        break;
      case 2:
        contactDistrict = "Orange Walk";
        break;
      case 3:
        contactDistrict = "Belize";
        break;
      case 4:
        contactDistrict = "Cayo";
        break;
      case 5:
        contactDistrict = "Stann Creek";
        break;
      case 6:
        contactDistrict = "Toledo";
        break;
    }
  }

  return (
    <Modal
      title={data && "Contact " + data.ContactListing__id}
      visible={visible}
      onOk={handleSubmit}
      onCancel={closeModal}
      style={{ width: "100vh" }}
      maskClosable={false}
      afterClose={() => {
        resetFields();
      }}
      destroyOnClose
    >
      <Divider>
        <Icon type={"edit"} /> {data && "Edit " + data.contactListNames}
      </Divider>

      <Form layout="horizontal" style={{ alignItems: "center" }}>
        <Form.Item label="Risk Level" {...formLayout}>
          {getFieldDecorator(
            "riskLevel",
            getFieldDecoratorOptions("riskLevel")
          )(
            <Select
              placeholder="Select level of risk"
              onChange={handleSelectChange}
            >
              <Option value={1}>HIGH</Option>
              <Option value={2}>MEDIUM</Option>
              <Option value={3}>LOW</Option>
              <Option value={4}>No Identifiable Risk</Option>
            </Select>
          )}
          <br />
        </Form.Item>
        <Form.Item label="Name" {...formLayout}>
          {data != undefined && <Text>{data.contactListNames}</Text>}
        </Form.Item>
        {/* <Form.Item label="Is household member" {...formLayout}>
          {getFieldDecorator(
            "isHouseholdMember",
            getFieldDecoratorOptions("isHouseholdMember")
          )(
            <Select
              placeholder="Select is household member"
              disabled
              onChange={handleSelectChange}
            >
              <Option value={1}>Yes</Option>
              <Option value={2}>No</Option>
              <Option value={999999}>DK/NS</Option>
            </Select>
          )}
          <br />
        </Form.Item>
        <Form.Item label="Relationship to head" {...formLayout}>
          {getFieldDecorator(
            "relationshipToHead",
            getFieldDecoratorOptions("relationshipToHead")
          )(
            <Select
              placeholder="Select relationship to head"
              disabled
              onChange={handleSelectChange}
            >
              <Option value={1}>Spouse/Partner</Option>
              <Option value={2}>Parent</Option>
              <Option value={3}>Child or step-child</Option>
              <Option value={4}>Sibling</Option>
              <Option value={5}>Grandparent</Option>
              <Option value={6}>Grandchild</Option>
              <Option value={7}>Other Relative</Option>
              <Option value={8}>Non-Relative</Option>
            </Select>
          )}
          <br />
        </Form.Item> */}
        <Form.Item label="General Relationship" {...formLayout}>
          {/* {getFieldDecorator(
            "generalRelationship",
            getFieldDecoratorOptions("generalRelationship")
          )(
            <Select
              placeholder="Select general relationship"
              disabled
              onChange={handleSelectChange}
            >
              <Option value={1}>Co-worker</Option>
              <Option value={2}>Relative</Option>
              <Option value={3}>Close friend</Option>
              <Option value={4}>Friend</Option>
              <Option value={5}>Acquintance</Option>
              <Option value={8}>Other</Option>
              <Option value={999999}>DK/NS</Option>
            </Select>
          )} */}
          {data != undefined && <Text>{data.genRelationship}</Text>}
          <br />
        </Form.Item>
        <Form.Item label="Contact Age" {...formLayout}>
          {/* {getFieldDecorator(
            "age",
            getFieldDecoratorOptions("age")
          )(<InputNumber min={0} placeholder="0" disabled />)} */}
          {data != undefined && <Text>{data.contactAge}</Text>}
          <br />
        </Form.Item>
        <Form.Item label="Contact Sex" {...formLayout}>
          {/* {getFieldDecorator(
            "sex",
            getFieldDecoratorOptions("sex")
          )(
            <Select
              placeholder="Select sex"
              disabled
              onChange={handleSelectChange}
            >
              <Option value={1}>Male</Option>
              <Option value={2}>Female</Option>
              <Option value={9}>DK/NS</Option>
            </Select>
          )} */}
          {data != undefined && <Text>{contactSex}</Text>}
          <br />
        </Form.Item>
        <Form.Item label="Contact Phone" {...formLayout}>
          {/* {getFieldDecorator(
            "phone",
            getFieldDecoratorOptions("phone")
          )(<Input placeholder="(xxx-xxxx)" disabled />)} */}
          {data != undefined && <Text>{data.contactPhone}</Text>}
          <br />
        </Form.Item>
        <Form.Item label="Contact Address" {...formLayout}>
          {/* {getFieldDecorator(
            "address",
            getFieldDecoratorOptions("address")
          )(<Input placeholder="Address" disabled />)} */}
          {data != undefined && <Text>{data.contactAddress}</Text>}
          <br />
        </Form.Item>
        <Form.Item label="Contact CTV" {...formLayout}>
          {/* {getFieldDecorator(
            "ctv",
            getFieldDecoratorOptions("ctv")
          )(<Input placeholder="City, Town, Village" disabled />)} */}
          {data != undefined && <Text>{contactCTV}</Text>}
          <br />
        </Form.Item>
        <Form.Item label="Contact District" {...formLayout}>
          {/* {getFieldDecorator(
            "district",
            getFieldDecoratorOptions("district")
          )(
            <Select
              placeholder="Select district"
              disabled
              onChange={handleSelectChange}
            >
              <Option value={1}>Corozal</Option>
              <Option value={2}>Orange Walk</Option>
              <Option value={3}>Belize</Option>
              <Option value={4}>Cayo</Option>
              <Option value={5}>Stann Creek</Option>
              <Option value={6}>Toledo</Option>
              <Option value={7}>Abroad</Option>
              <Option value={999999}>DK/NS</Option>
            </Select>
          )} */}
          {data != undefined && <Text>{contactDistrict}</Text>}
          <br />
        </Form.Item>
        <Form.Item label="Contact Work" {...formLayout}>
          {/* {getFieldDecorator(
            "work",
            getFieldDecoratorOptions("work")
          )(<Input placeholder="MoH" disabled />)} */}
          {data != undefined && <Text>{data.contactWork}</Text>}
          <br />
        </Form.Item>
        <Form.Item label="Work Address" {...formLayout}>
          {/* {getFieldDecorator(
            "workAddress",
            getFieldDecoratorOptions("workAddress")
          )(<Input placeholder="Address" disabled />)} */}
          {data != undefined && <Text>{data.contactWorkAddress}</Text>}
          <br />
        </Form.Item>
        {/* <Form.Item label="Is Emergency Contact" {...formLayout}>
          {getFieldDecorator(
            "emergencyContact",
            getFieldDecoratorOptions("emergencyContact")
          )(
            <Select
              placeholder="Select is emergency contact"
              disabled
              onChange={handleSelectChange}
            >
              <Option value={1}>Yes</Option>
              <Option value={2}>No</Option>
            </Select>
          )}
          <br />
        </Form.Item> */}
      </Form>
    </Modal>
  );
};

export default Form.create({ name: "control_form_entry" })(ControlModal);
