import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Edit from "../components/EditRow";
import SubTable from "./SubTable";
import { ADD, EDIT } from "../constants";
import { Button } from "antd";

import { Tabs } from "antd";
const { TabPane } = Tabs;

const ControlFormUnits = ({ unitData, hhData, onClick, onAssign, filter, extra }) => {
  const [key, setKey] = useState(1);
  let contactDataForButton;

  extra.map(i=>{
    unitData.filter(k=>k.genRelationship == "Co-worker").map(j=>{
      if(i.referenceName == j.referenceName)
      {
        j.contactWork = i.memberWork;
      }
    })
  })

  const highRisk = unitData != undefined && unitData.filter(data => data.riskLevel === 1);
  const mediumRisk = unitData != undefined && unitData.filter(data => data.riskLevel === 2);
  const lowRisk = unitData != undefined && unitData.filter(data => data.riskLevel === 3);
  const noIdentifiableRisk = unitData != undefined && unitData.filter(data => data.riskLevel === 4);

  const checkAssigned = e => {
    if (key == 1 && highRisk.length > 0 && highRisk[e] != undefined) {
      contactDataForButton = highRisk[e].isAssigned;
    } else if (
      key == 2 &&
      mediumRisk.length > 0 &&
      mediumRisk[e] != undefined
    ) {
      contactDataForButton = mediumRisk[e].isAssigned;
    } else if (key == 3 && lowRisk.length > 0 && lowRisk[e] != undefined) {
      contactDataForButton = lowRisk[e].isAssigned;
    } else if (
      key == 4 &&
      noIdentifiableRisk.length > 0 &&
      noIdentifiableRisk[e] != undefined
    ) {
      contactDataForButton = noIdentifiableRisk[e].isAssigned;
    }
  };

  const unitObj = {
    households: []
  };

  const getMuiTheme = color =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          root: {
            backgroundColor: "#FF000"
          },
          paper: {
            boxShadow: "none"
          }
        },
        MUIDataTableBodyCell: {
          root: {
            backgroundColor: color,
            padding: "4px"
          }
        },
        MUIDataTableFilterListChip: {
          root: {
            backgroundColor: "teal"
          }
        },
        MuiTableCell: {
          root: {
            padding: 0
          },
          paddingCheckbox: {
            padding: 0
          },
          head: {
            padding: "4px"
          }
        },
        MUIDataTableSelectCell: {
          fixedHeaderCommon: {
            backgroundColor: color
          }
        }
      }
    });

    unitData != undefined && unitData.forEach(row => {
    switch (parseInt(row.contactCTV)) {
      case 222:
        row.ctv = "Aguacate";
        break;
      case 184:
        row.ctv = "Alta Vista";
        break;
      case 2:
        row.ctv = "Altamira";
        break;
      case 129:
        row.ctv = "Arenal";
        break;
      case 130:
        row.ctv = "Armenia";
        break;
      case 41:
        row.ctv = "August Pine Ridge";
        break;
      case 131:
        row.ctv = "Augustine";
        break;
      case 42:
        row.ctv = "Bacadia";
        break;
      case 223:
        row.ctv = "Barranco";
        break;
      case 132:
        row.ctv = "Barton Creek";
        break;
      case 133:
        row.ctv = "Beaver Dam";
        break;
      case 88:
        row.ctv = "Belize City";
        break;
      case 185:
        row.ctv = "Bella Vista (Stann Creek)";
        break;
      case 224:
        row.ctv = "Bella Vista (Toledo)";
        break;
      case 125:
        row.ctv = "Belmopan";
        break;
      case 126:
        row.ctv = "Benque Viejo Del Carmen";
        break;
      case 90:
        row.ctv = "Bermudian Landing";
        break;
      case 186:
        row.ctv = "Big Creek";
        break;
      case 225:
        row.ctv = "Big Falls";
        break;
      case 162:
        row.ctv = "Billy White";
        break;
      case 91:
        row.ctv = "Biscayne";
        break;
      case 226:
        row.ctv = "Black Creek";
        break;
      case 134:
        row.ctv = "Blackman Eddy";
        break;
      case 227:
        row.ctv = "Bladden";
        break;
      case 43:
        row.ctv = "Blue Creek (Orange Walk)";
        break;
      case 228:
        row.ctv = "Blue Creek (Toledo)";
        break;
      case 92:
        row.ctv = "Bomba";
        break;
      case 229:
        row.ctv = "Boom Creek";
        break;
      case 93:
        row.ctv = "Boston";
        break;
      case 135:
        row.ctv = "Branch Mouth";
        break;
      case 3:
        row.ctv = "Buena Vista (Corozal)";
        break;
      case 163:
        row.ctv = "Buena Vista (Cayo)";
        break;
      case 136:
        row.ctv = "Bullet Tree Falls";
        break;
      case 94:
        row.ctv = "Burrell Boom";
        break;
      case 230:
        row.ctv = "Calacomos";
        break;
      case 4:
        row.ctv = "Calcutta";
        break;
      case 5:
        row.ctv = "Caledonia";
        break;
      case 137:
        row.ctv = "Calla Creek";
        break;
      case 138:
        row.ctv = "Camalote";
        break;
      case 44:
        row.ctv = "Carmelita (Orange Walk)";
        break;
      case 139:
        row.ctv = "Carmelita (Cayo)";
        break;
      case 6:
        row.ctv = "Carolina";
        break;
      case 231:
        row.ctv = "Cattle Landing";
        break;
      case 95:
        row.ctv = "Caye Caulker";
        break;
      case 140:
        row.ctv = "Central Farm";
        break;
      case 7:
        row.ctv = "Chan Chen";
        break;
      case 45:
        row.ctv = "Chan Chich";
        break;
      case 46:
        row.ctv = "Chan Pine Ridge";
        break;
      case 141:
        row.ctv = "Chial";
        break;
      case 8:
        row.ctv = "Chunox";
        break;
      case 9:
        row.ctv = "Concepcion";
        break;
      case 233:
        row.ctv = "Conejo";
        break;
      case 10:
        row.ctv = "Consejo";
        break;
      case 142:
        row.ctv = "Cool Shade";
        break;
      case 11:
        row.ctv = "Copper Bank";
        break;
      case 234:
        row.ctv = "Corazon";
        break;
      case 1:
        row.ctv = "Corozal Town";
        break;
      case 96:
        row.ctv = "Corozalito";
        break;
      case 143:
        row.ctv = "Cotton Tree";
        break;
      case 187:
        row.ctv = "Cow Creek";
        break;
      case 188:
        row.ctv = "Cow Pen";
        break;
      case 235:
        row.ctv = "Crique Jute";
        break;
      case 236:
        row.ctv = "Crique Largo";
        break;
      case 237:
        row.ctv = "Crique Sarco";
        break;
      case 232:
        row.ctv = "Crique Trosa";
        break;
      case 12:
        row.ctv = "Cristo Rey (Corozal)";
        break;
      case 144:
        row.ctv = "Cristo Rey (Cayo)";
        break;
      case 97:
        row.ctv = "Crooked Tree";
        break;
      case 47:
        row.ctv = "Cross Creek";
        break;
      case 238:
        row.ctv = "Crystal Creek";
        break;
      case 48:
        row.ctv = "Cuatro Leguas";
        break;
      case 183:
        row.ctv = "Dangriga";
        break;
      case 98:
        row.ctv = "Davis/Grace Bank";
        break;
      case 239:
        row.ctv = "Deep River/Medina Bank";
        break;
      case 999999:
        row.ctv = "DK/NS";
        break;
      case 241:
        row.ctv = "Dolores";
        break;
      case 99:
        row.ctv = "Double Head Cabbage";
        break;
      case 49:
        row.ctv = "Douglas";
        break;
      case 145:
        row.ctv = "Duck Run 1";
        break;
      case 146:
        row.ctv = "Duck Run 2";
        break;
      case 147:
        row.ctv = "Duck Run 3";
        break;
      case 240:
        row.ctv = "Dump";
        break;
      case 242:
        row.ctv = "Elridge";
        break;
      case 148:
        row.ctv = "Esperanza (Cayo)";
        break;
      case 243:
        row.ctv = "Esperanza (Toledo)";
        break;
      case 13:
        row.ctv = "Estrella";
        break;
      case 14:
        row.ctv = "Fire Burn (Corozal)";
        break;
      case 50:
        row.ctv = "Fire Burn (Orange Walk)";
        break;
      case 100:
        row.ctv = "Flowers Bank";
        break;
      case 244:
        row.ctv = "Forest Home";
        break;
      case 149:
        row.ctv = "Frank's Eddy";
        break;
      case 101:
        row.ctv = "Freetown Sibun";
        break;
      case 102:
        row.ctv = "Gales Point";
        break;
      case 51:
        row.ctv = "Gallon Jug";
        break;
      case 103:
        row.ctv = "Gardenia";
        break;
      case 245:
        row.ctv = "Genus";
        break;
      case 189:
        row.ctv = "Georgetown";
        break;
      case 150:
        row.ctv = "Georgeville";
        break;
      case 52:
        row.ctv = "Gold Button Ranch";
        break;
      case 246:
        row.ctv = "Golden Beauty";
        break;
      case 247:
        row.ctv = "Golden Stream";
        break;
      case 104:
        row.ctv = "Gracie Rock";
        break;
      case 248:
        row.ctv = "Graham Creek";
        break;
      case 53:
        row.ctv = "Guinea Grass";
        break;
      case 105:
        row.ctv = "Hattieville";
        break;
      case 106:
        row.ctv = "Hendy Bank";
        break;
      case 249:
        row.ctv = "Hicattee";
        break;
      case 15:
        row.ctv = "Hill Bank (Corozal)";
        break;
      case 54:
        row.ctv = "Hill Bank (Orange Walk)";
        break;
      case 55:
        row.ctv = "Honey  Camp";
        break;
      case 190:
        row.ctv = "Hope Creek";
        break;
      case 250:
        row.ctv = "Hopeville";
        break;
      case 191:
        row.ctv = "Hopkins";
        break;
      case 192:
        row.ctv = "Hummingbird Community";
        break;
      case 193:
        row.ctv = "Independence";
        break;
      case 56:
        row.ctv = "Indian Church";
        break;
      case 57:
        row.ctv = "Indian Creek (Orange Walk)";
        break;
      case 251:
        row.ctv = "Indian Creek (Toledo)";
        break;
      case 58:
        row.ctv = "Indian Hill Estate";
        break;
      case 107:
        row.ctv = "Isabella Bank";
        break;
      case 252:
        row.ctv = "Jacinto/Westmoreland";
        break;
      case 253:
        row.ctv = "Jalacte";
        break;
      case 254:
        row.ctv = "Jordan";
        break;
      case 194:
        row.ctv = "Kendall";
        break;
      case 108:
        row.ctv = "La Democracia";
        break;
      case 164:
        row.ctv = "La Gracia";
        break;
      case 109:
        row.ctv = "Ladyville";
        break;
      case 255:
        row.ctv = "Laguna";
        break;
      case 59:
        row.ctv = "Las Milpas";
        break;
      case 110:
        row.ctv = "Lemonal";
        break;
      case 16:
        row.ctv = "Libertad";
        break;
      case 17:
        row.ctv = "Little Belize";
        break;
      case 195:
        row.ctv = "Long Bank";
        break;
      case 111:
        row.ctv = "Lords Bank";
        break;
      case 151:
        row.ctv = "Los Tambos";
        break;
      case 18:
        row.ctv = "Louisville";
        break;
      case 112:
        row.ctv = "Lucky Strike";
        break;
      case 256:
        row.ctv = "Mabilha";
        break;
      case 152:
        row.ctv = "Macaw Bank";
        break;
      case 257:
        row.ctv = "Machakila";
        break;
      case 258:
        row.ctv = "Mafredi";
        break;
      case 60:
        row.ctv = "Mameyal";
        break;
      case 196:
        row.ctv = "Mango Creek";
        break;
      case 259:
        row.ctv = "Mango Walk";
        break;
      case 113:
        row.ctv = "Maskall";
        break;
      case 114:
        row.ctv = "May Pen";
        break;
      case 197:
        row.ctv = "Maya Beach";
        break;
      case 198:
        row.ctv = "Maya Centre";
        break;
      case 199:
        row.ctv = "Maya Mopan";
        break;
      case 260:
        row.ctv = "Medina Bank";
        break;
      case 200:
        row.ctv = "Melinda";
        break;
      case 201:
        row.ctv = "Middlesex";
        break;
      case 261:
        row.ctv = "Midway";
        break;
      case 115:
        row.ctv = "Mile 25 Village";
        break;
      case 262:
        row.ctv = "Moho River";
        break;
      case 263:
        row.ctv = "Monkey River";
        break;
      case 264:
        row.ctv = "Moody Hill";
        break;
      case 153:
        row.ctv = "More Tomorrow";
        break;
      case 202:
        row.ctv = "Mullins River";
        break;
      case 265:
        row.ctv = "Na Lum Kah";
        break;
      case 61:
        row.ctv = "New Hope";
        break;
      case 266:
        row.ctv = "New Road Area";
        break;
      case 154:
        row.ctv = "Ontario";
        break;
      case 40:
        row.ctv = "Orange Walk Town";
        break;
      case 124:
        row.ctv = "Other - Belize";
        break;
      case 182:
        row.ctv = "Other - Cayo";
        break;
      case 39:
        row.ctv = "Other - Corozal";
        break;
      case 87:
        row.ctv = "Other - Orange Walk";
        break;
      case 220:
        row.ctv = "Other - Stann Creek";
        break;
      case 294:
        row.ctv = "Other - Toledo";
        break;
      case 267:
        row.ctv = "Otoxha";
        break;
      case 19:
        row.ctv = "Paraiso";
        break;
      case 155:
        row.ctv = "Paslow Falls/Plant-Sucker/Remanzo";
        break;
      case 20:
        row.ctv = "Patchakan";
        break;
      case 62:
        row.ctv = "Petville";
        break;
      case 156:
        row.ctv = "Pilgrimage Valley";
        break;
      case 268:
        row.ctv = "Pinehill";
        break;
      case 203:
        row.ctv = "Placencia";
        break;
      case 204:
        row.ctv = "Pomona";
        break;
      case 21:
        row.ctv = "Progreso";
        break;
      case 157:
        row.ctv = "Pueblas";
        break;
      case 22:
        row.ctv = "Pueblo Nuevo";
        break;
      case 269:
        row.ctv = "Pueblo Viejo";
        break;
      case 221:
        row.ctv = "Punta Gorda";
        break;
      case 270:
        row.ctv = "Punta Negra";
        break;
      case 158:
        row.ctv = "Ramonal";
        break;
      case 23:
        row.ctv = "Ranchito";
        break;
      case 116:
        row.ctv = "Rancho Dolores";
        break;
      case 205:
        row.ctv = "Red Bank";
        break;
      case 63:
        row.ctv = "Richmond Hill";
        break;
      case 159:
        row.ctv = "Ringtail Village";
        break;
      case 206:
        row.ctv = "Riversdale";
        break;
      case 160:
        row.ctv = "Roaring Creek";
        break;
      case 161:
        row.ctv = "Roaring River";
        break;
      case 117:
        row.ctv = "Rock Stone Pond";
        break;
      case 207:
        row.ctv = "Rum Point";
        break;
      case 24:
        row.ctv = "Saltillo";
        break;
      case 25:
        row.ctv = "San Andres";
        break;
      case 26:
        row.ctv = "San Antonio (Corozal)";
        break;
      case 64:
        row.ctv = "San Antonio (Orannge Walk)";
        break;
      case 165:
        row.ctv = "San Antonio (Cayo)";
        break;
      case 271:
        row.ctv = "San Antonio (Toledo)";
        break;
      case 272:
        row.ctv = "San Benito Poite";
        break;
      case 65:
        row.ctv = "San Carlos";
        break;
      case 66:
        row.ctv = "San Estevan";
        break;
      case 67:
        row.ctv = "San Felipe (Orange Walk)";
        break;
      case 273:
        row.ctv = "San Felipe (Toledo)";
        break;
      case 127:
        row.ctv = "San Ignacio";
        break;
      case 27:
        row.ctv = "San Joaquin";
        break;
      case 166:
        row.ctv = "San Jorge";
        break;
      case 68:
        row.ctv = "San Jose (Orange Walk)";
        break;
      case 167:
        row.ctv = "San Jose (Cayo)";
        break;
      case 274:
        row.ctv = "San Jose (Toledo)";
        break;
      case 69:
        row.ctv = "San Jose Palmar";
        break;
      case 168:
        row.ctv = "San Jose Succotz";
        break;
      case 70:
        row.ctv = "San Juan (Orange Walk)";
        break;
      case 208:
        row.ctv = "San Juan (Stann Creek)";
        break;
      case 71:
        row.ctv = "San Lazaro";
        break;
      case 72:
        row.ctv = "San Lorenzo";
        break;
      case 275:
        row.ctv = "San Lucas";
        break;
      case 73:
        row.ctv = "San Luis";
        break;
      case 169:
        row.ctv = "San Marcos (Cayo)";
        break;
      case 276:
        row.ctv = "San Marcos (Toledo)";
        break;
      case 277:
        row.ctv = "San Miguel";
        break;
      case 28:
        row.ctv = "San Maximo";
        break;
      case 29:
        row.ctv = "San Narciso";
        break;
      case 74:
        row.ctv = "San Pablo (Orange Walk)";
        break;
      case 278:
        row.ctv = "San Pablo (Toledo)";
        break;
      case 30:
        row.ctv = "San Pedro";
        break;
      case 279:
        row.ctv = "San Pedro Columbia";
        break;
      case 89:
        row.ctv = "San Pedro Town";
        break;
      case 31:
        row.ctv = "San Roman (Corozal)";
        break;
      case 75:
        row.ctv = "San Roman (Orange Walk)";
        break;
      case 209:
        row.ctv = "San Roman (Stann Creek)";
        break;
      case 280:
        row.ctv = "San Vicente";
        break;
      case 32:
        row.ctv = "San Victor";
        break;
      case 118:
        row.ctv = "Sand Hill";
        break;
      case 281:
        row.ctv = "Santa Ana";
        break;
      case 33:
        row.ctv = "Santa Clara";
        break;
      case 34:
        row.ctv = "Santa Cruz (Corozal)";
        break;
      case 76:
        row.ctv = "Santa Cruz (Orange Walk)";
        break;
      case 210:
        row.ctv = "Santa Cruz (Stann Creek)";
        break;
      case 282:
        row.ctv = "Santa Cruz (Toledo)";
        break;
      case 128:
        row.ctv = "Santa Elena (Cayo)";
        break;
      case 283:
        row.ctv = "Santa Elena (Toledo)";
        break;
      case 170:
        row.ctv = "Santa Familia";
        break;
      case 77:
        row.ctv = "Santa Marta (Orange Walk)";
        break;
      case 171:
        row.ctv = "Santa Marta (Cayo)";
        break;
      case 172:
        row.ctv = "Santa Rosa (Cayo)";
        break;
      case 211:
        row.ctv = "Santa Rosa (Stann Creek)";
        break;
      case 284:
        row.ctv = "Santa Teresa";
        break;
      case 173:
        row.ctv = "Santa Teresita";
        break;
      case 119:
        row.ctv = "Santana";
        break;
      case 212:
        row.ctv = "Sarawee";
        break;
      case 285:
        row.ctv = "Sarstoon";
        break;
      case 35:
        row.ctv = "Sarteneja";
        break;
      case 120:
        row.ctv = "Scotland Halfmoon";
        break;
      case 213:
        row.ctv = "Seine Bight";
        break;
      case 174:
        row.ctv = "Selena";
        break;
      case 78:
        row.ctv = "Shipyard";
        break;
      case 286:
        row.ctv = "Sholville";
        break;
      case 214:
        row.ctv = "Silk Grass";
        break;
      case 287:
        row.ctv = "Silver Creek";
        break;
      case 215:
        row.ctv = "Sittee River";
        break;
      case 216:
        row.ctv = "South Stann Creek";
        break;
      case 175:
        row.ctv = "Spanish Lookout";
        break;
      case 181:
        row.ctv = "Spring Field";
        break;
      case 217:
        row.ctv = "St. Margaret";
        break;
      case 176:
        row.ctv = "St. Matthews";
        break;
      case 121:
        row.ctv = "St. Paul's Bank";
        break;
      case 218:
        row.ctv = "Steadfast";
        break;
      case 288:
        row.ctv = "Sunday Wood";
        break;
      case 289:
        row.ctv = "Swasey";
        break;
      case 79:
        row.ctv = "Sylvestre Camp";
        break;
      case 36:
        row.ctv = "Tacistal";
        break;
      case 290:
        row.ctv = "Tambran";
        break;
      case 177:
        row.ctv = "Teakettle";
        break;
      case 81:
        row.ctv = "Tower Hill";
        break;
      case 80:
        row.ctv = "Tres Leguas";
        break;
      case 82:
        row.ctv = "Trial Farm";
        break;
      case 83:
        row.ctv = "Trinidad";
        break;
      case 291:
        row.ctv = "Trio";
        break;
      case 178:
        row.ctv = "Unitedville";
        break;
      case 219:
        row.ctv = "Valley Community";
        break;
      case 179:
        row.ctv = "Valley of Peace";
        break;
      case 84:
        row.ctv = "Wahmil";
        break;
      case 123:
        row.ctv = "Western Paradise";
        break;
      case 122:
        row.ctv = "Willows Bank";
        break;
      case 292:
        row.ctv = "Wilson Road";
        break;
      case 37:
        row.ctv = "Xaibe";
        break;
      case 86:
        row.ctv = "Yalbac (Orange Walk)";
        break;
      case 180:
        row.ctv = "Yalbac (Cayo)";
        break;
      case 293:
        row.ctv = "Yemery Grove";
        break;
      case 38:
        row.ctv = "Yo Chen";
        break;
      case 85:
        row.ctv = "Yo Creek";
        break;
    }
  });

  unitData != undefined && unitData.forEach(row => {
    switch (parseInt(row.relationshipToHead)) {
      case 1:
        row.relationship = "Spouse/Partner";
        break;
      case 2:
        row.relationship = "Parent";
        break;
      case 3:
        row.relationship = "Child or step-child";
        break;
      case 4:
        row.relationship = "Sibling";
        break;
      case 5:
        row.relationship = "Grandparent";
        break;
      case 6:
        row.relationship = "Grandchild";
        break;
      case 7:
        row.relationship = "Other relative";
        break;
      case 8:
        row.relationship = "Non-Relative";
        break;
    }
  });

  unitData != undefined && unitData.forEach(row => {
    switch (parseInt(row.generalRelationship)) {
      case 1:
        row.genRelationship = "Co-worker";
        break;
      case 2:
        row.genRelationship = "Relative";
        break;
      case 3:
        row.genRelationship = "Close friend";
        break;
      case 4:
        row.genRelationship = "Friend";
        break;
      case 5:
        row.genRelationship = "Acquaintance";
        break;
      case 8:
        row.genRelationship = "Other";
        break;
      case 999999:
        row.genRelationship = "DK/NS";
        break;
    }
  });

  unitData != undefined && unitData.forEach(row => {
    switch (parseInt(row.contactSex)) {
      case 1:
        row.sex = "Male";
        break;
      case 2:
        row.sex = "Female";
        break;
      case 9:
        row.sex = "DK/NS";
        break;
    }
  });

  unitData != undefined && unitData.forEach(row => {
    switch (parseInt(row.contactDistrict)) {
      case 1:
        row.district = "Corozal";
        break;
      case 2:
        row.district = "Orange Walk";
        break;
      case 3:
        row.district = "Belize";
        break;
      case 4:
        row.district = "Cayo";
        break;
      case 5:
        row.district = "Stann Creek";
        break;
      case 6:
        row.district = "Toledo";
        break;
    }
  });

  unitData != undefined && unitData.forEach(row => {
    switch (parseInt(row.referenceDistrict)) {
      case 1:
        row.rDistrict = "Corozal";
        break;
      case 2:
        row.rDistrict = "Orange Walk";
        break;
      case 3:
        row.rDistrict = "Belize";
        break;
      case 4:
        row.rDistrict = "Cayo";
        break;
      case 5:
        row.rDistrict = "Stann Creek";
        break;
      case 6:
        row.rDistrict = "Toledo";
        break;
    }
  });

  unitData != undefined && unitData.forEach(row => {
    switch (parseInt(row.isEmergencyContact)) {
      case 1:
        row.emergencyContact = "Yes";
        break;
      case 2:
        row.emergencyContact = "No";
        break;
      case 9:
        row.emergencyContact = "DK/NS";
        break;
    }
  });

  unitData != undefined && unitData.forEach(row => {
    row.cdate = row.date.split("T")[0];
  });

  const columns_units = [
    {
      label: "Ref. Name",
      name: "referenceName"
    },
    {
      label: "Phone Number",
      name: "contactPhone"
    },
    {
      label: "Ref. District",
      name: "rDistrict"
    },
    {
      label: "Date",
      name: "cdate"
    },
    {
      label: "No.",
      name: "ContactListing__id"
    },
    {
      label: "Name",
      name: "contactListNames"
    },
    {
      label: "Relationship",
      name: "relationship"
    },
    {
      label: (
        <span>
          General
          <br />
          Relationship
        </span>
      ),
      name: "genRelationship"
    },
    {
      label: "Age",
      name: "contactAge"
    },
    {
      label: "Sex",
      name: "sex"
    },
    {
      label: "District",
      name: "district"
    },
    {
      label: "Works at",
      name: "contactWork"
    },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Edit
              handleClick={() => {
                let contactData = [];
                if (key == 1) {
                  contactData = highRisk;
                } else if (key == 2) {
                  contactData = mediumRisk;
                } else if (key == 3) {
                  contactData = lowRisk;
                } else if (key == 4) {
                  contactData = noIdentifiableRisk;
                }
                onClick(EDIT.UNIT, contactData[tableMeta.rowIndex]);
              }}
            />
          );
        },
        setCellProps: () => {
          return {
            style: { width: 48, padding: 0 }
          };
        }
      }
    },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          checkAssigned(tableMeta.rowIndex);
          let contactData = [];
          if (key == 1) {
            contactData = highRisk;
          } else if (key == 2) {
            contactData = mediumRisk;
          } else if (key == 3) {
            contactData = lowRisk;
          } else if (key == 4) {
            contactData = noIdentifiableRisk;
          }
          return (
            contactDataForButton != 1 && (
              <Button
                size="default"
                style={{ marginRight: 10 }}
                onClick={() => {
                  onAssign(0, contactData[tableMeta.rowIndex]);
                }}
              >
                Assign
              </Button>
            )
          );
        },
        setCellProps: () => {
          return {
            style: { width: 48, padding: 0 }
          };
        }
      }
    }
  ];

  const columns_households = [
    {
      label: "Household",
      name: "household_id"
    },
    {
      label: "Name",
      name: "name"
    },
    {
      label: "Result",
      name: "result"
    },
    {
      label: "Callback Date",
      name: "callback_date"
    },
    {
      label: "Callback Time",
      name: "callback_time"
    },
    {
      name: "",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Edit
              handleClick={() => {
                onClick(EDIT.HH, unitObj.households[tableMeta.rowIndex]);
              }}
            />
          );
        },
        setCellProps: () => {
          return {
            style: { width: 48, padding: 0 }
          };
        }
      }
    }
  ];

  unitData != undefined && unitData.forEach(row => {
    switch (row.unit_type) {
      case 0:
        row.unit_type2 = "Filler";
        break;
      case 1:
        row.unit_type2 = "Dwelling";
        break;
      case 2:
        row.unit_type2 = "Institution";
        break;
      case 3:
        row.unit_type2 = "Education";
        break;
      case 4:
        row.unit_type2 = "Health Care";
        break;
      case 5:
        row.unit_type2 = "Business/Mercantile";
        break;
      case 6:
        row.unit_type2 = "Diplomatic";
        break;
      case 7:
        row.unit_type2 = "Government";
        break;
      case 8:
        row.unit_type2 = "Religious";
        break;
      case 9:
        row.unit_type2 = "Transportation";
        break;
      case 10:
        row.unit_type2 = "Recreation";
        break;
      case 11:
        row.unit_type2 = "Storage";
        break;
      case 12:
        row.unit_type2 = "Vacant Unit";
        break;
      case 13:
        row.unit_type2 = "Other";
        break;
      default:
        break;
    }
  });

  const options_units = {
    selectableRows: "none",
    pagination: true,
    rowsPerPageOptions: [10, 25, 100],
    responsive: "scrollFullHeight",
    expandableRows: true,
    isRowExpandable: (dataIndex, expandedRows) => {
      // TEMP - MAKE NO UNIT EXPANDABLE
      return false;
      // Only dwellings are expandable
      /*if (unitData[dataIndex].unit_type !== 1) return false;
      // Expand selected dwellings and collapse any expanded dwelling on click
      const row = (expandedRows.data.filter(d => d.dataIndex === dataIndex).length === 0) ? [dataIndex] : [];
      setExpandedUnitRow(row)
      return true;*/
    },
    //rowsExpanded: expandedUnitRow,
    renderExpandableRow: (rowData, rowMeta) => {
      const buildingId = parseInt(unitData[rowMeta.dataIndex].building_id);
      const unitId = parseInt(unitData[rowMeta.dataIndex].unit_id);
      const colSpan = rowData.length + 1;
      const addHh = () => {
        onClick(ADD.HH, unitData[rowMeta.dataIndex]);
      };
      // Set all households for dwelling
      unitObj.households = hhData.filter(
        d => d.building_id === buildingId && d.unit_id === unitId
      );
      return (
        <SubTable
          colSpan={colSpan}
          theme={getMuiTheme("#F0808088") /* lightcoral ~50% */}
          columns={columns_households}
          data={unitObj.households}
          options={options_households}
          addOnClick={addHh}
          typeToAdd="Household"
        />
      );
    }
  };

  const options_households = {
    selectableRows: "none",
    pagination: false,
    responsive: "scrollFullHeight",
    sort: false,
    filter: false,
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    expandableRows: true,
    isRowExpandable: (dataIndex, expandedRows) => {
      return false;
    }
  };

  const handleChange = e => {
    setKey(e);
  };

  return (
    <Tabs
      defaultActiveKey="1"
      onChange={e => {
        handleChange(e);
      }}
      animated={false}
    >
      <TabPane tab="High Risk" key="1">
        <MuiThemeProvider
          theme={getMuiTheme("#FF000088") /* lightseagreen ~50% */}
        >
          <MUIDataTable
            title="Contact Listing"
            columns={columns_units}
            data={highRisk}
            options={options_units}
          />
        </MuiThemeProvider>
      </TabPane>
      <TabPane tab="Medium Risk" key="2">
        <MuiThemeProvider
          theme={getMuiTheme("#DDCC0088") /* lightseagreen ~50% */}
        >
          <MUIDataTable
            title="Contact Listing"
            columns={columns_units}
            data={mediumRisk}
            options={options_units}
          />
        </MuiThemeProvider>
      </TabPane>
      <TabPane tab="Low Risk" key="3">
        <MuiThemeProvider
          theme={getMuiTheme("#00CC6688") /* lightseagreen ~50% */}
        >
          <MUIDataTable
            title="Contact Listing"
            columns={columns_units}
            data={lowRisk}
            options={options_units}
          />
        </MuiThemeProvider>
      </TabPane>
      <TabPane tab={"No Identifiable Risk"} key="4">
        <MuiThemeProvider
          theme={getMuiTheme("#0066CC88") /* lightseagreen ~50% */}
        >
          <MUIDataTable
            title="Contact Listing"
            columns={columns_units}
            data={noIdentifiableRisk}
            options={options_units}
          />
        </MuiThemeProvider>
      </TabPane>
    </Tabs>
  );
};

export default ControlFormUnits;
