import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../context/auth";
import { MOH_USER } from "../constants";

function HqRoute({ component: Component, ...rest }) {
  const { authToken } = useAuth();

  return (
    <Route
      {...rest}
      render={props =>
        authToken && authToken.role === MOH_USER ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

export default HqRoute;
