import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import logoImg from "../assets/images/Census2020_OfficialLogo_V4.svg";
import { Form, Icon, Input, Button, Row, Col, Alert } from "antd";
import { HQ_USER, SU_USER, EN_USER, ED_USER, MOH_USER } from "../constants";
import { useAuth } from "../context/auth";
import { authenticate } from "../services/User";

const Login = (props) => {
  const { validateFields, getFieldDecorator } = props.form;
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const { authToken, setAuthToken } = useAuth();

  // const redirectMessage = (
  //   <p>
  //     Please visit the{" "}
  //     <a href="http://ptrack.sib.org.bz:8080/apex/f?p=143:LOGIN_DESKTOP:10996757858016:::::">
  //       New COVID19 Dashbard
  //     </a>
  //     .
  //   </p>
  // );

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        authenticate(values.username, values.password)
          .then((res) => {
            if (res.result === "success") {
              setAuthToken(res.data);
              setLoggedIn(true);
            } else {
              setIsError(true);
            }
          })
          .catch((e) => {
            setIsError(true);
          });
      }
    });
  };

  const onAlertClose = (e) => {
    e.preventDefault();
    setIsError(false);
  };

  if (isLoggedIn) {
    if (authToken.role === HQ_USER) {
      return <Redirect to="/" />;
    } else if (authToken.role === SU_USER) {
      return <Redirect to="/supervisor" />;
    } else if (authToken.role === EN_USER) {
      return <Redirect to="/enumerator" />;
    } else if (authToken.role === ED_USER) {
      return <Redirect to="/editor" />;
    } else if (authToken.role === MOH_USER) {
      return <Redirect to="/new-dashboard" />;
    } else {
      setLoggedIn(false);
      setIsError(true);
    }
  }

  return (
    <div className="login-container">
      <Row className="logo-row" type="flex" align="bottom">
        <Col span={24} align="middle">
          <div className="login-form">
            {isError && (
              <Alert
                message="Invalid Login Credentials"
                type="error"
                closable
                onClose={onAlertClose}
              />
            )}
          </div>
          <img src={logoImg} alt="logo" />
        </Col>
      </Row>
      <Row className="form-row" type="flex" justify="center" align="top">
        <Col span={24} align="middle">
          <Form onSubmit={handleSubmit} className="login-form">
            <Form.Item>
              {getFieldDecorator("username", {
                rules: [
                  { required: true, message: "Please input your username!" },
                ],
              })(
                <Input
                  prefix={
                    <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Username"
                  size="large"
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("password", {
                rules: [
                  { required: true, message: "Please input your Password!" },
                ],
              })(
                <Input
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder="Password"
                  size="large"
                />
              )}
            </Form.Item>
            <Form.Item>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Login
              </Button>
            </Form.Item>
            {/* <Alert message={redirectMessage} type="info" /> */}
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default Form.create({ name: "login" })(Login);
