import React from "react";
import { Spin } from "antd";

const Loading = () => {
  return (
    <div style={{ textAlign: "center", marginTop: 150 }}>
      <Spin size={"large"} />
    </div>
  );
};

export default Loading;
